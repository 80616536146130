.patternCircle {
	display: block;
	background-image: url('img/patternCircle.png');
	height: 40rem;
	width: 40rem;
	border-radius: 50%;
	&--more {
		background-image: url('img/patternCircleMore.png');
	}
	&--six {
		background-image: url('img/patternCircle6.png');
	}
	&--twofour {
		background-image: url('img/patternCircle24.png');
	}
}