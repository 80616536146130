$expoOut: cubic-bezier(0.13, 0.4, 0.29, 0.97);
.projects {
  &:not(.portfolio) {
    padding-bottom: 4rem;
    margin-top: 10rem;
    @include max($xl) {
      margin-top: 3rem;
    }
  }
  .portfolio--mobile {
    @include min($md) {
      display: none;
    }
  }

  // &.portfolio {
  //   .project {
  //     height: 330px;
  //     @include max($lap) {
  //       height: 18.5vw;
  //     }
  //     @include max($lg) {
  //       height: 315px;
  //     }
  //     @include max($md) {
  //       height: 340px;
  //       width: 100%;
  //     }
  //     @include max($sm) {
  //       height: 280px;
  //     }
  //     @include max($xs) {
  //       height: 180px;
  //     }
  //     &__content {
  //       @include btw($lg, $lap) {
  //         min-height: 160px;
  //       }
  //     }
  //   }
  // }
  @include btw($lg, $lap) {
    padding: 2rem 0;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &:not(.portfolio--mobile) {
      @include max($md) {
        display: none;
      }
    }
  }
  h3, h4 {
    font-size: 3rem;
    @include min($lg) {
      font-size: 3.6rem;
    }
    @include min($lap) {
      font-size: 4.8rem;
    }
    @include max($sm) {
      font-size: 2.7rem;
    }
    font-weight: 900;
    max-width: 500px;
    white-space: normal;
  }
  .project {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    pointer-events: auto;

    &__wrapper {
      width: calc(100%/2 - 14px);
      height: 420px;
      margin: 7px;
      cursor: pointer;
      @include max($lap) {
        height: 21.5vw;
      }
      @include max($lg) {
        height: 315px;
      }
      @include max($md) {
        height: 340px;
        width: 100%;
      }
      @include max($sm) {
        height: 280px;
      }
      @include max($xs) {
        height: 180px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      opacity: .7;
      width: 100%;
      height: 100%;
      background-image: url('img/patternBg.png');
      transition: background-color 0.5s $expoOut;
      background-color: transparent;
    }
    &__content {
      z-index: 2;
      margin-top: auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      min-height: 200px;
      align-items: center;
      justify-content: flex-start;
      width: auto;
      position: absolute;
      bottom: 5rem;
      left: 0;
      width: 100%;
      overflow: hidden;
      // pointer-events: none;
      cursor: pointer;
      .btn--alt {
        // margin-top: 7rem;
        transform: translateY(250px);
        transition: transform .7s $expoOut .2s;
      }
      @include max($xl) {
        min-height: 190px;
        bottom: 3rem;
      }
      @include max($lg) {
        min-height: 170px;
      }
      @include max($sm) {
        bottom: 1rem;
        min-height: 150px;
        justify-content: center;
      }
      h4 {
        transform: translateY(250px);
        opacity: 0;
        transition: transform .7s $expoOut, opacity .3s $expoOut;
        // min-height: 90px;
      }
      h3 {
        margin-top: 0.5rem;
        @include min($lap) {
          margin-top: 1.5rem;
        }
        font-size: 2.5rem;
        font-weight: 900;
        font-style: italic;
        transform: translateY(250px);
        opacity: 0;
        transition: transform .7s $expoOut .1s, opacity .3s $expoOut .1s;
        @include max($sm) {
          font-size: 2.2rem;
        }
      }
    }
    &__heading {
      display: block;
      text-align: center;
      &--main {
        transform: none;
        opacity: 1;
        transition: transform .8s $expoOut, opacity .8s $expoOut;
      }
    }
    img {
      transform: scale(1);
      transition: transform .3s $expoOut;
    }
    // @include max($lg) {
    //   .project__heading {
    //     &--main {
    //       transform: translateY(-200px);
    //       opacity: 0;
    //       transition: transform .7s $expoOut, opacity .3s $expoOut;
    //     }
    //   }
    //   .project__content {
    //     h4 {
    //       transform: none;
    //       opacity: 1;
    //       transition: transform .7s $expoOut, opacity 1s $expoOut;
    //     }
    //     h3{
    //       transform: none;
    //       opacity: 1;
    //       transition: transform .7s $expoOut .1s, opacity 1s $expoOut .1s;
    //     }
    //     .btn--alt {
    //       transform: none;
    //       transition: transform .7s $expoOut .2s;
    //     }
    //   }
    //   img {
    //     transform: scale(1.2);
    //     transition: transform .7s $expoOut;
    //   }
    // }
    &:hover, &:focus {
      .project__heading {
        &--main {
          transform: translateY(-200px);
          opacity: 0;
          transition: transform .7s $expoOut, opacity .3s $expoOut;
        }
      }
      .project__content {
        h4 {
          transform: none;
          opacity: 1;
          transition: transform .7s $expoOut, opacity 1s $expoOut;
        }
        h3{
          transform: none;
          opacity: 1;
          transition: transform .7s $expoOut .1s, opacity 1s $expoOut .1s;
        }
        .btn--alt {
          transform: none;
          transition: transform .7s $expoOut .2s;
        }
      }
      img {
        transform: scale(1.2);
        transition: transform .7s $expoOut;
      }
    }
  }
  &.portfolio {
    .project--static {
      opacity: 1;
    }
  }
  .project--static {
    @include min($md) {
      transform: translateY(100px);
      opacity: 0;
      transition: opacity .6s $expoOut, transform .4s $expoOut;
      &.active {
        transform: none;
        opacity: 1;
        transition: opacity .6s $expoOut, transform .4s $expoOut;
        &:nth-child(2n) {
          transition-delay: .2s;
        }
      }
    }
  }
  .projects__content {
    .project--static {
      @include min($md) {
        &:nth-child(1) {
          transition-delay: 2s;
        }
        &:nth-child(2) {
          transition-delay: 2.2s;
        }
      }
    }
  }
  .btn--alt {
    font-variant: small-caps;
    position: absolute;
    bottom: 0;
    left: calc(50% - 81px);
    @include max($sm) {
      position: relative;
      left: 0;
      margin-top: 1rem;
    }
  }
  &.portfolio {
    .project {
      width: 100%;
    }
  }
}
.portfolio {
  @include max($lg) {
    .project__content {
      min-height: 160px;
    }
  }
}
