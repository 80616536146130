.refer {
	display: flex;
	justify-content: center;
	@include min($md) {
		padding: 7vw 0;
	}
	height: 100%;
	&__wrap {
		width: calc(100% - 3rem);
		text-align: center;
		@include max($md) {
			padding: 3rem 1.5rem;

		}
		@include min($lg) {
			width: 50vw;
			padding: 0 1.5rem;
			width: 96rem;
			margin: 0 auto;
		}
		@include min($lap) {
			width: 124rem;
		}
	}
	&__heading {
		font-size: 2rem;
		@include max($md) {
			text-align: center;
			margin-bottom: 1.5rem;
		}
		@include min($md) {
			font-size: 2.2vw;
		}
		@include min($lg) {
			font-size: 1.3vw;
		}
		line-height: (36/25);
		color: #5D6669;
	}
	&__quote {
		@include min($md) {
			font-size: 1.6vw;
		}
		@include min($lg) {
			font-size: 1.2vw;
		}
		line-height: (33/19);
		color: #5D6669;
	}
	&__author {
		@include min($md) {
			font-size: 1.6vw;
		}
		@include min($lg) {
			font-size: 1vw;
		}
		font-style: italic;
		line-height: (44/19);
		font-weight: 300;
	}
}
