.customer {
  position: relative;
  .container--case {
    padding: 11rem 0;
    @include max($lg) {
      padding: 7rem 0;
    }
    @include max($md) {
      padding: 3rem 0;
      overflow: hidden;
    }
    @include max($sm) {
      padding: 0;
    }
  }

  .grid {
    &::after {
      content: '';
      display: none;
    }
  }

  .patternCircle {
    transform: rotate(-30deg);
    width: 200px;
    height: 200px;
    opacity: .5;
  }

  .frame {
    position: absolute;
    top: -66px;
    left: -99px;
    z-index: -1;
    @include max($lap) {
      transform: scale(.75);
      top: -72px;
    }
    @include max($sm) {
      display: none;
    }
  }

  h3 {
    font-size: 3.5rem;
    font-weight: 700;
    &::after {
      content: '';
      display: block;
      margin-top: .5rem;
      width: 0;
      height: 3px;
      background-color: #00D3D5;
      // transition: width .3s ease-in-out .5s;
    }

    &.anim--active {
      &::after {
        content: '';
        width: 100px;
        transition: width .3s ease-in-out .9s;
      }
    }
    @include max($lap) {
      font-size: 2.6rem;
    }
    @include max($sm) {
      font-size: 2.7rem;
      padding: 3rem 0 0 3rem;
    }
  }

  .text {
    overflow: hidden;
  }

  &__about {
    width: 50%;
    position: relative;
    @include max($md) {
      width: 100%;
    }
    p {
      font-weight: 100;
      font-size: 2.3rem;
      line-height: (350/230);
      margin-top: 1rem;
      max-width: 480px;
      color: #046078;
      @include max($lap) {
        font-size: 1.7rem;
        max-width: 360px;
      }
      @include max($lg) {
        max-width: calc(100% - 20px);
      }
      @include max($sm) {
        font-size: 1.6rem;
        margin-top: 1.5rem;
        line-height: (350/230);
        padding: 0 3rem;
        font-weight: 400;
      }
    }

    &--mieszko {
      p {
        max-width: 544px;
      }
    }
  }

  .tasks {
    left: 50%;
    bottom: -55px;
    @include btw($md, $lg) {
      padding: 2.5rem 3rem 4rem;
      top: 15px;
      z-index: 2;
      bottom: auto;
      li {
        font-size: 2.2rem;
      }
    }
    @include max($md) {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      margin-top: 2rem;
    }
    @include max($sm) {
      padding: 2.5rem 3rem 4rem;
      top: 15px;
      z-index: 2;
      li {
        font-size: 1.6rem;
      }
    }
    &--lessPadding {
      @include max($lg) {
        h4 {
          font-size: 4rem;

          @include max($sm) {
            font-size: 2.8rem;
          }
        }

        li {
          font-size: 2.2rem;
        }
      }

      @include btw($md, $lg) {
        padding: 2.5rem 5rem 4rem;
        top: 15px;
        z-index: 2;
        bottom: auto;
        li {
          font-size: 1.8rem;
        }
      }

      @include max($sm) {
        padding: 2.5rem 3rem 4rem;
        top: 15px;
        z-index: 2;

        li {
          font-size: 1.6rem;
        }
      }
    }

    &--sm {
      padding: 50px;
      @include max($sm) {
        padding: 2.5rem 3rem 4rem;
      }
    }
  }

  h4 {
    text-align: center;
    font-size: 6.1rem;
    font-weight: 900;
    @include max($lap){
      font-size: 4.55rem;
    }
    @include max($sm) {
      font-size: 2.8rem;
    }
  }

  .patternContainer--Ohiden {
    position: absolute;
    bottom: 0;
    left: -10px;
    overflow: hidden;
    height: 50px;
  }
}


// ********* EXTENDED *********
.customer--extended {
  .tasks {
    bottom: 30px;
    @include btw($md, $lg) {
      padding: 2.5rem 3rem 4rem;
      top: 15px;
      z-index: 2;
      bottom: auto;
      p {
        font-size: 2.2rem;
      }
    }
  }
}
