.btn {
	font-variant: small-caps;
	display: flex;
	align-items: center;
	padding: 0 3.5rem;
	color: #00D3D5;
	font-size: (3.727rem * 0.5);
	@include min($lap) {
		font-size: 3.727rem;
	}
	line-height: (4472/3727);
	font-weight: 500;
	border: 1px solid #00D3D5;
	cursor: pointer;
	position: relative;
	z-index: 1;
	&:hover {
		color: #fff;
	}
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		background-color: #00D3D5;
		z-index: -1;
		transform: scaleX(0);
		transform-origin: right top;
		transition: transform 0.3s ease-in-out;
	}
	&:hover {
		&::after {
			transform: scaleX(1);
			transform-origin: left top;
		}
	}
	&--alt {
		background-color: #00D3D5;
		color: #fff;
		font-size: 18px;
		font-weight: 700;
		z-index: 10;
		padding: 1.2rem 2.5rem;
		display: inline-block;
		transition: background-color .2s ease-in-out;
		// &:hover {
		// 	background-color: #00a9d5;
		// 	transition: background-color .2s ease-in-out;
		//
		// }
	}
	&--lighter {
		border: 1px solid #fff;
		color: #fff;
		// &:hover {
		// 	border: 1px solid #00D3D5;
		// }
	}
	&--bigger {
		background-color: #046078;
		border: none;
		color: #fff;
		font-size: 3rem;
		line-height: (760/360);
		font-variant: normal;
		text-transform: uppercase;
		// padding: 0 12rem;
		width: 360px;
		display: inline-block;
		font-weight: 400;
		position: absolute;
		bottom: -35px;
		left: calc(50% - 180px);
		text-align: center;
		@include max($lap) {
			width: 250px;
			left: calc(50% - 125px);
			font-size: 2.5rem;
			bottom: -26px;
		}
		@include max($md) {
			width: 250px;
			left: calc(50% - 125px);
			font-size: 2rem;
			bottom: -21px;

		}
		@include max($sm) {
			width: 100%;
			left: 0;
			bottom: -10px;
		}
	}
}
