.clients {
	display: flex;
	justify-content: space-around;
	height: 100%;
	@include min($sm) {
		align-items: center;
	}
	@include min($lg) {
		padding: 0 12vw 0 20vw;
	}
	@include max($lg) {
		flex-direction: column;
		margin: 5rem 0 3rem;
	}
	&__info {
		@include min($lg) {
			margin-right: 4rem;
		}
		@include min($lap) {
			margin-right: 6rem;
		}
	}
	&__heading {
		font-size: 5rem;
		display: flex;
		align-items: center;
		@include max($lg) {
			flex-direction: column;
		}
		@include min($sm) {
			font-size: 5rem;
		}
		@include max($md) {
			font-size: 7rem;
		}
		@include max($sm) {
			margin-top: 4rem;
			padding: 0 2.5rem 1.5rem;
			align-items: flex-start;
			font-size: 5rem;
		}
		@include min($lap) {
			font-size: 7rem;
		}
		line-height: (1416/1180);
		font-weight: 900;
		position: relative;
		z-index: 1;
		padding-bottom: 1.5rem;
		&::after {
			content: '';
			display: block;
			height: 0.6rem;
			width: 8rem;
			@include min($lg) {
				margin-left: 4rem;
			}
			@include min($lap) {
				width: 16rem;
			}
			background-color: #00D3D5;
		}
	}
	&__btn {
		padding: 0;
		display: flex;
		justify-content: center;
		margin: 1rem 0 0;
		padding: 1rem 4rem 1.6rem;
		font-weight: 700;
		font-size: 2rem;
		@include max($xs) {
			width: 100%;
			justify-content: center;
		}
		@include min($lap) {
			font-size: 2.3rem;
			padding: 1.6rem 4rem 2rem;
		}
	}
	&__stack {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: space-between;
		flex-shrink: 0;
		@include max($lg) {
			margin-top: 2rem;
			padding: 0 1.5rem;
		}
		@include min($lg) {
			width: 40vw;
		}
		@media (min-width: 1279px) and (max-width: 1281px) and (min-height: 750px) and (max-height: 801px) {
			margin-right: 10rem;
		}
	}
	&__logo {
		width: calc(50% - 0.75rem);
		height: calc(50vw - 1.5rem);
		@include max($sm) {
			margin-bottom: 1.5rem;
		}
		@include min($sm) {
			height: calc(24vw - 1.5rem);
			width: calc(24vw - 1.5rem);
			margin-bottom: 1.5rem;
		}
		@include min($lg) {
			height: calc(10vw - 1.5rem);
			width: calc(10vw - 1.5rem);
			margin-bottom: 1.5rem;
		}
		border: 0.2rem solid #ECECEC;
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		@include max($md) {
			&:first-child {
				span {
					&::after {
						content: '';
						width: 100%;
						height: 3px;
						position: absolute;
						top: -1px;
						left: 0;
						display: block;
						background-color: white;
					}
				}
			}
		}
		&:nth-child(7) {
			span {
				@include btw($lg, $lap) {
					max-width: 90%;
				}
			}
		}
		&:nth-child(8) {
			span {
				@include btw($lg, $lap) {
					max-width: 65%;
				}
			}
		}
		&:hover {
			span:last-child {
				opacity: 1;
				// filter: grayscale(0);
			}
		}
		span {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			max-width: 85%;
			// filter: grayscale(100%);
			transition: 0.3s;
			&:last-child {
				opacity: 0;
			}
			@include max($sm) {
				transform: translate(-50%, -50%) scale(.8);
				max-width: none;
			}
		}
		&::after,
		&::before {
			content: '';
			width: calc(100% - 5rem);
			height: calc(100% + 0.6rem);
			display: block;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: -1;
		}
		&::after {
			width: calc(100% + 0.6rem);
			height: calc(100% - 5rem);
		}
	}
}
.clients {
	&__hand {
		position: absolute;
		left: 6vw;
		bottom: 0;
		z-index: 2;
		transform: translateX(-50%) scale(0.7);
		transform-origin: left bottom;
		transition: 1s ease-in-out;
		transition-property: opacity, transform;
		opacity: 0;
		@include min($lap) {
			transform: translateX(-200%);
		}
	}
	&__bordered {
		position: absolute;
		left: -0.5vw;
		bottom: 0;
		z-index: 3;
		border-width: 4.7vw;
		height: 17vw;
		width: 17vw;
		transform: translate(-90%, 40%);
		opacity: 0;
		transition: 1s ease-in-out;
		transition-property: opacity, transform;
	}
	&__pattern {
		z-index: 1;
		position: absolute;
		left: 0.5vw;
		bottom: 0;
		width: 9vw;
		height: 9vw;
		z-index: 1;
		opacity: 0;
		transform: translate(-40%, -50%) rotate(-35deg);
		transition: 1s ease-in-out;
		transition-property: opacity, transform;
	}
}
.active {
	.clients {
		&__hand {
			transition: 1s ease-in-out 0.7s;
			transition-property: opacity, transform;
			transform: scale(0.7);
			opacity: 1;
			@include min($lap) {
				transform: none;
			}
		}
		&__bordered {
			transition: 1s ease-in-out 1s;
			transition-property: opacity, transform;
			transform: translate(-40%, 40%);
			opacity: 1;
		}
		&__pattern {
			opacity: 1;
			transition: 1s ease-in-out 0.7s;
			transition-property: opacity, transform;
			transform: translate(10%, -50%) rotate(-35deg);
		}
	}
}
