.pagination {
  padding: 0 0 10rem;
  text-align: center;
  .page-numbers {
    font-size: 2rem;
    font-weight: 800;
    margin-right: 1rem;
  }
  .current {
    color: #00d2d4;
  }
  .prev, .next {
    background-color: #00d2d4;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.6rem;
    padding: 1rem 4rem;
  }
  .prev {
    margin-right: 3rem;
  }
  .next {
    margin-left: 3rem;
  }
  .screen-reader-text {
    display: none;
  }
}
