.simpleDescription {
  display: flex;
  align-items: center;
  @include max($sm) {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .container--case {
    display: flex;
    align-items: center;
    @include max($sm) {
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  }
  p {
    max-width: 640px;
    color: #046078;
    font-size: 27px;
    line-height: (400/270);
    font-weight: 300;
    margin-top: 3rem;
    @include max($lap) {
      font-size: 2rem;
      max-width: 480px;
    }
    @include max($sm) {
      margin-top: 1rem;
      font-size: 1.6rem;
      line-height: (400/270);
    }
  }
  h5 {
    font-size: 74px;
    @include max($lap) {
      font-size: 55.5px;
    }
    @include max($sm) {
      font-size: 4rem;
      line-height: 1;
    }
    &::after {
      content: '';
      display: block;
      margin-top: 1rem;
      width: 0;
      height: 5px;
      background-color: #00D3D5;
    }
    &.anim--active {
      &::after {
        content: '';
        width: 125px;
        transition: width .3s ease-in-out .9s;
      }
    }
  }
  .heading--smaller {
    font-size: 6.1rem;
    font-weight: 900;
    color: #35302C;
    @include max($lap) {
      font-size: 4.55rem;
    }
    @include max($sm) {
      font-size: 3rem;
    }
    &::after {
      content: '';
      margin-top: 2rem;
    }
  }
  .text--smaller {
    font-size: 2rem;
    line-height: (330/200);
    margin-top: 5rem;
    @include max($sm) {
      margin-top: 2rem;
      font-size: 1.6rem;
      line-height: (330/200);
    }
  }
  .kefiry {
    width: 50%;
    margin-left: 50px;
    transform: translateY(-105px);
    flex-shrink: 0;
    z-index: 2;
    position: relative;
    @include max($lap) {
      transform: translateY(-60px);
    }
    @include max($lg) {
      width: calc(50% - 50px);
    }
    @include max($md) {
      transform: none;
      margin-left: 0;
    }
    @include max($sm) {
      width: 100%;
    }
    img {
      @include max($lap) {
        max-height: 500px;
      }
      @include max($sm) {
        max-height: 230px;
      }
    }
  }
  .kefiry--small {
    img {
      @include max($lap) {
        max-width: 379.5px;
      }
      @include max($lg) {
        max-width: 400px;
      }
      @include max($md) {
        max-width: 350px;
      }
      @include max($sm) {
        max-width: 90%;
      }
    }
  }
  &__content {
    width: calc(50% - 50px);
    z-index: 2;
    @include max($lg) {
      width: calc(50% + 50px);
      padding-right: .5rem;
    }
    @include max($md) {
      padding-left: 3rem;
    }
    @include max($sm) {
      width: 100%;
      padding: 2rem;
    }
  }
  .stripes {
    display: flex;
    position: absolute;
    top: 434px;
    left: -74px;
    z-index: -1;
    @include max($lap) {
      top: 210px;
    }
    @include max($lg) {
      top: 200px;
    }
    @include max($md) {
      top: 120px;
      left: -210px;
    }
    img {
      &:nth-child(3) {
        @include max($lap) {
          display: none;
        }
      }
    }
  }
  .plus--case {
    position: absolute;
    bottom: -90px;
    left: 125px;
    @include max($lap) {
      bottom: -50px;
      left: 200px;
    }
    @include max($md) {
      bottom: 0px;
      left: 230px;
      transform: scale(.7);
    }
  }
}

// ********* EXTENDED *********

.simpleDescription--extended {
  position: relative;
  flex-wrap: wrap;
  padding: 0 0 230px;
  @include max($lg) {
    padding: 0 0 100px;
  }
  @include max($md) {
    padding: 0 0 35rem;
  }
  @include max($sm) {
    padding: 0 0 0;
  }
  .heading--smaller {
    @include max($lg) {
      font-size: 4.5rem;
    }
    @include max($sm) {
      font-size: 3.5rem;
    }
  }
  .column {
    width: 50%;
    @include max($md) {
      width: 100%;
    }
    &:nth-child(1) {
      position: relative;
      @include max($md) {
        position: static;
      }
    }
  }
  .simpleDescription__content {
    background-color: #E5E5E5;
    width: 100%;
    margin-top: 75px;
    overflow: hidden;
    padding: 120px 90px 120px 160px;
    @include max($lap) {
      margin-top: 35px;
      padding: 50px 30px 50px 70px;
    }
    @include max($sm) {
      padding: 2rem;
      margin-top: 20px;
    }
  }
  p {
    font-size: 23px;
    line-height: (400/230);
    @include max($lap) {
      font-size: 1.7rem;
    }
    @include max($sm) {
      font-size: 1.6rem;
      line-height: (400/230);
    }
  }
  .pc {
    position: absolute;
    right: 0;
    top: -80px;
    @include max($lap) {
      max-height: 694.5px;
    }
    @include max($lg) {
      max-height: 500px;
      top: 0;
    }
    @include max($md) {
      max-height: 400px;
      bottom: 1rem;
      top: auto;
    }
    @include max($sm) {
      position: relative;
      max-height: none;
      width: 100%;
      right: 0;
    }
  }
  .shakes {
    position: absolute;
    bottom: -195px;
    right: -428px;
    z-index: 2;
    @include max($lap) {
      max-width: 434px;
      right: -250px;
    }
    @include max($lg) {
      max-width: 400px;
      bottom: -98px;
      right: -359px;
    }
    @include max($md) {
      bottom: 0;
      left: 20%;
      max-width: 300px;
    }
    @include max($sm) {
      max-width: 300px;
      right: 50%;
      bottom: 30px;
      position: relative;
    }
  }
  .stripes {
    bottom: 136px;
    top: auto;
    @include max($lap) {
      // transform: scale(.75);
      bottom: 120px;
    }
    @include max($sm) {
      left: -50px;
    }
    img {
      @include max($lap) {
        height: 50px;
      }
      @include max($sm) {
        height: 30px;
      }
    }
  }
}

// ********* LP *********
.simpleDescription--lp {
  position: relative;
  padding: 200px 0 550px;
  @include max($xl) {
    padding: 200px 0 450px;
  }
  @include max($lap) {
    padding: 200px 0 350px;
  }
  @include max($lg) {
    padding: 50px 0 250px;
  }
  @include max($md) {
    padding: 50px 0;
  }
  @include max($sm) {
    padding: 0;
  }
  .container--case {
    @include max($md) {
      flex-wrap: wrap;
    }
  }
  .pc {
    position: absolute;
    right: 0;
    top: 150px;
    @include max($xl) {
      width: 50%;
      top: 200px;
      img {
        max-width: 100%;
      }
    }
    @include max($lg) {
      width: 50%;
      bottom: 200px;
      top: auto;
    }
    @include max($md) {
      width: 100%;
      position: static;
      transform: translateX(70px);
    }
  }
  h5.heading--smaller {
    @include max($lg) {
      font-size: 5rem;
    }
    @include max($sm) {
      font-size: 3.5rem;
    }
  }
  .container--case {
    @include min($lg) {
      padding: 0;
    }
  }
  .simpleDescription__content {
     width: calc(50% + 50px);
     @include max($md) {
       width: 100%;
     }
   }
   p.text--smaller {
     max-width: 610px;
     margin-top: 3rem;
     @include max($lap) {
       font-size: 1.5rem;
     }
   }
}


// ********* MOVING DAY *********

.simpleDescription--movingDay {
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  margin-bottom: -200px;
  @include max($lap) {
    margin-bottom: 0;
  }
  @include max($md) {
    margin-bottom: 10px;
  }
  .column {
    &:nth-child(1) {
      width: calc(50% + 10vw);
      position: relative;
      @include max($sm) {
        width: 100%;
      }
      img {
        position: absolute;
        bottom: -100%;
        right: 10px;
        z-index: 2;
        @include max($lap) {
          position: static;
          max-width: 100%;
        }
      }
    }
    &:nth-child(2) {
      width: calc( 50% - 11vw);
      z-index: 2;
      @include max($sm) {
        width: 100%;
      }
      img {
        margin-top: 8rem;
        z-index: 2;
        @include max($lap) {
          max-width: 100%;
        }
        @include max($md) {
          max-width: 100%;
          margin-top: 0;
        }
      }
    }
  }

  .simpleDescription__content {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10rem;
    padding-bottom: 17rem;
    @include max($md) {
      padding: 4rem 2rem;
      align-items: flex-start;
    }
    @include max($sm) {
      padding-right: 0;
    }
  }
  .heading--smaller {
    @include max($sm) {
        font-size: 3rem;
    }
  }
  p {
    font-size: 2rem;
    max-width: 670px;
    @include max($sm) {
      font-size: 1.6rem;
      margin-top: 2rem;
    }
  }
  .heading--smaller {
    @include btw($sm, $md) {
     font-size: 4rem;
    }
  }
}
