// General
*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	font-size: 62.5%;
	box-sizing: border-box;
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	font-size: 1.6rem;
	// scroll-behavior: auto;
	font-family: 'Lato', sans-serif;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}
a {
	cursor: pointer;
}

.no-scroll {
	overflow: hidden;
}

*::selection {
	background-color: #00d3d5;
	color: #fff;
}
