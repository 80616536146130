.promoPhotos {
  &--alt {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .promoPhotos__image {
      width: 50%;
      &--wider {
        width: 100%;
      }
      &:nth-child(3) {
        width: 100%;
      }
    }
  }
  .grid {
    padding: 20px 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after {
      content: '';
      display: none;
    }
  }
  &__image {
    width: calc(50% - 100px);
    text-align: right;
    @include max($sm) {
      width: 100%;
    }
    &:nth-child(2n) {
      .anim--image {
        transition: opacity .7s ease-in-out .3s, transform .6s ease-in-out .3s;
      }
    }
    &--wider {
      width: calc(50% + 70px);
      transform: translateY(-75px);
      @include max($sm) {
        width: 100%;
        transform: none;
      }
    }
    &:nth-child(3) {
      margin-top: 65px;
      position: relative;
      @include max($md) {
        margin-top: 2rem;
        width: calc(50% - 70px);
      }
      @include max($sm) {
        width: 100%;
        margin-top: 0;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        right: -30px;
        bottom: -30px;
        background-color: #00D3D5;
        width: 4px;
        height: 60px;
        @include max($md) {
          right: -10px;
          bottom: -10px;
        }
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: -30px;
        bottom: -30px;
        background-color: #00D3D5;
        height: 4px;
        width: 60px;
        @include max($md) {
          right: -10px;
          bottom: -10px;
        }
      }
    }
    img {
      max-width: 100%;
    }
  }
  &__text {
    width: calc(50% - 100px);
    @include max($md) {
      width: calc(50% + 40px);
    }
    @include max($sm) {
      width: 100%;
      margin-top: 2rem;
      text-align: center;
    }
  }
  p {
    font-size: 2.3rem;
    line-height: 35px;
    max-width: 510px;
    padding-left: 100px;
    font-weight: 300;
    @include max($lap) {
      padding-left: 30px;
    }
    @include max($lg) {
      padding-left: 0;
    }
    @include max($md) {
      max-width: 100%;
    }
    @include max($sm) {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: (350/230);
      padding: 2rem;
    }
  }
  .stripes {
    position: absolute;
    overflow: hidden;
    top: -10px;
    left: calc(50% + 90px);
  }
  &--lp {
    .promoPhotos__image {
      width: 50%;
      @include max($sm) {
        width: 100%;
      }
      &:nth-child(1) {
        text-align: left;
        transform: translateY(-50%);
        @include max($lg) {
          transform: translateY(-140px);
        }
        @include max($md) {
          transform: translateY(-70px);
        }
        @include max($sm) {
          transform: none;
        }
      }
      &:nth-child(2) {
        text-align: right;
        margin-top: -250px;
        @include max($lg) {
          margin-top: -100px;
        }
        @include max($md) {
          margin-top: -50px;
        }
        @include max($sm) {
          margin-top: 0;
        }
      }
    }
    .row {
      margin: 10rem 0 0;
      justify-content: space-between;
      @include max($sm) {
        margin: 2rem 0 0;
      }
    }
    .image {
      width: calc(100%/3 - 10px);
      @include max($sm) {
        width: 100%;
      }
      &:nth-child(3n-1) {
        img {
          transition-delay: .2s;
        }
      }
      &:nth-child(3n) {
        img {
          transition-delay: .3s;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    h5 {
      font-size: 6.1rem;
      line-height: 1.3;
      font-weight: 900;
      color: #35302C;
      @include max($lap) {
        font-size: 4.55rem;
      }
      @include max($md) {
        font-size: 5.5rem;
      }
      @include max($sm) {
        font-size: 3rem;
        line-height: 1;
        text-align: center;
        margin-top: 3rem;
      }
      &::after {
        content: '';
        display: block;
        margin-top: 1.5rem;
        width: 0;
        height: 5px;
        background-color: #00D3D5;
        @include max($md) {
          margin-top: .5rem;
        }
        @include max($sm) {
          margin: 1.5rem auto;
        }
      }
      &.anim--active {
        &::after {
          content: '';
          width: 100px;
          transition: width .3s ease-in-out .9s;
        }
      }
    }
    .caseDescription__content {
      width: auto;
      @include max($sm) {
        margin: 0;
      }
    }
    p {
      font-size: 2rem;
      color: #046078;
      margin-top: 40px;
      line-height: (300/200);
      max-width: 630px;
      padding-left: 0;
      @include max($lap) {
        font-size: 1.6rem;
      }
      @include max($md) {
        max-width: 100%;
        margin-top: 20px;
      }
      @include max($sm) {
        // margin-top: 10px;
        padding: 0 1rem 2rem;
        margin: 0;
        font-size: 1.6rem;
        line-height: (300/200);
      }
    }
    .stripes {
      top: -250px;
      right: -10px;
      left: auto;
      @include max($lg) {
        top: -180px;
        right: -150px;
      }
      @include max($sm) {
        display: none;
      }
    }
  }
  &--movingDay {
    .grid {
      padding: 7rem 0 0;
      justify-content: space-around;
      align-items: flex-start;
      @include max($sm) {
        flex-direction: column;
        padding: 4rem 1rem;
      }
    }
    .column {
      width: calc(50% - 30px);
      @include max($sm) {
        width: 100%;
      }
      &:nth-child(2) {
        .promoPhotos__image {
          text-align: left;
          &:last-child {
            transform: translate(-250px, 20px);
            margin-top: 0;
            @include max($md) {
              transform: translate(-150px, 0);
            }
            @include max($sm) {
              transform: none;
              margin-top: 20px;
            }
          }
        }
      }
    }
    .promoPhotos__image {
      width: 100%;
      position: relative;
      @include max($sm) {
        width: 100%;
      }
    }
    .promoPhotos__text {
      margin: 4rem 4rem 8rem;
      width: 100%;
      @include max($md) {
        margin: 2rem 0;
      }
      @include max($sm) {
        margin: 1rem auto;
      }
      p {
        padding: 0;
        color: #046078;
        font-size: 2rem;
        font-weight: 300;
        @include max($sm) {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: (350/230);
          padding: 2rem 1rem;
        }
      }
    }
    .borderedCircle {
      position: absolute;
      bottom: 84px;
      left: 378px;
      z-index: -1;
      width: 180px;
      height: 180px;
      border: 5rem solid #00D3D5;
    }
    .icon-strategyPlus {
      position: absolute;
      bottom: -50px;
      right: 119px;
    }
    img {
      @include max($lap) {
        max-width: 70%;
      }
    }
  }
  &--benvenuti {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 6rem 0 2rem;
    @include max($sm) {
      padding: 0;
      flex-wrap: wrap;
    }
    .promoPhotos__image {
      margin-top: 2rem;
      width: 100%;
      @include max($sm) {
        margin-top: .5rem;
      }
    }
    .column {
      width: calc(50% - 10px);
      z-index: 2;
      @include max($sm) {
        width: 100%;
      }
      &:nth-child(1) {
        margin-top: 6rem;
        @include max($sm) {
          margin-top: 0;
        }
        .promoPhotos__image {
          text-align: right;
        }
      }
      &:nth-child(2) {
        .promoPhotos__image {
          text-align: left;
        }
        img {
          transition-delay: .3s;
        }
      }
    }
    img {
      max-width: 100%;
      @include max($lap) {
        max-width: 75%;
        max-height: 412px;
      }
      @include max($sm) {
        max-width: 100%;
      }
    }
    &.grid {
      &::after {
        display: none;
      }
      .promoPhotos__image {
        position: relative;
      }
      .column {
        margin-top: -210px;
        @include max($sm) {
          margin-top: 0;
        }
        &:nth-child(1) {
            margin-top: -210px;
            @include max($sm) {
              margin-top: 0;
            }
        }
        &:nth-child(2) {
          .promoPhotos__image:first-child {
            margin-top: 14rem;
            @include max($sm) {
              margin-top: 0;
            }
          }
        }
      }
      .borderedCircle, .icon-strategyPlus {
        position: absolute;
      }
      .stripes {
        z-index: 2;
        top: 75px;
        right: -212px;
      }
      .icon-strategyPlus {
        bottom: -75px;
        left: 230px;
      }
      .borderedCircle {
        width: 160px;
        height: 160px;
        border: 4.5rem solid #00D3D5;
        right: 120px;
        bottom: -74px;
        z-index: -1;
        @include max($sm) {
          bottom: -20px;
        }
      }
    }
  }
}
