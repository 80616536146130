.swiper {
  .swiper-slide {
    height: 22vw;
    @include max($lap) {
      height: 23vw;
    }
    @include max($lg) {
      height: 32vw;
    }
    @include max($md) {
      height: 40vw;
    }
    @include max($sm) {
      height: 350px;
    }
    @include max($xs) {
      height: 80vw;
    }
    &:nth-child(4n) {
      .anim--image {
        transition-delay: .4s;
      }
    }
    &:nth-child(4n - 1) {
      .anim--image {
        transition-delay: .3s;
      }
    }
    &:nth-child(4n - 2) {
      .anim--image {
        transition-delay: .2s;
      }
    }
  }
  &__background {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  img {
    object-fit: fill;
  }
}
