.tasks {
  position: absolute;
  background-color: #046078;
  color: #fff;
  padding: 8rem 4.5rem 10rem;
  width: 750px;
  z-index: 2;
  &--mobile {
    position: relative;
    @include min($md) {
      display: none;
    }
  }
  @include max($lap){
    width: 562px;
    padding: 6rem 4.5rem 7.5rem;
  }
  @include max($lg) {
    width: calc(50% - 10px);
    padding: 4rem 2rem 4rem;
  }
  &:not(.tasks--mobile) {
    @include max($md) {
      display: none;
    }
  }
  li {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 35px;
    max-width: 580px;
    margin: 1.5rem auto 0;
    list-style-type: square;
    @include max($lap){
      font-size: 1.85rem;
      line-height: (350/250);
      font-weight: 600;
    }
    @include btw($md, $lg) {
      font-weight: 400;
      line-height: (350/250);
    }
    @include max($sm) {
      font-weight: 400;
      line-height: (350/250);
    }
    // &:nth-child(2){
    //   margin-top: 3rem;
    //   @include max($sm) {
    //     margin-top: 1rem;
    //
    //   }
    // }
  }
  &--lessPadding {
    li {
      max-width: 630px;
      margin: .5rem auto 0;
    }
    h4 {
      text-align: center;
    }
  }
  &--benvenuti {
    p {
      max-width: 700px;
    }
  }
}
