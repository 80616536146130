.posts {
  padding-top: 2.5rem;
  margin-bottom: 6rem;

  @include min($sm) {
    margin-bottom: 8rem;
  }

  .container--small {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include btw($lg, $lap) {
      width: 70vw;
    }
  }

  .postBox--small {
    margin: 4rem 0 0 0;

    @include min($sm) {
      margin: 0 0 6rem 0;
    }


  }

  .heading {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: (600/280);
    color: #999999;
    width: 100%;
    transition-delay: 1.9s;
  }

  .postBox--big {

    @include min($sm) {
      margin-bottom: 6rem;
    }
  }
}
