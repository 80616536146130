.post {

  img {
    max-width: 100%;
    display: block;
    margin: 5rem auto;
  }

  .post__list {

    p:first-child {
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 1.4rem;

      @include min($lg) {
        font-size: 1.7vw;
      }

      @include min($lap) {
        font-size: 1.4vw;
      }
    }
  }

  p, h5 {
    font-size: 1.8rem;
    font-weight: 100;

    @include min($lg) {
      font-size: 1.6vw;
    }

    @include min($lap) {
      font-size: 1.1vw;
    }
  }

  h5 {
    font-weight: bold;
  }

  h4 {
    font-size: 1.9rem;
    margin-top: 4.5rem;

    @include min($lg) {
      font-size: 1.7vw;
      margin-top: 8rem;
    }

    @include min($lap) {
      font-size: 1.4vw;
    }
  }

  p {
    margin: 4rem 0;
  }

  .post__video {
    margin: 5rem 0;

    @include max($sm) {
      position: relative;
      height: 0;
      padding-bottom: 56.25%;
    }

    iframe {

      @include max($sm) {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  .post__video {
    display: flex;
    justify-content: center;
  }

  .post__fbSection {
    margin-bottom: 2rem;
  }

  .post__separator {
    margin-bottom: 3rem;
    background-color: #B3B3B3;
  }
}
