.follow {
	display: flex;
	align-items: center;
	text-align: center;
	@include max($md) {
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&__tag {
		@include max($md) {
			width: 100%;
			padding: 1.5rem 0;
		}
		@include min($md) {
			margin-right: 1vw;
		}
	}
	&__icon:not(:last-child) {
		@include min($md) {
			margin-right: 1vw;
		}
	}
}