.heading {
	position: relative;
	z-index: 1;
	&__bordered {
		width: 7.3vw;
		height: 7.3vw;
		border-width: 2.1vw;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
	}
	&__pattern {
		width: 4vw;
		height: 4vw;
		opacity: 0.46;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
	}
	&--portfolio {
		.heading {
			&__bordered {
				transform: translate(-45%, -30%);
			}
			&__pattern {
				transform: translate(-135%, -30%) rotate(-35deg);
			}
		}
	}
	&--clients {
		.heading {
			&__bordered {
				transform: translate(-40%, -25%);
			}
			&__pattern {
				transform: translate(80%, 65%) rotate(-35deg);
			}
		}
	}
}