.introClients {
	height: 50vw;
	@include min($md) {
		height: 27vw;
	}
	@include max($sm) {
		// height: 51vw;
		padding-top: 70px;
		height: 220px;
		padding-bottom: 2rem;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		z-index: -1;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('img/patternBg.png');
	}
	.btn--lighter:not(.mobile) {
    position: absolute;
    top: 80px;
    padding: 1.5rem 2rem 1.8rem;
    right: 137px;
    height: 3.9rem;
    font-size: 1.9rem;
    @include max($lap) {
      height: 3rem;
    }
    @include max($lg) {
      top: auto;
      bottom: 30px;
      right: 50%;
      height: 3.9rem;
      transform: translateX(50%);
    }
    @include max($md) {
      bottom: 10px;
    }
    @include max($sm) {
      display: none;
    }
  }
  .btn--lighter.mobile {
    @include min($sm) {
      display: none;
    }
    @include max($sm) {
      position: relative;
      margin: 0 1rem;
      height: 4rem;

      // top: auto;
      // right: auto;
    }
  }
	&--line {
		&::after {
			content: '';
			display: block;
			height: 4rem;
			width: 0.2rem;
			background-color: #03D3D5;
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateY(50%);
			@include min($sm) {
				height: 8rem;
				bottom: 2rem;
			}
			@include min($lap) {
				height: 14rem;
				bottom: 4rem;
			}
		}
	}
	&__iconAnchor {
		display: flex;
		align-items: center;
		@include max($xs) {
			height: 7rem;
		}
	}
	.introClients__iconAnchor img{
		transform: scale(0.5);
		// display: flex;
		@include min($xs) {
			transform: scale(0.7);
		}
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 2;
	background-size: cover;
	&__bordered {
		width: 40vw;
		height: 40vw;
		border-width: 12vw;
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-45%, -50%);
		z-index: 0;
	}
	&__pattern {
		width: 12vw;
		height: 12vw;
		transform: translate(20%, 40%) rotate(-35deg);
		position: absolute;
		bottom: 0;
		right: 0;
		@include max($sm) {
			display: none;
		}
	}
	&__heading {
		color: #fff;
		font-weight: 900;
		line-height: (89/69);
		position: relative;
		z-index: 1;
		@include min($sm) {
			font-size: 3.5rem;
		}
		@include min($md) {
			font-size: 3.6vw;
		}
		&--offers {
			&::after {
				content: "";
				width: 9.2rem;
				height: 0.6rem;
				background-color: #00D3D5;
				display: block;
				position: absolute;
				left: 50%;
				bottom: -1.5rem;
				transform: translateX(-50%);
				@include min($sm) {
					bottom: -2rem;
				}
				@include min($lap) {
					width: 15rem;
					bottom: -4rem;
				}
			}
		}
	}
}
