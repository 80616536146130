.postBox {
  box-shadow: inset 0 0 1px #000;
  position: relative;

  &--big {
    width: 100%;

    .postBox__content {
      text-align: center;
      padding: 5rem 20% 10rem;

      @include max($md) {
        padding: 5rem 10% 10rem;
      }

      @include max($sm) {
        padding: 3rem 2rem 5rem;
      }
    }

    .postBox__heading {
      font-size: 7rem;
      margin: 1.5rem 0;

      @include max($lap) {
        font-size: 6rem;
      }

      @include max($md) {
        font-size: 5rem;
        margin: 1rem 0;
      }

      @include max($sm) {
        font-size: 3rem;
      }
    }

    .postBox__text {
      font-size: 2.3rem;
      line-height: (280/230);

      @include max($lap) {
        font-size: 2rem;
      }

      @include max($sm) {
        font-size: 1.6rem;
      }
    }

    .postBox__imageWrap {
      height: 550px;
      width: 100%;

      @include max($lg) {
        height: 350px;
      }

      @include max($md) {
        height: 400px;
      }

      @include max($sm) {
        height: 250px;
      }
    }
  }

  &--small {
    width: calc(100%/2 - 20px);

    @include max($sm) {
      width: 100%;
      margin-top: 4rem;
    }

    .postBox__content {
      padding: 2rem 1.5rem 7rem 3.5rem;

      @include max($sm) {
        padding: 3rem 2rem 5rem;
        text-align: center;
      }
    }

    .postBox__heading {
      font-weight: 700;
      font-size: 3.3rem;
      margin: 1.5rem 0;

      @include max($md) {
        font-size: 2.8rem;
        line-height: (760/730);
        margin: 1rem 0;
      }
    }

    .postBox__text {
      font-size: 1.9rem;
      line-height: (250/220);
      height: 13%;

      @include max($lap) {
        font-size: 1.8rem;
      }

      @include max($sm) {
        font-size: 1.6rem;
      }
    }

    .postBox__imageWrap {
      height: 300px;
      width: 100%;

      height: 160px;

      @include min($xs) {
        height: 250px;
      }

      @include min($sm) {
        height: 200px;
      }

      @include min($lg) {
        height: 250px;
      }

      @include min($lap) {
        height: 300px;
      }
    }
  }

  &__image {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  &__content {
    border-top: none;
    margin-top: -.1rem;
  }

  &__date {
    color: #757575;
    font-size: 2rem;
    text-align: center;
  }

  &__heading {
    text-align: center;
  }

  &__text {
    font-weight: 100;
  }

  &:last-child {

    @include min($md) {
      transition-delay: .2s;
    }
  }
}
