.incStack {
	&__image {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include max($lg) {
			transform: translate(-50%, -50%) scale(0.7);
		}
		&:last-child {
			opacity: 0;
			transition: 0.3s;

			&:hover {
				opacity: 1;
			}
		}
	}
	&__wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin: 0 auto;
		@include min($lg) {
		// 	padding: 0 10vw 8vw;
			width: 124rem;
			justify-content: space-between;
			margin: 5rem auto 5rem;
		}
		@include btw($lg, $lap) {
			width: 96rem;
		  }
		}
		// @include min($lg) {
		// 	padding: 0 23vw 8vw;
		// }
	&__brand {
		border: 3px solid #ededed;
		margin: 1.5rem;
		width: calc(50vw - 3.75rem);
		height: calc(50vw - 3.75rem);
		@include min($md) {
			width: 15vw;
			height: 15vw;
			// margin: 3vw 3.6vw 0;
		}
		@include min($md) {
			height: 200px;
			width: calc(20% - 1.5rem);
		}
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// filter: grayscale(100%);
		// transition: filter 0.3s ease-in-out;
		transition: 0.3s;
		position: relative;
		z-index: 1;
		@include max($md) {
			&:first-child {
				.incStack__image {
					&::after {
						content: '';
						width: 100%;
						height: 3px;
						position: absolute;
						top: -1px;
						left: 0;
						display: block;
						background-color: white;
					}
				}
			}
		}
		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: #fff;
			width: calc(100% + 10px);
			height: 60%;
			z-index: -1;
		}
		&::after {
			height: calc(100% + 10px);
			width: 60%;
		}
		&:hover {
			filter: grayscale(0);
		}
	}
}
