.cookies {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0,0,0,.7);
	color: #efefef;
	letter-spacing: 0.025rem;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 5;
	font-size: 1.2rem;
	padding: 1rem;
	color: #fff - #101010;
	background-color: rgba(0,0,0,0.7);
	transform: translateY(100%);
	transition: transform 0.3s ease-in-out;
	&__wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	.container {
		display: flex;
		align-items: center;
	}
	a {
		text-decoration: underline;
	}
	&--active {
		transform: none;
	}
	&__close {
		cursor: pointer;
		display: flex;
		color: #fff;
		align-items: center;
		justify-content: center;
		transition: color 0.1s ease-in-out;
		background-color: transparent;
		font-style: normal;
		font-size: 1.8rem;
			margin-left: 4rem;
		@include btw($lg, $lap) {
			font-size: (1.8rem * 0.6);
		}
		&:hover {
			text-decoration: underline;
		}
		span {
			&:first-child {
				@include max($md) {
					display: none;
				}
			}
			&:last-child {
				@include min($md) {
					display: none;
				}
			}
		}
	}
	&__text {
		display: flex;
		font-size: 1.4rem;
		@include max($sm) {
			flex-direction: column;
			align-items: flex-end;
			font-size: 1.4rem;
		}
		@include min($sm) {
			align-items: center;
		}
	}
}
