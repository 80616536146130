.otherProjects {
  .grid {
    padding-top: 120px;
    padding-bottom: 2rem;
    &::after {
      display: none;
    }
  	@include max($md) {
  		padding: 10rem 0 5rem;
  	}
  	@include max($sm) {
  		padding: 7rem 0 1rem;
  	}
  }
  .container--slider {
    margin-top: 3rem;
    @include max($sm) {
      margin-top: 1rem;
    }
  }
  .swiper-button-next, .swiper-button-prev {
    background-image: none;
    top: calc(50% + 22px);
  }
  .swiper-button-prev {
    left: -50px;
  }
  .swiper-button-next {
    right: -50px;
  }
  .rotate {
    transform: rotate(-180deg);
  }
  .contactUs__heading {
    font-size: 5.5rem;
    @include max($lap) {
      font-size: 4.15rem;
    }
    @include max($lg) {
      font-size: 4rem;
    }
    @include max($sm) {
      font-size: 3rem;
      line-height: 1;
      margin-bottom: 2rem;
    }
  }
  &--extended {
    .grid{
      padding-top: 450px;
      @include max($lap) {
        padding-top: 390px;
      }
      @include max($md) {
				padding: 30rem 0 1rem;
			}
			@include max($sm) {
				padding: 8rem 0 1rem;
			}
    }
  }
  .portfolio.projects {
    height: auto;
  }
  .portfolio.projects, .swiper-container {
    @include max($sm) {
      width: 100%;
    }
  }
  .portfolio.projects {
    @include max($md) {
      padding-top: 1rem;
    }
    @include max($sm) {
      padding-top: 0;
    }
  }
  .portfolio__gallery {
    @include max($sm) {
      padding: 0;
    }
  }
  &--simple {
    .grid {
      padding-top: 6rem;
      @include max($md) {
        padding: 4rem 0 5rem;
      }
      @include max($sm) {
        padding: 2rem 0 1rem;
      }
    }
  }
}
