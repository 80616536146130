.otherPosts{
  position: relative;
  margin-bottom: 4rem;

  @include min($sm) {
    margin-bottom: 8rem;
  }

  a.goTop {
    display: none;
  }

  &__title {
    color: #046078;
    padding: 5rem 0 14rem;
    font-size: 2.6rem;
    font-weight: 900;

    @include min($md) {
      font-size: 3.2vw;
      padding: 7rem 0 16rem;
    }
  }

  .container--small {
    display: flex;
    justify-content: space-between;

    @include max($sm) {
      flex-direction: column;
    }
  }

  .contactUs__bar {
    padding: 4vw 0 8vw;

    @include max($sm) {
      padding-bottom: 12vw;
    }
  }

  .grid {
    margin-bottom: -3rem;
    z-index: -2;

    @include min($xs) {
      margin-bottom: -3rem;
    }

    @include min($sm) {
      margin-bottom: -5rem;
    }

    @include min($lg) {
      margin-bottom: -8rem;
    }

    @include min($lap) {
      margin-bottom: -11rem;
    }

    &::after {
      display: none;
    }
  }

  .postBox--small:first-child {
     margin: 0;
  }

  .postBox--small {

    @include min($sm) {
      width: calc(100%/2 - 20px);
    }
  }
}
