.contactForm {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&__textarea,
	.inputs__short,
	.inputs__long {
		outline: 0;
		font: inherit;
		height: 4rem;
		border-radius: 0;
		line-height: (1796/1548);
		border: 0;
		resize: none;
		border: 0.2rem solid #e4e4e4;
		border-top: 0.2rem solid #e4e4e4;
		box-shadow: none;
		appearance: none;
		padding-left: 2rem;
		@include min($md) {
			font-size: 1.3vw;
			// height: 3vw;
		}
		@include min($lg) {
			font-size: 0.8vw;
			// height: 1.85vw;
			line-height: 1.85vw;
		}
		&.error {
			background-color: #ffd9d9;
		}
	}
	&__textarea {
		padding: 1rem 1rem 1rem 2rem;
		height: 15rem;
		border-radius: 0;
		width: 100%;
		margin: 1rem 0;
		@include min($md) {
			height: 8vw;
		}
		// @include min($lg) {
		// 	height: 1.85vw * 2;
		// }
	}
	&__button {
		font-variant: small-caps;
		padding: 1rem 0 1.3rem;
		width: 100%;
		background-color: #03D3D5;
		color: #fff;
		font-weight: 700;
		font-size: 1.5rem;
		position: relative;
		@include min($sm) {
			width: 33%;
		}
		&:hover {
			cursor: pointer;
		}
		&:disabled {
			.simpleLoader-wrapper {
				opacity: 1;
			}
			.simpleLoader {
				width: 30px;
				height: 30px;
			}
		}
	}
}
