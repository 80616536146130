$expoOut: cubic-bezier(0.13, 0.4, 0.29, 0.97);
.line {
	@include max($sm) {
		transform: translateY(50%);
	}
	&__element {
		width: 2px;
		height: 8rem;
		background-color: #00d3d5;
	}
	&__text {
		@include max($sm) {
			display: none;
		}
		color: #00d3d5;
		position: relative;
		font-style: italic;
		transform: translateX(-50%);
		overflow: hidden;
		font-size: 2.5rem;
		font-weight: 300;
		margin-bottom: 1.5rem;
		@include min($lg) {
			font-size: 3.1rem;
		}
		span {
			@include min($lg) {
				transform: translateY(100%);
			}
			display: block;
			transition: transform 0.35s $expoOut 0s;
		}
	}
}
.line {
	&__element {
		transform-origin: 50% 100%;
		transition: transform 0.35s $expoOut;
		@include min($sm) {
			transform: scaleY(0);
			animation: line 2s infinite cubic-bezier(1, 0, 0, 1);
		}
	}
}
@keyframes line {
	0% {
		transform: scaleY(1);
		transform-origin: center bottom;
	}
	50% {
		transform: scaleY(0);
		transform-origin: center bottom;
	}
	51% {
		transform: scaleY(0);
		transform-origin: center top;
	}
	100% {
		transform: scaleY(1);
		transform-origin: center top;
	}
}
.loaded {
  .section.active {
    .line {
      &__element {
        transform: none;
    		transition: transform 1s $expoOut 1s;
      }
      &__text {
        span {
          transform: none;
    			transition: transform 0.7s $expoOut 1.1s;
        }
      }
    }
  }
}
