.container{
	padding: 0 15px;
	@include min($sm) {
		width: 750px;
		margin: 0 auto;
	}
	@include min($md) {
		width: 970px;
	}
	@include min($lg) {
		width: 1260px;
	}
	// @include min($xl) {
	// 	width: 1400px;
	// }
}
.container--slider {
	padding: 0 15px;
	@include min($sm) {
		width: 92vw;
		max-width: 100%;
		margin: 0 auto;
	}
	@include min($md) {
		width: 89vw;
	}
	@include min($lg) {
		width: 920px;
	}
	@include min($lap) {
		width: 1480px;
	}
	@include max($sm) {
		width: 100%;
	}
}
.container--big{
	padding: 0 15px;
	@include min($sm) {
		width: 99vw;
		max-width: 100%;
		margin: 0 auto;
	}
	@include min($md) {
		width: 99vw;
	}
	@include min($lg) {
		width: 1080px;
	}
	@include min($lap) {
		width: 1480px;
	}
	// @include min($xl) {
	// 	width: 1400px;
	// }
}
.container--huge {
	padding: 0 15px;
	@include min($sm) {
		width: 99vw;
		margin: 0 auto;
	}
	@include min($md) {
		width: 99vw;
	}
	@include min($lg) {
		width: 100%;
		padding: 0 70px;

	}
	@include min($lap) {
		width: 100%;
		padding: 0 140px;

	}
}
.container--case {
	padding: 0 15px;
	@include min($sm) {
		width: 92vw;
		margin: 0 auto;
	}
	@include min($md) {
		width: 89vw;
	}
	@include min($lg) {
		width: 960px;
	}
	@include min($lap) {
		width: 1280px;
	}
}
.container--small {
	padding: 0 15px;
	@include min($sm) {
		width: 92vw;
		margin: 0 auto;
	}
	@include min($md) {
		width: 78vw;
	}
	@include min($lap) {
		width: 1130px;
	}
}
