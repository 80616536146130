.effects {
  height: 470px;
  background-color: #000;
  position: relative;
  @include max($lap) {
    height: 352.5px;
  }
  @include max($md) {
    height: 400px;
  }
  @include max($sm) {
    height: auto;
  }
  &__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .3;
    @include max($sm) {
      background-position: center center;
      background-size: cover;
    }
  }
  .container--case {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    z-index: 2;
  }
  &_robicolosalna {
    .container--case {
      justify-content: flex-start;

    }
  }
  h5 {
    color: #fff;
    font-size: 61px;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 1rem;
    @include max($lap) {
      font-size: 45.5px;
    }
    @include max($sm) {
      margin-top: 2rem;
    }
    &::after {
      content: '';
      display: block;
      margin-top: 1rem;
      width: 0;
      height: 3px;
      background-color: #00D3D5;
    }
    &.anim--active {
      &::after {
        content: '';
        width: 170px;
        transition: width .3s ease-in-out .9s;
      }
    }
  }
  &__statistics {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transform: translateY(50px);
    flex-wrap: wrap;
    @include max($lg) {
      width: 100vw;
    }
    @include max($sm) {
      flex-wrap: wrap;
    }
  }
  &__statistic {
    height: 210px;
    width: calc(100%/4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    @include max($lap) {
      height: 157.5px;
    }
    @include max($md) {
      height: 150px;
      padding: 1rem;
    }
    @include max($sm) {
      width: calc(100%/2);
    }
    &:first-child {
      height: 270px;
      @include max($lap) {
        height: 202.5px;
      }
      @include max($md) {
        height: 200px;
      }
      @include max($sm) {
        height: 150px;
      }
    }
    &:nth-child(4n - 2) {
      transition: opacity .7s ease-in-out .2s, transform .6s ease-in-out .2s;
    }
    &:nth-child(4n - 1) {
      transition: opacity .7s ease-in-out .3s, transform .6s ease-in-out .3s;
    }
    &:nth-child(4n) {
      transition: opacity .7s ease-in-out .4s, transform .6s ease-in-out .4s;
    }
  }
  h6 {
    font-size: 68px;
    font-weight: 900;
    @include max($lap) {
      font-size: 51px;
    }
    @include max($md) {
      font-size: 30px;
    }
  }
  .counterHeading {
    display: flex;
    align-items: center;
    span {
      font-size: 68px;
      font-weight: 900;
      @include max($lap) {
        font-size: 51px;
      }
      @include max($md) {
        font-size: 30px;
      }
    }
  }
  .headingContainer {
    min-height: 90px;
  }
  p {
    font-size: 32px;
    font-weight: 300;
    line-height: (310/320);
    margin-top: 1rem;
    @include max($lap) {
      font-size: 24px;
    }
    @include max($md) {
      font-size: 25px;
    }
  }
  &_robicolosalna {
    .headingContainer {
      margin-top: 10rem;
      @include max($sm) {
        margin-top: 2rem;
      }
    }
    .effects__statistic:nth-child(6) {
      @include max($lap) {
        height: 242px !important;
      }
      @include max($md) {
        height: auto !important;
      }
      @include max($sm) {
        width: 100%;
        order: 7;
      }
    }
  }
  &--movingDay {
    h5 {
      text-transform: none;
      &::after {
        margin: 1rem auto;
        height: 5px;
      }
      @include max($sm) {
        font-size: 3.2rem;
      }
    }
  }
  &--benvenuti {
    .heading--smaller {
      font-size: 5rem;
      @include max($lap) {
        font-size: 3.5rem;
      }
      @include max($lg) {
        font-size: 4rem;
      }
      @include max($md) {
        font-size: 3rem;
      }
      @include max($sm) {
        font-size: 2.7rem;
      }
    }
  }
}
