/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-1 {
  background-image: url(sprite.png);
  background-position: -678px -891px;
  width: 144px;
  height: 30px;
}
.icon-10 {
  background-image: url(sprite.png);
  background-position: -128px -812px;
  width: 128px;
  height: 128px;
}
.icon-10_s {
  background-image: url(sprite.png);
  background-position: 0px -812px;
  width: 128px;
  height: 128px;
}
.icon-11 {
  background-image: url(sprite.png);
  background-position: -515px -812px;
  width: 150px;
  height: 56px;
}
.icon-11_s {
  background-image: url(sprite.png);
  background-position: -665px -812px;
  width: 150px;
  height: 56px;
}
.icon-12 {
  background-image: url(sprite.png);
  background-position: -815px -812px;
  width: 130px;
  height: 34px;
}
.icon-12_s {
  background-image: url(sprite.png);
  background-position: -548px -891px;
  width: 130px;
  height: 34px;
}
.icon-1_s {
  background-image: url(sprite.png);
  background-position: -693px -668px;
  width: 144px;
  height: 30px;
}
.icon-2 {
  background-image: url(sprite.png);
  background-position: -248px -940px;
  width: 116px;
  height: 32px;
}
.icon-2_s {
  background-image: url(sprite.png);
  background-position: -132px -940px;
  width: 116px;
  height: 32px;
}
.icon-3 {
  background-image: url(sprite.png);
  background-position: -1035px -340px;
  width: 68px;
  height: 76px;
}
.icon-3_s {
  background-image: url(sprite.png);
  background-position: -1035px -264px;
  width: 68px;
  height: 76px;
}
.icon-4 {
  background-image: url(sprite.png);
  background-position: -256px -891px;
  width: 146px;
  height: 40px;
}
.icon-4_s {
  background-image: url(sprite.png);
  background-position: -402px -891px;
  width: 146px;
  height: 40px;
}
.icon-5 {
  background-image: url(sprite.png);
  background-position: -947px -682px;
  width: 66px;
  height: 86px;
}
.icon-5_s {
  background-image: url(sprite.png);
  background-position: -947px -596px;
  width: 66px;
  height: 86px;
}
.icon-6 {
  background-image: url(sprite.png);
  background-position: -1035px -60px;
  width: 94px;
  height: 60px;
}
.icon-6_s {
  background-image: url(sprite.png);
  background-position: -1035px 0px;
  width: 94px;
  height: 60px;
}
.icon-7 {
  background-image: url(sprite.png);
  background-position: -574px -700px;
  width: 138px;
  height: 70px;
}
.icon-7_s {
  background-image: url(sprite.png);
  background-position: -436px -700px;
  width: 138px;
  height: 70px;
}
.icon-8 {
  background-image: url(sprite.png);
  background-position: -947px -178px;
  width: 88px;
  height: 88px;
}
.icon-8_s {
  background-image: url(sprite.png);
  background-position: -947px -90px;
  width: 88px;
  height: 88px;
}
.icon-9 {
  background-image: url(sprite.png);
  background-position: -712px -700px;
  width: 142px;
  height: 64px;
}
.icon-9_s {
  background-image: url(sprite.png);
  background-position: -373px -812px;
  width: 142px;
  height: 64px;
}
.icon-arrow-c {
  background-image: url(sprite.png);
  background-position: -1071px -728px;
  width: 39px;
  height: 32px;
}
.icon-arrows--alt {
  background-image: url(sprite.png);
  background-position: -1035px -794px;
  width: 34px;
  height: 24px;
}
.icon-arrows {
  background-image: url(sprite.png);
  background-position: -1107px -120px;
  width: 18px;
  height: 27px;
}
.icon-creationPlus {
  background-image: url(sprite.png);
  background-position: -1035px -645px;
  width: 44px;
  height: 43px;
}
.icon-crop {
  background-image: url(sprite.png);
  background-position: 0px 0px;
  width: 693px;
  height: 700px;
}
.icon-event {
  background-image: url(sprite.png);
  background-position: -947px 0px;
  width: 88px;
  height: 90px;
}
.icon-fastContact {
  background-image: url(sprite.png);
  background-position: -693px 0px;
  width: 254px;
  height: 295px;
}
.icon-fb-c {
  background-image: url(sprite.png);
  background-position: -1066px -763px;
  width: 31px;
  height: 31px;
}
.icon-fb {
  background-image: url(sprite.png);
  background-position: -1079px -645px;
  width: 40px;
  height: 41px;
}
.icon-hand {
  background-image: url(sprite.png);
  background-position: -693px -295px;
  width: 198px;
  height: 373px;
}
.icon-ig {
  background-image: url(sprite.png);
  background-position: -1035px -688px;
  width: 40px;
  height: 40px;
}
.icon-in-c {
  background-image: url(sprite.png);
  background-position: -1035px -763px;
  width: 31px;
  height: 31px;
}
.icon-in {
  background-image: url(sprite.png);
  background-position: -1088px -593px;
  width: 40px;
  height: 41px;
}
.icon-ins-c {
  background-image: url(sprite.png);
  background-position: -1097px -763px;
  width: 31px;
  height: 31px;
}
.icon-learning {
  background-image: url(sprite.png);
  background-position: -1035px -120px;
  width: 72px;
  height: 74px;
}
.icon-logo-light {
  background-image: url(sprite.png);
  background-position: -240px -700px;
  width: 196px;
  height: 90px;
}
.icon-logo-typo {
  background-image: url(sprite.png);
  background-position: 0px -940px;
  width: 132px;
  height: 59px;
}
.icon-logo-white {
  background-image: url(sprite.png);
  background-position: 0px -700px;
  width: 240px;
  height: 112px;
}
.icon-logo {
  background-image: url(sprite.png);
  background-position: -947px -266px;
  width: 70px;
  height: 101px;
}
.icon-managePlus {
  background-image: url(sprite.png);
  background-position: -1035px -728px;
  width: 36px;
  height: 35px;
}
.icon-mockup {
  background-image: url(sprite.png);
  background-position: -1035px -194px;
  width: 74px;
  height: 70px;
}
.icon-motion {
  background-image: url(sprite.png);
  background-position: -1035px -488px;
  width: 73px;
  height: 61px;
}
.icon-patternLines {
  background-image: url(sprite.png);
  background-position: -1035px -549px;
  width: 85px;
  height: 44px;
}
.icon-patterns {
  background-image: url(sprite.png);
  background-position: -436px -770px;
  width: 201px;
  height: 20px;
}
.icon-plans {
  background-image: url(sprite.png);
  background-position: -947px -367px;
  width: 74px;
  height: 84px;
}
.icon-pr {
  background-image: url(sprite.png);
  background-position: -256px -812px;
  width: 117px;
  height: 79px;
}
.icon-social {
  background-image: url(sprite.png);
  background-position: -947px -524px;
  width: 79px;
  height: 72px;
}
.icon-strat {
  background-image: url(sprite.png);
  background-position: -1035px -416px;
  width: 68px;
  height: 72px;
}
.icon-strategyPlus {
  background-image: url(sprite.png);
  background-position: -1035px -593px;
  width: 53px;
  height: 52px;
}
.icon-swiper-arrow {
  background-image: url(sprite.png);
  background-position: -1075px -688px;
  width: 35px;
  height: 39px;
}
.icon-up {
  background-image: url(sprite.png);
  background-position: -1069px -794px;
  width: 30px;
  height: 24px;
}
.icon-video {
  background-image: url(sprite.png);
  background-position: -947px -451px;
  width: 84px;
  height: 73px;
}
