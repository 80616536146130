// Breakpoints
$xxs: 350px;
$xs: 480px;
$sm: 768px;
$md: 991px;
$lg: 1264px;
$lap: 1500px;
$xl: 1870px;

@mixin min($arg) {
  @media (min-width: $arg) {
    @content;
  }
}
@mixin max($arg) {
  @media (max-width: $arg - 1) {
    @content;
  }
}
@mixin btw($argo, $argt) {
  @media (min-width: $argo) and (max-width: ($argt - 1)) {
    @content;
  }
}
@mixin minh($arg) {
  @media (min-height: $arg) {
    @content;
  }
}
@mixin maxh($arg) {
  @media (max-height: $arg - 1) {
    @content;
  }
}
@mixin btwh($argo, $argt) {
  @media (min-height: $argo) and (max-height: ($argt - 1)) {
    @content;
  }
}

@mixin expoInOut($durationOpacity, $opacity, $delayOpacity, $durationTransform, $transform, $delayTransform) {
	opacity: $opacity;
	transform: $transform;
	$easing: cubic-bezier(0.13, 0.4, 0.29, 0.97);
	transition: $durationOpacity opacity $easing $delayOpacity, $durationTransform transform $easing $delayTransform;
}
