.fastContact {

	@include min($md) {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	@include min($lg) {
		width: 96rem;
		margin: 0 auto;
		padding: 0 1.5rem;
		padding-top: 4rem;
		justify-content: space-between;
	}

	@include min($md) {
		height: 40rem;
	}

	@include min($lap) {
		width: 124rem;
	}

	&__wrapper {
		display: flex;
		width: 100%;
		padding: 1.5rem 1.5rem 0;
		justify-content: space-between;

		@include max($xs) {
			flex-direction: column;
		}

		@include btw($xs, $md) {
			flex-direction: row;
		}

		@include min($md) {
			padding: 0;
			width: 40%;
		}

		@include min($lg) {
			width: 50%;
		}
	}

	&__form {
		width: 40%;

		@include max($md) {
			padding: 1.5rem 1.5rem 0;
			width: 100%;
		}

		@include min($lg) {
			width: 35%;
		}
	}

	&__info {

		@include max($xs) {
			width: 100%;
			text-align: center;
		}

		@include btw($xs, $sm) {
			width: 50%;
		}

		@include btw($sm, $md) {
			width: 33%;
		}
	}

	&__image {
		display: flex;
		align-items: center;

		@include max($xs) {
			width: 100%;
			justify-content: center;
			margin: 1.5rem 0;
		}

		@include btw($xs, $sm) {
			width: 50%;
		}

		@include btw($sm, $md) {
			width: 65%;
		}

		img {

			@include max($md) {
				height: 89px;
			}

			@include min($md) {
				max-width: 70%;
			}
		}
	}
	form {
		position: relative;
		z-index: 1;
		.thank {
			pointer-events: none;
			opacity: 0;
			transition: opacity 1s ease-in-out 1s;
			display: flex;
			flex-direction: column;
			text-align: center;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 20;
			background-color: #fff;
			h2 {
				font-size: 4rem;
				margin-bottom: 1rem;
				line-height: 1.03797;
				font-weight: 900;
				color: #6F6F6E;
				opacity: 0;
				transition: opacity 1s ease-in-out;
			}
			p {
				line-height: 1.33333;
				color: #00D3D5;
				font-size: 2.4rem;
				opacity: 0;
				transition: opacity 1s ease-in-out;
			}
		}
		&.active {
			.thank {
				pointer-events: all;
				opacity: 1;
				transition: opacity 1s ease-in-out;
				h2,
				p {
					opacity: 1;
					transition: opacity 1s ease-in-out 1s;
				}
			}
		}
	}
}
