.socialMedia {
  .grid {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include max($md) {
      flex-direction: column;
    }
    &::after {
      content: '';
      display: none;
    }
  }
  .column {
    width: 50%;
    @include max($md) {
      width: 100%;
    }
    &--images {
      width: calc(50% - 50px);
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      // align-items: center;
      height: 100%;
    }
    &:nth-child(2) {
      @include max($xl) {
        width: calc(50% - 50px);
        margin-right: 2rem;
      }
      @include max($lap) {
        width: calc(50% - 50px);
        margin-right: 2rem;
      }
      @include max($md) {
        width: 100%;
        margin-right: 0;
        height: auto;
      }
    }
  }
  .image {
    &:nth-child(2n) {
      .anim--image {
        transition: opacity .7s ease-in-out .3s, transform .6s ease-in-out .3s;
        @include max($md) {
          transition: opacity .7s ease-in-out, transform .6s ease-in-out;
        }
      }
    }
    &:nth-child(2) {
      .anim--image {
        @include max($md) {
          transition: opacity .7s ease-in-out .1s, transform .6s ease-in-out.1s;
        }
      }
    }
    &:nth-child(3) {
      .anim--image {
        @include max($md) {
          transition: opacity .7s ease-in-out .2s, transform .6s ease-in-out .2s;
        }
      }
    }
    &:nth-child(4) {
      .anim--image {
        @include max($md) {
          transition: opacity .7s ease-in-out .3s, transform .6s ease-in-out .3s;
        }
      }
    }
    @include max($xl) {
      width: calc(50% - 10px);
    }
    @include max($md) {
      width: calc(100%/4);
    }
    @include max($sm) {
      width: calc(100%/2);
    }
    &:nth-child(3), &:nth-child(4) {
      margin-top: 1rem;
      @include max($lap) {
        margin-top: .7rem;
      }
      @include max($md) {
        margin-top: 0;
      }
    }
    img {
      @include max($xl) {
        width: 100%;
        height: auto;
      }
    }
  }
  &__content {
    background-color: #E5E5E5;
    margin-top: 75px;
    overflow: hidden;
    padding: 120px 90px 120px 160px;
    @include max($lap) {
      margin-top: 35px;
      padding: 90px 30px 90px 70px;

    }
    @include max($sm) {
      padding: 2rem;
      margin-top: 20px;
    }
  }
  p {
    color: #046078;
    font-size: 2.7rem;
    font-weight: 300;
    line-height: (400/270);
    @include max($lap) {
      font-size: 2rem;
    }
    @include max($lg) {
      font-size: 2rem;
    }
    @include max($sm) {
      font-size: 1.6rem;
      line-height: (400/270);
    }
  }
  &__row {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    @include max($md) {
      justify-content: flex-start;
    }
  }
  h5 {
    font-size: 74px;
    font-weight: 900;
    text-align: left;
    padding: 0 5rem;
    // margin-left: 285px;
    margin-top: 120px;
    // @include max($xl) {
    //   margin-left: 0;
    //   text-align: left;
    // }
    @include max($lap) {
      font-size: 55.5px;
      margin-top: 20px;
      margin-left: 51px;
    }
    @include max($lg) {
      font-size: 40px;
    }
    @include max($sm) {
      padding: 0 1rem;
      margin-left: 0;
      font-size: 35px;
    }
    &::after {
      content: '';
      display: block;
      margin-top: 1rem;
      width: 0;
      height: 5px;
      background-color: #00D3D5;
    }
    &.anim--active {
      &::after {
        content: '';
        width: 125px;
        transition: width .3s ease-in-out .9s;
      }
    }
  }
}
