.grid {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: #f6f6f6;
	background-image: url('img/square.png');
	background-attachment: fixed;
	position: relative;
	z-index: 1;
	&::after {
		content: '';
		display: block;
		height: 4rem;
		@include min($md) {
			height: 9rem;
		}
		@include max($lg) {
			bottom: -10px;
		}
		width: 0.2rem;
		background-color: #03D3D5;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateY(50%);
		z-index: 1;
	}
}
