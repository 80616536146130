.simpleLoader-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		margin: auto;
		top: 0;
		left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00D3D5;
    opacity: 0;
}

.simpleLoader {
		position: relative;
		border: 5px solid #c4fafb;
		border-bottom-color: #023947;
		border-radius: 50%;
    width: 40px;
		height: 40px;
		animation: loading 1s infinite linear;
}

@keyframes loading {
		0% {
				transform: rotate(0deg);
		}
		100% {
				transform: rotate(360deg);
		}
}
