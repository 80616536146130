.error404 {

  .badimg {
    position: absolute;
    left: 6rem;
    top: 7rem;
    width: 42px;
    height: 60px;

    @include max($lg) {
      display: none;
    }
  }


  .header__toggle {

    span:nth-child(1),
    span:nth-child(2) {
      background-color: #000;
    }
  }

  .badgat {
  	height: 100vh;
  	display: flex;
    flex-direction: column;
  	align-items: center;
  	justify-content: center;
  	flex-wrap: nowrap;
    .for404container {
      position: relative;
      display: flex;
    	align-items: center;
    	justify-content: center;
    	flex-wrap: nowrap;
      margin-left: 5vw;
      @include max($md) {
        flex-direction: column;

      }
      &::before {
        content: '4';
        position: absolute;
        top: calc(50% - 16.5rem);
        left: -3.7rem;
        color: #00D3D5;
        font-size: 29.5rem;
        z-index: -2;
        font-family: "Lato", sans-serif;
        font-weight: 800;
        @include max($lg) {
          font-size: 20rem;
          top: calc(50% - 10rem);
          left: 0;
        }
        @include max($md) {
          font-size: 25rem;
          top: calc(50% - 5rem);

        }
        @include max($sm) {
          font-size: 15rem;
          left: -1rem;
          top: calc(50% - 4rem);
        }
      }
      &::after {
        content: '4';
        position: absolute;
        top: calc(50% - 16.5rem);
        right: 2.6rem;
        color: #00D3D5;
        font-size: 29rem;
        font-weight: 800;
        z-index: -2;
        @include max($lg) {
          font-size: 20rem;
          top: calc(50% - 10rem);
          right: 7.5rem;
        }
        @include max($md) {
          right: 1rem;
          font-size: 25rem;
          top: calc(50% - 5rem);

        }
        @include max($sm) {
          right: 0;
          font-size: 15rem;
          top: calc(50% - 4rem);
        }
      }
    }
    .heading {
      font-size: 3.31rem;
      line-height: (3981/3317);
      font-weight: 800;
      margin-top: 2rem;
      text-align: center;
      @include max($md) {
        max-width: 70%;
      }
      @include max($sm) {
        font-size: 2rem;
      }
    }
    .button {
      font-variant: normal;
      text-transform: uppercase;
      font-size: 1.96rem;
      line-height: (2351/1960);
      padding: 1.5rem 2.5rem;
      margin-top: 2rem;
    }

  	img {
  		position: relative;
  		z-index: 1;
      @include max($lap) {
        height: 70vh;
      }
  		@include max($lg) {
        height: 50vh;
        width: auto;
  			max-width: 100%;
  		}
  		@include max($md) {
        height: auto;
        width: auto;
  			max-width: 70%;
  		}
  	}

  	span {
  		position: relative;
  		z-index: 2;
  		font-size: (11.4rem * 0.6);
      font-weight: 900;
  		@include min($lg) {
  			font-size: 11.4rem;
  			margin-bottom: 5rem;
  		}
      @include max($sm) {
        font-size: 3rem;
      }

  		&:first-child {

  			@include max($lg) {
  				order: -1;
  			}

        @include min($lg) {
          margin-right: -13vh;
        }
  			@include min($lap) {
  				margin-right: -11rem;
  			}
        @include max($sm) {
          margin-top: 4rem;
        }
  		}

  		&:last-child {

  			// @include max($lg) {
  			// 	margin-bottom: 2rem;
  			// }
        //
  			// &::after {
        //
  			// 	@include max($lg) {
  			// 		content: '';
  			// 		width: 6rem;
  			// 		height: 0.5rem;
  			// 		display: block;
  			// 		position: absolute;
  			// 		bottom: -1.5rem;
  			// 		background-color: #00d3d5;
  			// 		left: calc(50% - 3rem);
  			// 	}
  			// }

  			@include max($md) {
  				order: -1;
  			}

        @include min($lg) {
          margin-left: -19vh;
        }
  			@include min($lap) {
  				margin-left: -18rem;
  			}
  		}
  	}
  }
}
