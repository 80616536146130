.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 5;
	@include max($lg) {
		position: fixed;
		top: 0;
		left: 0;
		background-color: #fff;
		height: 7rem;
		box-shadow: 0 0px 6px 0px rgba(0,0,0,0.2);
	}
	&__contact {
		width: 4.3rem;
		height: 2.6rem;
		display: block;
		position: fixed;
		top: 2.15rem;
		right: 11rem;
		z-index: 3;
		@include max($sm) {
			display: none;
		}
		@include min($lg) {
			top: 8.1rem;
			right: 13rem;
		}
		@include min($lap) {
			top: 8.65rem;
			right: 15rem;
		}
		svg {
			width: 100%;
			height: auto;
		}
	}
	&__toggle {
		cursor: pointer;
		position: fixed;
		z-index: 6;
		top: 3rem;
		right: 3rem;
		width: (6rem * 0.75);
		height: (3.6rem * 0.75);
		@include min($lap) {
			width: 6rem;
			height: 3.9rem;
		}
		@include min($lg) {
			top: 8rem;
			right: 6rem;
		}
		@include max($lg) {
			top: 2rem;
		}
		&.active {
			span {
				&:nth-child(1) {
					z-index: 2;
					top: 1.6rem;
					transform: rotate(-45deg);
				}
				&:nth-child(2) {
					z-index: 2;
					top: 1.6rem;
					transform: rotate(45deg);
				}
				&:nth-child(3) {
					transform: scaleX(0);
				}
			}
		}
		span {
			background-color: #fff;
			@include max($lg) {
				background-color: #000 !important;
			}
			height: (0.7rem * 0.75);
			transition: 0.3s ease-in-out;
			transition-property: top, transform;
			@include min($lap) {
				height: 0.7rem;
			}
			width: 100%;
			display: block;
			position: absolute;
			left: 0;
			z-index: 1;
			&:nth-child(1) {
				top: 0;
			}
			&:nth-child(2) {
				top: (1.6rem * 0.75);
				@include min($lap) {
					top: 1.6rem;
				}
			}
			&:nth-child(3) {
				background-color: #00d3d5;
				@include max($lg) {
					background-color: #00d3d5 !important;
				}
				top: (3.2rem * 0.75);
				@include min($lap) {
					top: 3.2rem;
				}
			}
		}
		&--alt {
			span {
				background-color: #000;
			}
		}
	}
	&__nav {
		position: absolute;
		top: 0;
		right: 2rem;
		@include max($lg) {
			display: none;
		}
		@include min($lap) {
			right: 5rem;
		}
		height: 85vh;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: right;
		.header__menuLink {
			display: inline-flex;
			align-items: center;
			margin-bottom: 2rem;
			font-size: 1.6rem;
			font-weight: 700;
			color: #025f77;
			overflow: hidden;
			span {
				transform: translateX(-100%);
				transition: transform 0.3s ease-in-out;
			}
			&::after {
				content: '';
				display: block;
				height: 1.3rem;
				width: 1.3rem;
				border: 0.2rem solid #00D3D5;
				@include min($lap) {
					border: 0.2rem solid #00D3D5;
					height: 1.9rem;
					width: 1.9rem;
				}
				border-radius: 50%;
				margin-left: 1rem;
			}
			&.active {
				&::after {
					background-color: #00D3D5;
				}
			}
			&:hover {
				span {
					transform: none;
				}
				&::after {
					background-color: #00D3D5 + #333;
				}
			}
		}
	}
	&__overlay {
		position: fixed;
		z-index: 4;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		background-color: #fff;
		background-color: rgba(255,255,255,0.55);
		opacity: 0;
		transition: opacity 0.3s ease-in-out 0s;
	}
	&__mainNav {
		position: fixed;
		width: 100%;
		padding-bottom: 230px;
		height: 100vh;
		top: 0;
		right: 0;
		z-index: 5;
		transform: translateX(100%);
		transition: transform 0.6s ease-in-out 0.3s;
		color: #fff;
		background-color: #00D3D5;
		font-size: 2.9rem;
		font-weight: 700;
		line-height: (86/49);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding-bottom: 100px;
		@include min($sm) {
			font-size: 4rem;
			padding-bottom: 150px;
		}
		@include min($lg) {
			font-size: 4.9rem;
			padding-bottom: 0;
		}
		@include min($md) {
			width: 50%;
		}
		@include btw($lg, $lap) {
			font-size: 3.9rem;
		}
		@include max($lap) {
			justify-content: flex-start;
			padding-top: 10vh;
		}
		&.active {
			transition: transform 0.6s ease-in-out;
			transform: none;
			.header__menuLink {
				transform: none;
			}
			li {
				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						a {
							transition: transform 0.3s ease-in-out 0.4s + 0.07s * $i;
						}
					}
				}
			}
			+ .header__overlay {
				opacity: 1;
				transition: opacity 0.3s ease-in-out 0.4s;
			}
		}
		li {
			overflow: hidden;
			z-index: 1;
		}
		.header__menuLink {
			position: relative;
			display: block;
			transform: translateX(100%);
			transition: transform 0.3s ease-in-out;
			z-index: 1;
			&::after {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: 0.4rem;
				background-color: #fff;
				width: 10rem;
				height: 0.6rem;
				transform-origin: right top;
				transform: scaleX(0);
				transition: transform 0.3s ease-in-out;
			}
			&[href]:hover {
				&::after {
					transform: scaleX(1);
					transform-origin: left top;
				}
			}
		}
	}
	&__infoSection {
		display: flex;
		position: absolute;
		bottom: 5rem;
		left: 50%;
		transform: translateX(-50%);
		width: 55%;
		justify-content: space-between;
		@include max($lg) {
			flex-direction: column;
			bottom: 2rem;
			height: 10rem;
		}
		@include max($xs) {
			bottom: 0rem;
			height: auto;
			width: 70%;
		}
		@include btw($lg, $lap) {
			width: 70%;
		}
	}
	&__icon {
		@include max($lap) {
			transform: scale(.7) translateY(-5px);
			vertical-align: middle;
		}
	}
	&__copyright,
	&__icons {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
		@include min($lg) {
			width: 50%;
		}
		img {
			@include max($sm) {
				height: 4rem;
			}
		}
	}
	&__copyright {
		@include max($sm) {
			margin-bottom: 1rem;
		}
	}
	&__copyrightText {
		font-size: 2rem;
		color: #046078;
	}
	&__logo {
		position: fixed;
		@include min($lg) {
			position: absolute;
		}
		top: 1rem;
		left: 2rem;
		transform-origin: left top;
		transform: scale(0.6);
		@include min($lg) {
			left: 7rem;
			top: 6rem;
		}
		@include max($lg) {
			transform: scale(0.5);
		}
	}
	&__list {
		margin-right: 57px;
		margin-top: 15vh;

		@include btw($lg, $lap) {
			margin-top: 20vh;
		}

		@include min($lap) {
			margin-right: 33px;
		}
	}
	&__menu {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 250%) rotate(90deg);
		display: block;
		font-size: 0;
		@include min($lg) {
			font-size: (2.6rem * 0.75);
		}
		@include min($lap) {
			font-size: 2.6rem;
		}
		line-height: (312/260);
		font-weight: 900;
		color: #025F77;
		> div {
			transform: translateX(-100%);
		}
	}
}
.fp-enabled {
	.header__menu.animUp > div {
			transform: none;
	}
}
