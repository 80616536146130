.caseDescription {
  padding: 220px 0 200px;
  &--center {
    padding: 220px 0 100px;
    .container--case {
      align-items: center;
    }
  }
  @include max($lap) {
    padding-bottom: 120px;
  }
  @include max($md) {
    padding: 7rem 0;
  }
  @include max($sm) {
    padding: 5rem 0;
  }
  .container--case, .container--big {
    display: flex;
    justify-content: space-between;
    @include max($md) {
      flex-direction: column;
    }
  }
  .container--big {
    flex-wrap: wrap;
  }
  &__content {
    width: calc(100%/2 - 50px);
    overflow: hidden;
    @include max($lg) {
      width: 50%;
    }
    @include max($md) {
      width: 100%;
      margin-top: 2rem;
    }
  }
  .headingContainer {
    width: calc(100%/2 - 10px);
    @include max($md) {
      width: 100%;
    }
  }
  h5 {
    font-size: 8.5rem;
    font-weight: 900;
    color: #35302C;
    position: relative;
    line-height: (800/850);
    @include max($lap) {
      font-size: 6.35rem;
    }
    @include max($lg) {
      font-size: 6rem;
    }
    @include max($md) {
      font-size: 5rem;
    }
    @include max($sm) {
      font-size: 4rem;
      line-height: 1;
    }
    &.heading--smaller {
      font-size: 6.1rem;
      line-height: (550/610);
      @include max($lap) {
        font-size: 4.55rem;
      }
      @include max($sm) {
        font-size: 3.5rem;
        line-height: (550/610);
      }
    }
    &.heading--sm {
      line-height: 1;
      @include max($sm) {
        font-size: 3rem;
      }
      &::after {
        content: '';
        margin-top: 5rem;
        @include max($sm) {
          margin-top: 1.5rem;
        }
      }
    }
    &::after {
      content: '';
      display: block;
      margin-top: 1.5rem;
      width: 0;
      height: 5px;
      background-color: #00D3D5;
      @include max($md) {
        margin-top: .5rem;
      }
    }
    &.anim--active {
      &::after {
        content: '';
        width: 100px;
        transition: width .3s ease-in-out .9s;
      }
    }
    span {
      display: block;
      font-size: 7.2rem;
      @include max($lap) {
        font-size: 5.4rem;
      }
      @include max($lg) {
        font-size: 5.2rem;
      }
      @include max($md) {
        display: inline-block;
        font-size: 4.5rem;
        margin-left: 1rem;
      }
      @include max($sm) {
        font-size: 3.5rem;
        margin-left: 0;
      }
    }
  }
  p {
    font-size: 2.3rem;
    line-height: (350/230);
    font-weight: 300;
    @include max($lap) {
      font-size: 1.7rem;
    }
    @include max($sm) {
      font-size: 1.6rem;
    }
  }
  .text--smaller {
    color: #046078;
    font-size: 2rem;
    line-height: (300/200);
    @include max($lap) {
      font-size: 1.5rem;
    }
    @include max($sm) {
      font-size: 1.6rem;
      line-height: (300/200);
    }
  }
  .pc {
    margin-top: -250px;
    @include max($lap) {
      max-width: 100%;
    }
    @include max($sm) {
        margin-top: -120px;
        max-width: 100%;
    }
  }
  .chart {
    @include max($lap) {
      max-width: 456px;
    }
    @include max($lg) {
      max-width: 500px;
    }
    @include max($sm) {
      margin-top: 4rem;
      max-width: 100%;
    }
  }
  &.less-padding {
    padding: 120px 0 0;
  }
  &--sm {
    padding: 220px 0 0;
    @include max($lg) {
      padding: 100px 0 50px;
    }
    @include max($sm) {
      padding: 3rem 1rem 4rem;
    }

    .caseDescription__content {
      width: 50%;
      @include max($md) {
        width: 100%;
      }
    }
    .container--huge {
      display: flex;
      justify-content: space-between;
      margin-top: 8rem;
      @include max($md) {
        flex-wrap: wrap;
        margin-top: 1rem;
      }
    }
  }
  &__box {
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    width: calc(50% - 15px);
    height: 100%;
    position: relative;
    &:nth-child(2n) {
      transition-delay: .2s;
    }
    @include max($lap) {
      img {
        max-width: 100%;
      }
    }
    @include max($md) {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

// ********* VERTICAL *********

.caseDescription--vertical {
  padding: 120px 0 100px;
  overflow: visible;
  position: relative;
  @include max($md) {
    padding: 50px 0 130px;
  }
  @include max($sm) {
    padding: 3rem 1rem 4rem;
  }
  .container--case{
    display: flex;
    overflow: visible;
    flex-direction: column;
    align-items: center;
  }
  .caseDescription__content {
    width: 100%;
    @include max($lap) {
      max-width: 817.5px;
      margin: 0 auto;
    }
    @include max($lg) {
      width: 100%;
    }
  }
  .stripes {
    position: absolute;
    top: 4rem;
    left: 4rem;
    @include max($lap) {
      display: none;
    }
  }
  .icon-strategyPlus {
    position: absolute;
    right: 253px;
    bottom: 145px;
    @include max($sm) {
      display: none;
    }
  }
  .headingContainer {
    width: 100%;
    @include max($md) {
      width: 100%;
    }
  }
  h5 {
    font-size: 6.1rem;
    line-height: 1.1;
    @include max($lap) {
      font-size: 4.55rem;
    }
    @include max($md) {
      font-size: 5.5rem;
    }
    @include max($sm) {
      font-size: 3rem;
    }
  }
  p {
    font-size: 2rem;
    color: #046078;
    margin-top: 40px;
    line-height: (300/200);
    max-width: 1200px;
    @include max($lap) {
      margin-top: 30px;
      font-size: 1.5rem;
    }
    @include max($md) {
      max-width: 100%;
      margin-top: 20px;
    }
    @include max($sm) {
      margin-top: 10px;
      font-size: 1.6rem;
      line-height: (300/200);
    }
  }
  .borderedCircle {
    position: absolute;
    bottom: -9px;
    right: 216px;
    width: 250px;
    height: 250px;
    border: 7rem solid #00D3D5;
    @include max($md) {
      right: 0;
    }
    &.robicolosalna {
      left: -89px;
      width: 300px;
      height: 300px;
      border: 8rem solid #00D3D5;
      bottom: -111px;
      @include max($sm) {
        display: none;
      }
    }
  }
  .patternCircle{
    width: 180px;
    height: 180px;
    transform: rotate(-40deg);
    position: absolute;
    bottom: 42px;
    right: 343px;
    @include max($md) {
      right: 150px;
    }

    &.robicolosalna {
      left: -137px;
      bottom: -170px;
      @include max($sm) {
        display: none;
      }
    }
    &#pkol1 {
      width: 250px;
      height: 250px;
      right: -125px;
      top: -200px;
      @include max($sm) {
        width: 100px;
        height: 100px;
        right: 0;
        top: -100px;
        background-image: url('../assets/img/patternCircleMore.png');
      }
    }
  }
  .grid {
    padding-bottom: 250px;
    @include max($sm) {
      padding-bottom: 50px;
    }
    &::after {
      content: '';
      display: none;
    }
    .borderedCircle {
      position: absolute;
      top: 62%;
      left: 137px;
      width: 300px;
      height: 300px;
      border: 9rem solid #00D3D5;
      @include max($md) {
        top: 0;
        left: 0;
      }
      @include max($sm) {
        top: 0;
        width: 200px;
        height: 200px;
        border: 2rem solid #00D3D5;
        left: 0;
      }
    }
    .patternCircle{
      width: 180px;
      height: 180px;
      transform: rotate(-40deg);
      position: absolute;

      &:nth-of-type(5) {
        top: calc(62% - 120px);
        left: 200px;
        @include max($md) {
          top: -20px;
          right: 0;
          left: auto;
        }
        @include max($sm) {
          top: -20px;
          right: 0;
        }
      }
      &:nth-of-type(6) {
        top: 22%;
        right: 200px;
        @include max($md) {
          top: 50%;
          left: 0;
          right: auto;
        }
        @include max($sm) {
          z-index: -1;
          right: -120px;
          left: auto;
        }
      }
    }
    &.less-padding {
      padding-bottom: 10px;
    }
  }
  .caseDescription__image {
    &:not(.caseDescription__image--alt) {
      margin: -100px 0 100px;
      @include max($lg) {
        margin: -100px 0 50px;
      }
      @include max($sm) {
        margin: -50px 0 30px;
      }
    }
    position: relative;
    z-index: 2;
    img {
      box-shadow: 0 0 25px rgba(0,0,0, .3);
      max-width: 100%;
    }
    &::before {
      content: '';
      display: block;
      background-color: #00D3D5;
      width: 5px;
      height: 100px;
      position: absolute;
      top: -60px;
      left: calc(50% - 2.5px);
      @include max($sm) {
        height: 70px;
        top: -40px;
      }
    }
    &--alt {
      &:nth-child(1) {
        margin-top: -350px;
        @include max($sm) {
          margin-top: 0;
        }
      }
      &:nth-child(2) {
        margin: 50px 0 100px;
        @include max($sm) {
          margin: 20px 0 50px;
        }
      }
      &::before {
        content: '';
        display: none;
      }
      img {
        box-shadow: none;
      }
    }
  }
}
.noPadding {
  padding-bottom: 0;
  @include max($sm) {
    padding: 0;
  }
}

// ********* VIDEO *********
.caseDescription--video {
  padding: 50px 0;
  text-align: center;
  @include max($sm) {
    padding: 25px 0;
  }
  .patternCircle {
    width: 250px;
    height: 250px;
    top: 45px;
    right: -116px;
    @include max($lap) {
      z-index: -1;
    }
    @include max($sm) {
      display: none;
    }
  }
  .icon-strategyPlus {
    position: absolute;
    top: -15px;
    right: 166px;
    z-index: 2;
    @include max($sm) {
      display: none;
    }
  }
  h5 {
    display: inline-block;
    position: relative;
    &::after {
      content: '';
      margin: 1rem auto;
    }
  }
  .caseDescription__video {
    margin-top: 3rem;
    background-color: #E9E9E9;
    padding: 5rem 7rem;
    position: relative;
    @include max($sm) {
      padding: .5rem;
    }
  }
  .caseDescription__content {
    max-width: 800px;
    @include max($lap) {
      max-width: 600px;
    }
    @include max($sm) {
      margin-top: 1rem;
    }
  }
  video {
    max-width: 100%;
    @include max($lap) {
      height: auto;
    }
  }
  .playIcon {
    position: absolute;
    top: calc(50% - 54px);
    left: calc(50% - 54px);
    opacity: .7;
    transition: opacity .2s ease-in-out;
    &:hover {
      opacity: 1;
      cursor: pointer;
      transition: opacity .2s ease-in-out;
    }
    @include max($sm) {
      max-width: 70px;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      img {
        width: 100%;
      }
    }
  }
  p {
    margin-top: 1rem;
  }
}

// ********* COLOR *********
.caseDescription--color {
  padding: 70px 0 250px;
  background-color: #046078;
  @include max($lap) {
    padding: 70px 0 210px;
  }
  @include max($lg) {
    padding: 50px 0 190px;
  }
  @include max($md) {
    padding: 50px 0 260px;
  }
  @include max($sm) {
    padding: 30px 0 100px;
  }
  h5 {
    color: #fff;
    text-align: center;
    @include max($sm) {
      font-size: 2.7rem;
    }
    &:after {
      content: '';
      margin: 2rem auto;
      background-color: #fff;
      @include max($sm) {
        margin: 1rem auto;
      }
    }
  }
  p {
    color: #fff;
    font-weight: 400;
    text-align: center;
    font-size: 2.2rem;
    @include max($lap) {
      font-size: 1.65rem;
    }
    @include max($lg) {
      margin-top: 0;
    }
    @include max($sm) {
      font-size: 1.6rem;
      margin-top: 1rem;
    }
  }
  .caseDescription__content {
    max-width: 1090px;
    @include max($sm) {
      margin-top: 0;
    }
  }
}

// ********* CENTERED *********

.caseDescription--centered {
  &.benvenuti {
    padding-bottom: 20px;
    @include max($sm) {
      padding-bottom:  0;
    }
    p {
      margin-top: 1rem;
    }
    h5::after {
      margin-top: 2.5rem;
    }
    img {
      @include max($sm) {
        margin-top: 3.2rem;
      }
    }
  }
  @include max($sm) {
    padding: 20px 0;
  }
  h5 {
    text-align: center;
    &::after {
      content: '';
      margin: 1.5rem auto;
    }
  }
  p {
    text-align: center;
    margin: 4rem auto 0;
    @include max($sm) {
      margin: .5rem auto 0;
    }
  }
  img {
    margin-top: 5rem;
    max-width: 100%;
  }
  .stripes--static {
    position: static;
    @include max($sm) {
      display: block;
    }
  }
}
// ********* EXTENDED *********

.caseDescription--extended {
  padding: 150px 0 50px;
  @include max($md) {
    padding: 50px 0 0;
  }
  h5 {
    font-size: 7rem;
    font-weight: 900;
    @include max($lap) {
      font-size: 5rem;
    }
    @include max($sm) {
      font-size: 3.5rem;
    }
    &::after {
      content: '';
      margin-top: 4rem;
      @include max($sm) {
        margin-top: 2rem;
      }
    }
  }
  .caseDescription__content {
    @include max($sm) {
      margin-top: 0;
    }
  }
  p {
    font-size: 2.7rem;
    line-height: (400/270);
    @include max($lap) {
      font-size: 2rem;
    }
    @include max($sm) {
      font-size: 1.6rem;
      line-height: (400/270);
    }
  }
}


// ********* COLUMN *********

.caseDescription--column {
  display: flex;
  flex-direction: column;
  .candy {
    width: auto;
    @include max($lap) {
      max-width: 90%;
      img {
        max-width: 100%;
      }
    }
    @include max($lg) {
      max-width: 100%;
      img {
        max-width: 100%;
      }

    }
    &:nth-child(even) {
      align-self: flex-end;
      margin-top: -120px;
      @include max($lap) {
        margin-top: -100px;

      }
      @include max($lg) {
        margin-top: 0;
      }

    }
    &:nth-child(odd) {
      margin-top: -50px;
      @include max($lg) {
        margin-top: 0;
      }
    }
    &:last-child {
      margin-bottom: -100px;
      @include max($lg) {
        margin-bottom: -50px;
      }
    }
  }
}

// ********* PHOTO BACKGROUND *********

.caseDescription--photoBackground {
  position: relative;
  padding: 260px 0;
  background-size: cover;
  // background-attachment: fixed;
  @include max($lap) {
    padding: 175px 0 129px;
  }
  @include max($lg) {
    padding: 14rem 0;
  }
  @include max($md) {
    padding: 3rem 0 5rem;
  }
  &::after {
    content: '';
    display: block;
    height: 10rem;
    width: 0.5rem;
    background-color: #03D3D5;
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    z-index: 2;
    @include max($sm) {
      height: 5rem;
      z-index: 5;
    }
  }
  .simpleDescription__content {
    width: auto;
    @include max($sm) {
      width: 100%;
      padding: 0;
    }
  }
  h5 {
    color: #fff;
    font-size: 7.5rem;
    @include max($lap) {
      font-size: 5.6rem;
    }
    @include max($sm) {
      font-size: 3rem;
    }
    &::after {
      margin-top: 5rem;
      @include max($sm) {
        margin-top: 1rem;
      }
    }
  }
  p {
    color: #fff;
    font-size: 3.2rem;
    font-weight: 400;
    max-width: 920px;
    @include max($lap) {
      font-size: 2.4rem;
      max-width: 690px;
    }
    @include max($sm) {
      font-size: 1.6rem;
      line-height: (350/230);
    }
  }
}
// ********* MOVING DAY *********

.caseDescription--movingDay {
  padding: 0;
  .grid {
    padding: 300px 0 50px;
    @include max($lap) {
      padding: 2rem 0;
    }
    @include max($sm) {
      padding: 20px 0;
    }
    .borderedCircle {
      top: 125px;
      right: 5px;
      left: auto;
      bottom: auto;
      width: 160px;
      height: 160px;
      border: 5rem solid #00D3D5;
      @include max($lg) {
        top: -100px;
        z-index: -1;
      }
      @include max($md) {
        display: none;
      }
    }
  }
  h5 {
    @include max($lg) {
      font-size: 4rem;
    }
    @include max($sm) {
      font-size: 3.2rem;
    }
    &::after {
      @include max($sm) {
        margin-top: 2rem;
      }
    }
  }
  .container--big {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include max($lg) {
      align-items: flex-start;
    }
    @include max($md) {
      flex-direction: column-reverse;
    }
  }
  .column {
    &:nth-child(1) {
      width: calc(50% - 100px);
      text-align: right;
      @include max($md) {
        width: 100%;
      }
      img {
        @include max($md) {
          margin-top: -330px;
        }
        @include max($sm) {
          margin-top: 0;
        }
      }
    }
    &:nth-child(2) {
      padding-top: 8.5rem;
      width: calc(50% + 50px);
      @include max($lg) {
        padding-top: 3rem;
      }
      @include max($md) {
        width: 100%;
        padding-top: 20px;
      }
      img {
        margin-top: 5rem;
      }
    }
  }
  p {
    max-width: 700px;
    @include max($sm) {
      margin-top: 2rem;
    }
  }
  .stripes {
    bottom: 250px;
    left: -50px;
    top: auto;
    @include max($lg) {
      bottom: 30px;
    }
  }
  img {
    @include max($lap) {
      max-width: 100%;
    }
    @include max($md) {
      max-width: 50%;
    }
    @include max($sm) {
      max-width: 100%;
    }
  }
}
// ********* BENVENUTI *********
.caseDescription--benvenuti {
  p {
    max-width: 1200px;
  }
}
.caseDescription--benvenuti1 {
  padding: 0;
  margin-top: -300px;
  z-index: 1;
  @include max($sm) {
    margin-top: 0;
  }
  .grid {
    padding: 300px 0 0;
    @include max($sm) {
      padding: 20px 0;
    }
  }
  .row {
    background-color: #E9E9E9;
    padding: 10rem 0 20rem;
    height: auto;
    @include max($sm) {
      width: 100vw;
      padding: 30px;
    }
  }
  .text--smaller {
    margin-top: 1rem;
    @include max($sm) {
      font-size: 1.6rem;
      margin-top: 0;
    }
  }
  h5.heading--smaller {
    @include max($sm) {
      font-size: 3rem;
      &::after {
        margin-top: 1.5rem;
      }
    }
  }
}
.caseDescription--sm1 {
  .caseDescription__content {
    max-width: none;
    margin: 0;
    width: 100%;
  }
}

.mieszko {
  padding-top: 3rem;
}
