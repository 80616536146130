.about {
	display: flex;
	align-items: center;
	justify-content: center;
	&--nap {
		@include min($lap) {
			transform: translateY(40px);
		}
	}
	&--strat {
		.about {
			@include min($lap) {
				height: auto;
			}
		}
	}
	height: 100%;
	@include max($sm) {
		flex-direction: column;
	}
	&__heading {
		font-size: 6rem;
		padding-bottom: 1rem;
		@include min($lg) {
			font-size: (11.8rem * 0.75);
			padding-bottom: 1.5rem;
		}
		@include min($lap) {
			font-size: 11.8rem;
		}
		display: inline-block;
		line-height: (1416/1180);
		font-weight: 900;
		position: relative;
		z-index: 1;
		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			display: block;
			height: 0.6rem;
			width: 5rem;
			@include min($sm) {
				width: 9.2rem;
			}
			background-color: #00D3D5;
		}
	}
	&__content {
		position: relative;
		z-index: 2;
		width: 100%;
		text-align: left;
		@include min($sm) {
			width: 50%;
		}
		@include min($lg) {
			width: 40%;
		}
		@include max($sm) {
			padding: 1.5rem 2.5rem 0;
		}
		&--strategy,
		&--manage {
			@include min($lg) {
				padding-right: 7rem;
			}
			@include min($lap) {
				padding-right: 10rem;
			}
		}
		&--strategy {
			@media (min-width: 1599px) and (max-width: 1601px) and (min-height: 800px) and (max-height: 901px) {
				transform: translateY(-10vh);
			}
		}
	}
	&__text {
		margin-top: 1.5rem;
		font-size: 1.6rem;
		@include min($lg) {
			font-size: (2.6rem * 0.75);
			margin-top: (3.4rem * 0.75);
		}
		@include min($lap) {
			margin-top: 3.4rem;
			font-size: 2.6rem;
		}
		font-weight: 300;
		line-height: (312 / 260);
		color: #025F77;
		~ .about__text {
			margin-top: 0;
		}
	}

	&__image {
		font-size: 0;
		width: 70%;
		order: -1;

		@include min($sm) {
			order: 0;
			width: 40%;
		}
		.art--mobile{
			display: none;
		}
		@include max($sm) {
			.art{
				display: none;
			}
			.art--mobile{
				display: block;
			}
		}
		&--manage,
		&--creation {

			@include min($lg) {
				width: auto;
			}
		}

		&--manage {

			@include min($lg) {
				margin-right: 6rem;
			}

			@include min($lap) {
				margin-right: 15rem;
			}
			@media (min-width: 1599px) and (max-width: 1601px) and (min-height: 800px) and (max-height: 901px) {
			 	margin-right: 10rem;
			}
			@media (min-width: 1279px) and (max-width: 1281px) and (min-height: 750px) and (max-height: 801px) {
				margin-right: 0;
			}
		}

		&--creation {

			@include min($lg) {
				margin-right: 20rem;
			}
			@media (min-width: 1599px) and (max-width: 1601px) and (min-height: 800px) and (max-height: 901px) {
			 	margin-right: 10rem;
			}
			@media (min-width: 1279px) and (max-width: 1281px) and (min-height: 750px) and (max-height: 801px) {
				margin-right: 10rem;
			}
		}

		img {
			z-index: 2;
			position: relative;
			@include max($lg) {
				width: 80%;
			}
			@include max($sm) {
				width: 100%;
				height: auto;
			}
		}
		&--strategy {
			position: relative;
			z-index: 1;
			@include max($md) {
				margin: 3rem 0 -2rem;
			}
			img {
				transform: translateY(5%);
				@include min($lap) {
					transform: translateY(1%) translateY(-60px);
				}
				left: 1.3rem;
			}
			.icon-strategyPlus {
				@include max($lg) {
					display: none;
					position: absolute;
					top: 6rem;
					left: 3rem;
			    transform: translate(0%, 100%) scale(0.5);
				}
			}
			.icon-patternLines {
				@include max($lg) {
					display: none;
					position: absolute;
					bottom: 6rem;
					left: 0;
					z-index: 3;
					transform: translate(-15%, -60%) scale(0.5);
				}
			}
			.icon-crop {
				position: absolute;
				z-index: 3;
				bottom: 0;
				left: 0;
				transform: translate(-5%, 70%);
				@include max($lg) {
					transform: translate(-10%, 30%) scale(0.7);
			    transform-origin: left bottom;
					height: 300px;
				}
			}
			.borderedCircle {
				z-index: 1;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 16rem;
				height: 16rem;
				border-width: 5rem;
				transform: translate(-5%, -55%) scale(0.8);
				@include min($lg) {
					width: 35rem;
					height: 35rem;
					border-width: 9.7rem;
					transform: translate(-5%, -96.5%);
				}
			}
			.whiteCircle {
				transform: translate(85%, 10%);
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 3;
				@include max($lg) {
					width: 20rem;
					height: 20rem;
					transform: translate(45%, 40%) scale(0.6);
				}
			}
			.patternCircle {
				transform: translate(150%, -105%) rotate(-35deg);
				position: absolute;
				bottom: 0;
				left: 0;
				width: 16rem;
				height: 16rem;
				z-index: 3;
				@include max($lg) {
					width: 16rem;
					height: 16rem;
					transform-origin: left bottom;
					transform: translate(85%, -10%) rotate(-35deg) scale(0.45);
				}
			}
		}
		&--creation {
			position: relative;
			z-index: 1;
			transform: translate(-10%, 10%);
			left: -3vw;
			top: 4vw;
			@include max($sm) {
				margin-top: 2rem;
				margin-bottom: -1rem;
			}
			img {
				transform: rotate(-9deg);
			}
			.icon-patternLines {
				@include max($lg) {
					position: absolute;
					bottom: 0;
					right: 0;
					transform: translate(50%, -250%) scale(0.5);
					z-index: 3;
				}
			}
			.icon-strategyPlus {
				@include max($lg) {
					position: absolute;
					top: 0;
					left: 0;
					transform: translate(100%, 150%) scale(0.5);
					z-index: 3;
				}
			}
			.whiteCircle {
				position: absolute;
				right: 0;
				bottom: 0;
				z-index: 3;
				transform: translate(20%, 30%);
				width: 15rem;
				height: 15rem;
				@include min($sm) {
					width: 20rem;
					height: 20rem;
					transform: translate(15%, 15%);
				}
				@include min($md) {
					transform: translate(20%, 20%);
					width: 28rem;
					height: 28rem;
				}
				@include min($lg) {
					transform: translate(12%, 18%);
					width: 33.2rem;
					height: 33.2rem;
				}
			}
			.patternCircle--twofour {
				position: absolute;
				z-index: 3;
				top: 50%;
				left: 0;
				height: 6.8rem;
				width: 6.8rem;
				opacity: 0.46;
				transform: translate(0, -100%) rotate(-35deg) scale(0.5);
				@include min($sm) {
					transform: translate(25%, -100%) rotate(-35deg) scale(0.7);
				}
				@include min($md) {
					transform: translate(50%, -100%) rotate(-35deg);
				}
				@include min($lg) {
					transform: translate(100%, -200%) rotate(-35deg);
				}
			}
			.patternCircle--more {
				position: absolute;
				z-index: 4;
				left: 0;
				bottom: 0;
				width: 16rem;
				height: 16rem;
				transform: translate(35%, 0) rotate(-35deg) scale(0.5);
				@include min($sm) {
					transform: translate(40%, -10%) rotate(-35deg) scale(0.7);
				}
				@include min($md) {
					width: 14rem;
					height: 14rem;
					transform: translate(100%, -20%) rotate(-35deg);
				}
				@include min($lg) {
					width: 18rem;
					height: 18rem;
					transform: translate(100%, -20%) rotate(-35deg);
				}
			}
			.borderedCircle {
				position: absolute;
				top: 0;
				right: 0;
				width: 20rem;
				height: 20rem;
				border-width: 6rem;
				transform: translate(20%, -10%);
				@include min($sm) {
					transform: translate(-10%, -10%);
				}
				@include min($md) {
					border-width: 10rem;
					width: 32rem;
					height: 32rem;
				}
				@include min($lg) {
					border-width: 13rem;
					width: 42rem;
					height: 42rem;
					transform: translate(20%, -10%);
				}
			}
			.icon-crop {
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 3;
				height: 400px;
				transform: translate(-15%, 20%);
				@include max($sm) {
					height: 156px;
				}
			}
		}
		&--manage {
			position: relative;
			z-index: 1;
			margin-top: 20rem;
			@include max($md) {
				margin-top: 4rem;
				margin-bottom: -7rem;
			}
			img {
				z-index: 2;
			}
			.whiteCircle {
				position: absolute;
				bottom: 0;
				z-index: 3;
				right: 0;
				width: 14rem;
				height: 14rem;
				transform: translate(25%, 0);
				@include min($sm) {
					transform: translate(-35%, -20%);
				}
				@include min($lg) {
					transform: translate(30%, 10%);
					width: 33.2rem;
					height: 33.2rem;
				}
			}
			.icon-patternLines {
				transform: translate(20%, 220%) scale(0.5);
				position: absolute;
				right: 0;
				top: 0;
				z-index: 3;
				@include min($md) {
					transform: translate(-100%, 330%) scale(0.5);
				}
			}
			.icon-strategyPlus {
				transform: translate(15%, 30%) scale(0.5);
		    position: absolute;
		    right: 0;
		    top: 0;
		    z-index: 3;
				@include min($md) {
					transform: translate(-150%, 70%) scale(0.5);
				}
			}
			.borderedCircle {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: 16rem;
				height: 16rem;
				border-width: 4.5rem;
				@include max($lg) {
					transform: translate(15%, -5%);
				}
				@include min($lg) {
					border-width: 11rem;
					width: 36rem;
					height: 36rem;
				}
			}
			.patternCircle {
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 4;
				height: 16rem;
				width: 16rem;
				transform: translate(40%, 0) rotate(-40deg) scale(0.5);
				@include min($sm) {
					transform: translate(50%, -15%) rotate(-40deg) scale(0.5);
				}
				@include min($lg) {
					transform: translate(120%, -70%) rotate(-40deg);
				}
			}
			.icon-crop {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 4;
				transform: translate(-27%, 30%) scale(0.5);
				@include min($lg) {
					transform: translate(-5%, 55%);
				}
				@include min($lap) {
					transform: translate(0, 50%);
				}
				@include max($lg) {
					height: 400px;
					transform: translate(-25%, 35%) scale(0.5);
				}
				@include max($md) {
					transform: translate(-27%, 30%) scale(0.5);
					height: 300px;
				}
			}
		}
	}
	strong {
		font-size: 1.6rem;
		@include min($lg) {
			font-size: (2.6rem * 0.75);
		}
		@include min($lap) {
			font-size: 2.6rem;
		}
		line-height: (312 / 260);
		color: #025F77;
		font-weight: 700;
	}
	&--alt {
		display: flex;
		flex-direction: row-reverse;
		@include max($sm) {
			flex-direction: column;
		}
		.about__heading {
			&::after {
				@include min($sm) {
					left: auto;
					right: 0;
				}
			}
		}
		.about__content {
			@include min($sm) {
				margin-right: 8rem;
				margin-left: 2rem;
				text-align: right;
			}
			@include min($lg) {
				margin-right: 3rem;
			}
			@include min($lap) {
				margin-right: 20rem;
			}
			margin-left: 0;
			.animUp > span {
				justify-content: flex-end;
			}
		}
	}
}
.fp-enabled {
	.section {
		$mainDelay: 1.6s;
		$firstStep: 0.7s;
		$secondStep: 0.9s;
		$thirdStep: 1.8s;
		$fourthStep: 2.3s;
		.about {
			.animUp {
				&::after {
					transition: transform 0.8s cubic-bezier(0.13, 0.4, 0.29, 0.97);
				}
				span {
					transition: transform 0.8s cubic-bezier(0.13, 0.4, 0.29, 0.97);
				}
			}
		}
		&.active {
			.about {
				.animUp {
					&::after {
						transition: transform 0.8s cubic-bezier(0.13, 0.4, 0.29, 0.97) $firstStep;
					}
					span {
						transition: transform 0.8s cubic-bezier(0.13, 0.4, 0.29, 0.97) $firstStep;
					}
				}
			}
		}
		.about {
			&__image {
				&--strategy {
					&.fromTopStrat {
						.icon-crop {
							$opacityDuration: 0.7s;
							$opacity: 0;
							$opacityDelay: 0s;
							$transformDuration: 0.7s;
							$transform: translate(-5%, 70%);
							$transformDelay: 0s;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
					}
					.borderedCircle {
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 1s;
						$transform: translate(-50%, -110%);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.patternCircle {
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 1s;
						$transform: translate(100%, -130%) rotate(-35deg);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.whiteCircle {
						$opacityDuration: 0.7s;
						$opacity: 1;
						$opacityDelay: 0s;
						$transformDuration: 1s;
						$transform: translate(85%, 75%);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.icon-strategyPlus {
						position: absolute;
						top: 0;
						left: 0;
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 1s;
						$transform: translate(50%, 300%);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.icon-patternLines {
						position: absolute;
						bottom: 0;
						left: 0;
						z-index: 3;
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 1s;
						$transform: translate(-50%, -700%);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
				}
			}
		}
		&.active {
			.about {
				&__image {
					&--strategy {
						&.fromTopStrat {
							.icon-crop {
								$opacityDuration: 0.5s;
								$opacity: 1;
								$opacityDelay: 0.7s;
								$transformDuration: 0.5s;
								$transform: translate(-5%, 50%);
								$transformDelay: 0.7s;
								@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
							}
						}
						.borderedCircle {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $thirdStep;
							$transformDuration: 1s;
							$transform: translate(-5%, -110%);
							$transformDelay: $thirdStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.patternCircle {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $fourthStep;
							$transformDuration: 1s;
							$transform: translate(155%, -130%) rotate(-35deg);
							$transformDelay: $fourthStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.whiteCircle {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $thirdStep;
							$transformDuration: 1s;
							$transform: translate(85%, -15%);
							$transformDelay: $thirdStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.icon-strategyPlus {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $fourthStep;
							$transformDuration: 1s;
							$transform: translate(100%, 300%);
							$transformDelay: $fourthStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.icon-patternLines {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $fourthStep;
							$transformDuration: 1s;
							$transform: translate(0%, -700%);
							$transformDelay: $fourthStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
					}
				}
			}
		}
		.about {
			&__image {
				&--creation {
					.borderedCircle {
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 0.7s;
						$transform: translate(70%, -10%);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.patternCircle--more {
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 0.7s;
						$transform: translate(150%, -70%) rotate(-35deg);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.patternCircle--twofour {
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 0.7s;
						$transform: translate(150%, -200%) rotate(-35deg);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.whiteCircle {
						$opacityDuration: 0.7s;
						$opacity: 1;
						$opacityDelay: 0s;
						$transformDuration: 0.7s;
						$transform: translate(12%, 58%);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.icon-strategyPlus {
						position: absolute;
						top: 0;
						left: 0;
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 0.7s;
						$transform: translate(250%, 300%);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.icon-patternLines {
						position: absolute;
						bottom: 0;
						right: 0;
						z-index: 3;
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 0.7s;
						$transform: translate(100%, -750%);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
				}
			}
		}
		&.active {
			.about {
				&__image {
					&--creation {
						.borderedCircle {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $thirdStep;
							$transformDuration: 1s;
							$transform: translate(20%, -10%);
							$transformDelay: $thirdStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.patternCircle--more {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $fourthStep;
							$transformDuration: 1s;
							$transform: translate(100%, -70%) rotate(-35deg);
							$transformDelay: $fourthStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.patternCircle--twofour {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $fourthStep;
							$transformDuration: 1s;
							$transform: translate(100%, -200%) rotate(-35deg);
							$transformDelay: $fourthStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.whiteCircle {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $thirdStep;
							$transformDuration: 1s;
							$transform: translate(12%, -10%);
							$transformDelay: $thirdStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.icon-strategyPlus {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $fourthStep;
							$transformDuration: 1s;
							$transform: translate(200%, 300%);
							$transformDelay: $fourthStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.icon-patternLines {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $fourthStep;
							$transformDuration: 1s;
							$transform: translate(50%, -750%);
							$transformDelay: $fourthStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
					}
				}
			}
		}
		.about {
			&__image {
				&--manage {
					.borderedCircle {
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 0.7s;
						$transform: translate(-25%, 0);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.whiteCircle {
						transition: transform 1s ease-in-out 0s;
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 0.7s;
						$transform: translate(30%, 110%);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.patternCircle {
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 0.7s;
						$transform: translate(60%, -70%) rotate(-40deg);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.icon-strategyPlus {
						position: absolute;
						top: 0;
						right: 0;
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 0.7s;
						$transform: translate(-50%, 0);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
					.icon-patternLines {
						position: absolute;
						bottom: 0;
						right: 0;
						z-index: 3;
						$opacityDuration: 0.7s;
						$opacity: 0;
						$opacityDelay: 0s;
						$transformDuration: 0.7s;
						$transform: translate(-50%, -980%);
						$transformDelay: 0s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
				}
			}
		}
		&.active {
			.about {
				&__image {
					&--manage {
						.borderedCircle {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $thirdStep;
							$transformDuration: 1s;
							$transform: translate(25%, 0%);
							$transformDelay: $thirdStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.patternCircle {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $fourthStep;
							$transformDuration: 1s;
							$transform: translate(110%, -70%) rotate(-40deg);
							$transformDelay: $fourthStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.whiteCircle {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $thirdStep;
							$transformDuration: 1s;
							$transform: translate(30%, 10%);
							$transformDelay: $thirdStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.icon-strategyPlus {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $fourthStep;
							$transformDuration: 1s;
							$transform: translate(0, 0);
							$transformDelay: $fourthStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
						.icon-patternLines {
							$opacityDuration: 1s;
							$opacity: 1;
							$opacityDelay: $fourthStep;
							$transformDuration: 1s;
							$transform: translate(0, -980%);
							$transformDelay: $fourthStep;
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
					}
				}
			}
		}
		$opacityDuration: 0.7s;
		$opacity: 0;
		$opacityDelay: 0s;
		$transformDuration: 0.7s;
		$transformDelay: 0s;
		.about {
			&__image {
 				$transform: translateX(-50%);
				@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
				@include btw($lg, $lap) {
					transform: scale(0.75);
					transform: scale(0.75) translateX(-20%);
				}
				&.fromTopStrat {
					opacity: 1;
					transform: none;
					@include btw($lg, $lap) {
						transform: scale(0.75);
					}
				}
			}
		}
		.about--alt {
			.about__image {
				$transform: translateX(50%);
				@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
				@include btw($lg, $lap) {
					transform: scale(0.75);
					transform: scale(0.75) translateX(20%);
				}
			}
		}
		&.active {
			.about {
				&__image {
					$opacityDuration: $secondStep;
					$opacity: 1;
					$opacityDelay: 1.3s;
					$transformDuration: $secondStep;
					$transform: translateX(0);
					$transformDelay: 1.3s;
					@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					@include btw($lg, $lap) {
						transform: scale(0.75) translateX(0);
					}
				}
			}
		}
	}
}
