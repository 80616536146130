.anim {
  &--text {
    transform: translateY(100%);
    opacity: 0;
    font-weight: 100;
    transition: opacity .4s ease-in-out .1s, transform .5s ease-in-out .2s;
    &.anim--active {
      transform: none;
      opacity: 1;
    }
  }
  &--heading {
    transform: translateY(100%);
    opacity: 0;
    transition: opacity .4s ease-in-out, transform .6s ease-in-out;
    &.anim--active {
      transform: none;
      opacity: 1;
    }
  }
  &--image, &--newestPost {
    transform: translateY(50%);
    opacity: 0;
    transition: opacity .7s ease-in-out, transform .6s ease-in-out;
    &.anim--active {
      transform: none;
      opacity: 1;
    }
  }
  &--newestPost {
    transition-delay: 2s;
  }
  &--image-horizontal, &--image-horizontalKefiry {
    transform: translateY(100%);
    opacity: 0;
    transition: opacity .7s ease-in-out, transform .6s ease-in-out;
    &.anim--active {
      transform: none;
      opacity: 1;
    }
  }
  &--stripes {
    transform: translateX(-100%);
    opacity: 0;
    transition: opacity .7s ease-in-out 1s, transform .6s ease-in-out 1s;
    &.anim--active {
      transform: none;
      opacity: 1;
    }
  }
}
.headingContainer, .textContainer {
  overflow: hidden;
}

// no animations on ipad
.ipad {
  transform: none;
  opacity: 1;
}
