.contactInfo {
	display: flex;
	margin-bottom: 2rem;
	@include max($lg) {
		flex-direction: column;
	}
	width: 100%;
	@include min($lg) {
		width: 65%;
	}
	@include min($lap) {
		width: calc(100% - 300px);
	}
	@include min($xl) {
		width: 50rem;
		width: 26vw;
	}
	&__col {
		width: 100%;
		@include min($xl) {
			width: 25rem;
			width: 13vw;
		}
		color: #fff;
	}
	ul {
		line-height: (2655/2288);
		margin-top: 1rem;
		font-size: 1.6rem;
		a {
			&:hover {
				text-decoration: underline;
			}
		}
		@include min($lg) {
			margin-top: 2rem;
			font-size: (2.288rem * 0.7);
		}
		@include min($lap) {
			font-size: 2.288rem;
		}
	}
	h3 {
		line-height: (2929/2472);
		font-weight: 900;
		font-size: 1.8rem;
		@include max($lg) {
			font-size: 2.472rem;
			margin-top: 3rem;
		}
		&::after {
			content: '';
			display: block;
			height: 1px;
			width: 100%;
			background-color: #d3d3d3;
			margin-top: 1rem;
			opacity: 0.3;
			@include min($lg) {
				margin-top: 2rem;
			}
		}
	}
}
