.introCase {
  height: 500px;
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 12rem;
  background-size: cover;
  @include max($lap) {
    max-height: 55vh;
    align-items: center;
    padding-bottom: 5rem;
    background-size: cover;
  }
  @include max($lg) {
    max-height: 65vh;

  }
  @include max($md) {
    max-height: 55vh;
    background-position: center center;
    padding-bottom: 0;
  }
  @include max($sm) {
    max-height: none;
    height: auto;
    padding: 11rem 0 5rem;
    flex-direction: column;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    opacity: .9;
    width: 100%;
    height: 100%;
    background-image: url(img/patternBg.png);
    -webkit-transition: background-color 0.5s ease-in-out;
    transition: background-color 0.5s ease-in-out;
    background-color: transparent;
    z-index: 1;
  }
  &__buttons {
    @include max($sm) {
      display: flex;
      align-items: center;
      margin-top: 2rem;
    }
  }
  .btn--lighter:not(.mobile) {
    position: absolute;
    top: 80px;
    padding: 1.5rem 2rem 1.8rem;
    right: 137px;
    height: 3.9rem;
    font-size: 1.9rem;
    @include max($lap) {
      height: 3rem;
    }
    @include max($lg) {
      top: auto;
      bottom: 30px;
      right: 50%;
      height: 3.9rem;
      transform: translateX(50%);
    }
    @include max($md) {
      bottom: 10px;
    }
    @include max($sm) {
      display: none;
    }
  }
  .btn--lighter.mobile {
    @include min($sm) {
      display: none;
    }
    @include max($sm) {
      position: relative;
      margin: 0 1rem;
      height: 4rem;

      // top: auto;
      // right: auto;
    }
  }
  &__content {
    position: relative;
    z-index: 2;
  }
  h1 {
    font-weight: 900;
    color: #fff;
    font-size: 11.6rem;
    text-align: center;
    &::after {
      content: '';
      display: block;
      margin: 0 auto;
      width: 100px;
      height: 5px;
      background-color: #00D3D5;
      @include max($sm) {
        margin: 1rem auto;
        width: 50px;
        height: 3.5px;
      }
    }
    @include max($lap) {
      font-size: 8.7rem;
    }
    @include btw($md, $lg) {
      font-size: 9rem;
    }
    @include max($sm) {
      font-size: 5rem;
      line-height: 1;
    }
  }
  h2 {
    color: #fff;
    font-weight: 300;
    font-size: 3.9rem;
    letter-spacing: -0.05em;
    margin-top: 1rem;
    text-align: center;
    @include max($lap) {
      font-size: 2.9rem;
    }
    @include max($sm) {
      font-size: 2.5rem;
      margin-top: 1rem;
      line-height: 1;
      font-weight: 400;
      padding: 0 1rem;
    }

  }
  &__bordered {
    position: absolute;
    width: 140px;
    top: -48px;
    left: 21px;
    height: 140px;
    border: 4rem solid #00D3D5;
    z-index: -1;
    @include max($sm) {
      width: 60px;
      height: 60px;
      border: 2rem solid #00D3D5;
      top: 0px;
      left: -10px;
      display: none;
    }
  }
  &__pattern {
    position: absolute;
    top: 160px;
    left: 0;
    transform: rotate(-42deg);
    width: 85px;
    height: 85px;
    opacity: .4;
    z-index: -1;
    @include max($sm) {
      width: 55px;
      height: 55px;
      top: 100px;
      left: 20px;
      display: none;
    }
  }
  .icon-strategyPlus {
    position: absolute;
    top: -103px;
    right: -99px;
    @include max($xl) {
      top: -50px;
    }
  }
  .previous-case, .next-case {
    position: absolute;
    z-index: 2;
    color: #fff;
    font-weight: 900;
    font-size: 2rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    transition: opacity .2s ease-in-out;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      @include max($sm) {
        width:100%;
        height:100%;
      }
    }
    &:hover {
      opacity: .7;
      transition: opacity .2s ease-in-out;
    }
    @include max($md) {
      font-size: 1.6rem;
    }
    @include max($sm) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #fff;
      width: 40px;
      height: 40px;
      p {
        display: none;
      }
    }
  }
  svg {
    width: 40px;
    height: 40px;
    @include max($sm) {
      width: 30px;
      height: 30px;
    }
  }
  .previous-case {
    top: 50%;
    left: 50px;
    @include max($lg) {
      bottom: 30px;
      top: auto;
    }
    @include max($md) {
      bottom: 10px;
      left: .5rem;
      top: auto;
    }

    @include max($sm) {
      bottom: auto;
      left: auto;
    }
    span {
      margin-right: 2rem;
      @include max($sm) {
        margin-right: 0;
        // transform: scale(.6);
      }
    }
  }
  .next-case {
    top: 50%;
    right: 50px;
    text-align: right;
    @include max($lg) {
      bottom: 30px;
      top: auto;
    }
    @include max($md) {
      bottom: 10px;
      right: .5rem;
      top: auto;
    }
    @include max($sm) {
      bottom: auto;
      right: auto;
    }
    span {
      margin-left: 2rem;
      transform: rotate(180deg);
      @include max($sm) {
        // transform: rotate(180deg) scale(.6);
        margin-left: 0;

      }
    }
  }
}

.intro-book2meet {
  .icon-strategyPlus {
    top: -30px;
    right: -60px;
  }
  .introCase__bordered {
    top: 25px;
    left: -59px;
  }
  .introCase__pattern {
    top: 15px;
    left: 63px;
  }
}
.intro-pkol {
  .introCase {
    padding-bottom: 6rem;
    @include max($lap) {
      padding-bottom: 0;
    }
    @include max($md) {
      padding-bottom: 0;
    }
    @include max($sm) {
      padding-bottom: 6rem;
    }
  }
  h1 {
    font-size: 89px;
    line-height: (860/890);
    max-width: 900px;
    &::after {
      content: '';
      margin-top: 3rem;
    }
    @include max($lap) {
      font-size: 6.65rem;
      max-width: 675px;
    }
    @include max($lg) {
      font-size: 7rem;
    }
    @include max($md) {
      font-size: 7rem;
      line-height: 1;
    }
    @include max($sm) {
      font-size: 4rem;
      line-height: 1;
    }
  }
  .borderedCircle {
    left: 50px;
    @include max($lg) {
      left: -150px;
      top: 0;
    }
    @include max($sm) {
      left: -40px;
    }
  }
}
.intro-sm {
  h1 {
    @include max($sm) {
      line-height: 1;
      font-size: 4rem;
    }
  }
  .borderedCircle {
    @include max($sm) {
      left: -20px;
    }
  }
}
.intro-lp {
  h1 {
    @include max($sm) {
      line-height: 1;
      font-size: 4rem;
    }
  }
  .borderedCircle {
    @include max($sm) {
      left: -20px;
    }
  }
}
.intro-movingDay {
  .borderedCircle {
    left: -70px;
    @include max($sm) {
      left: -20px;
    }
  }
}
