.introOut {
	position: relative;
	z-index: 1;
	height: 100%;
	&__circleFirst {
		flex-shrink: 0;
		position: absolute;
		left: 0;
		top: calc(50% - 5rem);
		width: 18rem;
		height: 18rem;
		transform: translateX(150%) rotate(-35deg);
	}
	&__circleSecond {
		flex-shrink: 0;
		position: absolute;
		right: 0;
		top: 50%;
		width: 18rem;
		height: 18rem;
		transform: translate(-150%, 80%) rotate(-35deg);
	}
	.borderedCircle {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80.2rem;
		height: 80.2rem;
		border-width: 22rem;
	}
	&__team {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		transform: scale(0.75);
		transform-origin: center bottom;
		@include min($lap) {
			transform: scale(1);
		}
	}
	img {
		position: relative;
		flex-shrink: 0;
	}
	.copy {
		z-index: 1;
		left: 15vw;
	}
	.strategy {
		z-index: 3;
		left: 6vw;
	}
	.manage {
		z-index: 2;
		right: 8vw;
	}
	.art {
		z-index: 1;
		right: 22vw;
	}
}