.offer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  @include max($lg) {
    flex-direction: column;
    padding: 4rem 2.5rem 0;
  }
  &__icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @include max($lg) {
      width: 100%;
    }
    @include min($lg) {
      width: 30vw;
      height: 40vw;
      margin-right: 5vw;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @include btw($sm, $lg) {
      align-items: center;
    }

    @include min($lg) {
      width: 35vw;
      height: 40vw;
    }
  }

  &__contentWrapper {
    display: flex;
    align-items: center;

    @include max($sm) {
      align-items: flex-start;
    }

    @include max($lg) {
      flex-direction: column;
    }

    @include min($lg) {
      margin-top: 14rem;
    }

    @include min($lap) {
      margin-top: 3rem;
    }
  }

  &__heading {
    position: relative;
    font-size: 4rem;
    font-weight: 900;
    display: flex;
    flex-direction: column;

    @include min($xxs) {
      font-size: 5rem;
    }

    @include btw($sm, $lg) {
      text-align: center;
      align-items: center;
    }

    @include min($lg) {
      font-size: 3.5vw;
      flex-direction: row;
      align-items: center;
    }

    @include btw($lg, $lap) {
      font-size: 3.5vw;
    }
  }

  &__line {
    height: 0.6rem;
    width: 9.2rem;
    background-color: #00D3D5;

    @include max($lg) {
      margin-top: 1rem;
    }

    @include min($lg) {
      width: 10vw;
      margin-left: 5rem;
    }

    @include btw($lg, $lap) {
      width: 8vw;
    }
  }

  &__btnWrap {
    width: 100%;
    display: inline-flex;
    justify-content: center;

    @include min($lg) {
      width: auto;
    }
  }

  &__btn {
    margin-top: 2rem;
    height: 5rem;
		padding: 0 4rem;
		font-size: 2rem;
		font-weight: 700;
    display: flex;
    align-items: center;
    align-content: center;

    @include max($lg) {
      margin: 2rem 0;
    }

    @include btw($md, $lg) {
      margin-bottom: 7rem;
    }

    @include min($lap) {
      height: 6rem;
      padding: 0 8.2rem;
      font-size: 2.4rem;
    }
  }

  .comp__item {
    justify-content: center;
    align-items: center;
    margin: 0;

    @include max($sm) {
      width: 70%;
    }

    // @include max($md) {
    //   margin-bottom: 2rem;
    // }

    @include btw($md, $lg) {
      width: calc(100%/3 - 7rem);
      height: 18vw;
      margin-bottom: 5rem;
    }

    @include btw($sm, $md) {
      width: calc(100%/3 - 3rem);
      height: 20vw;
      margin-bottom: 2rem;
    }
    @include max($sm) {
      margin-bottom: 1rem;
    }

    @include min($lg) {
      width: 40%;
      height: calc(100%/3 - 50px);
    }
  }

  .comp__heading {
    position: static;
    transform: none;

    @include min($md) {
      font-size: 1.8vw;
    }

    @include min($lg) {
      font-size: 1.1vw;
    }
  }

  .comp__text {
    display: none;
  }

  // .comp__item:first-child,
  // .comp__item:nth-child(2) {
  //
  //     @include btw($lg, $lap) {
  //       margin: 5rem 0;
  //     }
  // }

  .comp__item:nth-child(3),
  .comp__item:nth-child(4) {

    @include min($sm) {
      margin: 0;
    }
  }

  .comp__item:last-child {

    @include max($sm) {
      margin: 0;
    }
  }
  .comp__icon {
    @include max($sm) {
      transform: scale(.7);
    }
  }
}
