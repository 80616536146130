.introProjects {
  overflow: hidden;
  padding-top: 5rem;
  @include max($xl) {
    padding-top: 1rem;
  }
  @include max($lg) {
    padding-top: 9.5rem;
  }
  &__heading {
    font-size: 93px;
    font-weight: 900;
    @include max($lap) {
      font-size: 69.5px;
    }
    @include max($md) {
      font-size: 60px;
    }
    @include max($sm) {
      font-size: 35px;
    }
    span {
      @include min($md) {
        color: white;
      }
    }
  }
  .icon-logo {
    position: absolute;
    top: 50px;
    left: 70px;
    z-index: 2;
    transform: scale(.6);
    @include max($xl) {
      top: 30px;
      left: 30px;
    }
    @include max($md) {
      top: -20px;
      left: -10px;
      transform: scale(.4);
    }
  }
  .art {
    @include max($xl) {
      max-height: 517px;
    }
    @include max($lap) {
      max-height: 443px;
    }
    @include max($md) {
      max-height: 320px;
    }
    @include max($sm) {
      max-height: 250px;
    }
  }
  h2 {
    font-size: 35px;
    font-weight: 900;
    margin-left: 110px;
    &::after {
      content: '';
      background-color: #00D3D5;
      height: 5px;
      width: 100px;
      display: block;
      margin-top: 22px;
      @include max($md) {
        margin-top: 10px;
      }
    }
    @include max($lg) {
      font-size: 30px;
    }
    @include max($lap) {
      font-size: 26.5px;
      margin-left: 82.5px;
    }
    @include max($md) {
      margin-left: 50px;
      font-size: 25px;
    }
    @include max($sm) {
      font-size: 20px;
      margin-left: 0;
    }
  }
  span.icon, .borderedCircle, .patternCircle, .circle-crop {
    position: absolute;
  }
  .container--big {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    @include max($md) {
      flex-direction: column;
    }
    // min-height: 750px;
  }
  .circle-crop {
    width: 300px;
    height: 300px;
    left: -55px;
    bottom: -104px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 2;
    @include max($xl) {
      width: 265px;
      height: 265px;
    }
    @include max($lap) {
      width: 225px;
      height: 225px;
    }
    @include max($md) {
      width: 180px;
      height: 180px;
    }
    @include max($sm) {
      width: 160px;
      height: 160px;
    }
  }
  .borderedCircle {
    top: 30px;
    z-index: -1;
    left: 0;
    @include max($xl) {
      width: 48.5rem;
      height: 48.5rem;
    }
    @include max($lap) {
      width: 412px;
      height: 412px;
      border: 12rem solid #00D3D5;

    }
    @include max($md) {
      width: 28.5rem;
      height: 28.5rem;
      border: 8rem solid #00D3D5;
    }
    @include max($sm) {
      width: 21.7rem;
      height: 21.7rem;
      border: 6rem solid #00D3D5;
    }
    // width: 29vw;
    // height: 29vw;
  }
  &__headings {
    position: relative;
    z-index: 3;
    @include min($md) {
      transform: translate(-30px, 83px);
    }
    @include min($lap) {
      transform: translate(-39px, 83px);
    }
    @include min($xl) {
      transform: translate(-33px, 93px);
    }
  }
  &__person {
    position: relative;
    max-height: 100%;
  }
  &__pattern {
    bottom: 158px;
    left: -210px;
    z-index: 1;
    width: 300px;
    height: 300px;
    transform: translate(20%, 40%) rotate(-40deg);
    @include max($xl) {
      width: 265px;
      height: 265px;
    }
    @include max($lap) {
      width: 225px;
      height: 225px;
      left: -180px;
      bottom: 140px;
    }
    @include max($md) {
      width: 180px;
      height: 180px;
      left: -150px;
      bottom: 90px;
      opacity: .5;
    }
    @include max($sm) {
      width: 160px;
      height: 160px;
      background-image: url('../assets/img/patternCircleLess.png');
    }
  }
  .patternCircle--more {
    width: 90px;
    height: 90px;
    top: 35px;
    right: -52px;
    opacity: .4;
    transform: translate(20%, 40%) rotate(-40deg);
    @include max($sm) {
      width: 50px;
      height: 50px;
      right: -10px;
      z-index:-1;

    }
  }
  .icon-managePlus {
    top: 21px;
    right: -87px;
    @include max($sm) {
      right: -45px;
    }
  }
}
.introProjects {
  $firstDelay: 0.7s;
  $secondDelay: 1.2s;
  $thirdDelay: 1.7s;
  &__person {
    $opacityDuration: 0.7s;
    $opacity: 0;
    $opacityDelay: $firstDelay;
    $transformDuration: 0.7s;
    $transform: translateX(-40%);
    $transformDelay: $firstDelay;
    @include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
  }
  .icon-managePlus {
    $opacityDuration: 0.7s;
    $opacity: 0;
    $opacityDelay: $thirdDelay;
    $transformDuration: 0.7s;
    $transform: translateX(-50%);
    $transformDelay: $thirdDelay;
    @include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
  }
  .patternCircle--more {
    $opacityDuration: 0.7s;
    $opacity: 0;
    $opacityDelay: $thirdDelay;
    $transformDuration: 0.7s;
    $transform: translate(-20%, 40%) rotate(-40deg);
    $transformDelay: $thirdDelay;
    @include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
  }
  &__pattern {
    $opacityDuration: 0.7s;
    $opacity: 0;
    $opacityDelay: $thirdDelay;
    $transformDuration: 0.7s;
    $transform: translate(-10%, 40%) rotate(-40deg);
    $transformDelay: $thirdDelay;
    @include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
  }
  .circle-crop {
    $opacityDuration: 0.7s;
    $opacity: 0;
    $opacityDelay: $secondDelay;
    $transformDuration: 0.7s;
    $transform: translateY(30%);
    $transformDelay: $secondDelay;
    @include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
  }
  .animUp {
    overflow: hidden;
    i {
      font-style: normal;
      display: inline-block;
      transform: translateY(100%);
      transition: transform 0.7s ease-in-out 0.9s;
    }
  }
}
.introProjects h2::after {
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.7s ease-in-out 1.1s;
}
.view {
  .animUp {
    i {
      transform: none;
    }
  }
  .introProjects {
    h2::after {
      transform: scaleX(1);
    }
    .patternCircle--more {
      opacity: 1;
      transform: translate(20%, 40%) rotate(-40deg);
    }
    &__person {
      opacity: 1;
      transform: translateX(0);
    }
    &__pattern {
      opacity: 1;
      transform: translate(20%, 40%) rotate(-40deg);
    }
    .circle-crop {
      opacity: 1;
      transform: none;
    }
    .icon-managePlus {
      opacity: 1;
      transform: none;
    }
  }
}

.introBlog {
  @include max($lg) {
    padding-top: 5.5rem;
  }
  &__logo {
    text-align: center;
    margin-bottom: 5vh;
    img {
      @include max($lap) {
        transform: scale(.6);
      }
    }
  }
  .container--huge {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @include max($lap) {
      justify-content: center;
    }
  }
  .art {
    transform: translateX(-50px);
    @include max($lap) {
      transform: translateX(-130px);
    }
    @include max($lg) {
      transform: none;
      margin-left: -104px;
    }
    @include max($md) {
      transform: translateX(-50px);
    }
    @include max($sm) {
      transform: none;
    }
  }
  .patternCircle {
    top: auto;
    bottom: 168px;
    left: 31px;
    transform: rotate(-40deg);
    width: 180px;
    height: 180px;
    z-index:-1;
    @include max($lap) {
      width: 120px;
      height: 120px;
      left: -50px;
      bottom: 100px;
    }
    @include max($md) {
      width: 100px;
      height: 100px;
      left: 0px;
      bottom: 100px;
    }
    @include max($sm) {
      left: 20px;
      bottom: 168px;
      display: none;
    }
  }
  .borderedCircle {
    width: 46rem;
    height: 46rem;
    top: 50px;
    left: 166px;
    @include max($xl) {
      left: 120px;
    }
    @include max($lap) {
      width: 360px;
      height: 360px;
      left: 27px;
      top: 41px;
    }
    @include max($lg) {
      width: 400px;
      height: 400px;
      left: 40px;
    }
    @include max($md) {
      width: 250px;
      height: 250px;
      top: 40px;
      left: auto;
      right: 30px;
    }
    @include max($sm) {
      width: 200px;
      height: 200px;
      right: -20px;
    }
  }
  .introProjects__heading {
    font-size: 4.5vw;
    @include max($lap) {
      font-size: 6rem;
    }
    @include max($lg) {
      font-size: 4.5rem;
    }
    @include max($md) {
      font-size: 3.5rem;
    }
    @include max($sm) {
      font-size: 3rem;
    }
  }
  .introProjects__headings {
    @include max($lg) {
      margin-left: 7vw;
    }
    @include max($sm) {
      margin-top: 1rem;
    }
  }
  h2 {
    color: #046078;
    font-size: 3.8rem;
    font-weight: 400;
    margin-left: 0;
    line-height: 1;
    @include max($lg) {
      font-size: 2.5rem;
    }
    @include max($md) {
      font-size: 2.2rem;
    }
    i {
      // background-color: #f9cbcd;
      padding: 0 0 .5rem 0;
    }
    &::after {
      margin-left: 1rem;
    }
  }
  a {
    display:none;
  }
}
