/* LIBS */
@import 'lib/swiper.min';

.mobileOpacity {
	opacity: 0;
}

@import 'lib/animate';
/* GENERIC */
@import 'generic/reset';

/* VARIABLES */

/* SPRITES */
@import 'sprite';
.icon {
	display: inline-block;
}

/* MIXINS */
@import '_mixins';

/* SETTINGS */
@import '_base';

/* LAYOUT */
@import 'layout/grid';
@import 'layout/container';
/* OBJECTS */
@import 'objects/borderedCircle';
@import 'objects/patternCircle';
@import 'objects/whiteCircle';
@import 'objects/btn';
@import 'objects/frame';
@import 'objects/tasks';
@import 'objects/anim';
@import 'objects/up';
@import 'objects/simpleLoader';
/* COMPONENTS */
@import 'components/header';
@import 'components/intro';
@import 'components/introClients';
@import 'components/introOut';
@import 'components/about';
@import 'components/portfolio';
@import 'components/project';
@import 'components/clients';
@import 'components/contact';
@import 'components/contactInfo';
@import 'components/logo';
@import 'components/heading';
@import 'components/grid';
@import 'components/footer';
@import 'components/follow';
@import 'components/info';
@import 'components/comp';
@import 'components/fastContact';
@import 'components/contactForm';
@import 'components/incStack';
@import 'components/refer';
@import 'components/references';
@import 'components/contactUs';
@import 'components/introProjects';
@import 'components/sectionProjects';
@import 'components/introProjects';
@import 'components/introCase';
@import 'components/sectionCustomer';
@import 'components/sectionCase_description';
@import 'components/sectionPromoPhotos';
@import 'components/sectionPreview';
@import 'components/sectionSocialMedia';
@import 'components/sectionSimpleDescription';
@import 'components/inputs';
@import 'components/sectionEffects';
@import 'components/line';
@import 'components/sectionTwoColumns';
@import 'components/swiper';
@import 'components/cookies';
@import 'components/otherProjects';
@import 'components/posts';
@import 'components/offer';
@import 'components/introPost';
@import 'components/404';
@import 'components/blogPost';
@import 'components/sectionOtherPosts';
@import 'components/postBox';
@import 'components/pagination';

$copyLap: translate(15%, 15%);
$copy: translate(4%, 8%) translateY(-60px);

$strategyLap: translate(15%, 5%);
$strategy: translateY(1%) translateY(-60px);

$manageLap: translate(7%, 8%);
$manage: translate(-8%, -2%) translateY(-60px);

$artLap: translate(-8%, 10%);
$art: translate(-20%, 5%) translateY(-60px);

/* STATE */
$easing: ease-in-out;
$expoOut: cubic-bezier(0.13, 0.4, 0.29, 0.97);

.underline {
	text-decoration: underline;
	font-weight: 900;
}

.logo {
	@include min($lg) {
		display: none;
	}
}
.contactFull {
	section {
		height: 100vh;
	}
}
.preload {
	.logo {
		@include min($lg) {
			display: flex;
		}
	}
}
.italic {
	font-style: italic;
}
.msg {
	stroke: #00D3D5;
	transition: stroke 0.3s ease-in-out;
}
.fp-viewing-intro,
.fp-viewing-contactus {
	.msg {
		stroke: #fff;
	}
}
.wp-fadeInUp {
	opacity: 0;
	&:nth-child(4n - 2) {
		animation-delay: 0.1s;
	}
	&:nth-child(4n - 1) {
		animation-delay: 0.2s;
	}
	&:nth-child(4n) {
		animation-delay: 0.3s;
	}
}
.introClients {
	&__bordered {
		opacity: 0;
		transform: translate(-95%, -50%);
		transition: 0.7s ease-in-out 0.3s;
		transition-property: opacity, transform;
	}
	&__pattern {
		opacity: 0;
		transform: translate(70%, 40%) rotate(-35deg);
		transition: 0.7s ease-in-out 0.2s;
		transition-property: opacity, transform;
	}
}
.view {
	.introClients {
		&__bordered {
			opacity: 1;
			transform: translate(-45%, -50%);
		}
		&__pattern {
			opacity: 1;
			transform: translate(20%, 40%) rotate(-35deg);
		}
	}
}
.intro {
	&::after {
		background-color: rgba(0,0,0,1);
		transition: background 1s $easing 0.3s;
	}
}
.fp-enabled {
	.header__toggle {
		transform: scaleX(0);
		transition: transform 0.6s cubic-bezier(1,0,0,1) 0.6s;
		transform-origin: left center;
		span {
			&:nth-child(1),
			&:nth-child(2) {
				background-color: #000;
			}
		}
	}
	.ready {
		.header__toggle {
			transform: none;
		}
	}
}
.loaded {
	.intro {
		&::after {
			background-color: rgba(0, 28, 38, 0.8);
		}
	}
}
.portfoli {
	.header__toggle {
		span {
			&:nth-child(1),
			&:nth-child(2) {
				background-color: #000;
			}
		}
	}
}
.center {
	display: flex;
	justify-content: center;
}
.introAnimate {
	.section.active.fp-completely {
		.intro {
			&__heading {
				&::after,
				&::before {
					transform: scaleX(0);
				}
				.animUp {
					> span {
						transform: translateY(100%);
						will-change: transform;
					}
				}
			}
		}
	}
	&.loaded {
		.section.active.fp-completely {
			.intro {
				&__heading {
					&::after,
					&::before {
						transform: scaleX(1);
						transition-delay: 0.6s;
					}
					.animUp {
						> span {
							transform: none;
						}
						&:nth-child(1) {
							span {
								transition-delay: 0.3s;
							}
						}
						&:nth-child(2) {
							span {
								transition-delay: 0.6s;
							}
						}
						&:nth-child(3) {
							span {
								transition-delay: 0.9s;
							}
						}
					}
					.animUp.anim__mainHeadWrap {
						span {
							transition-delay: 1s;
						}
					}
					.animUp.anim__mainLeadWrap {
						span {
							transition-delay: 1.2s;
						}
					}
				}
				.intro__lead {
					.animUp {
						span {
							transition-delay: 1.4s;
						}
					}
				}
			}
		}
	}
}

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 40;
	color: #00D3D5;
	font-weight: 900;
	line-height: 0.9;
	font-size: 8rem;
	font-size: 2.9rem;
	transform: translateX(-150%);
	transition: transform 0.7s $easing 0.3s;
	.wrap,
	.preloader__wrapper {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}
	&.active {
		.wrap,
		.preloader__wrapper {
			opacity: 1;
			transition: opacity 0.3s ease-in-out;
		}
		transition: transform 1s $easing;
		transform: none;
	}
	.logo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&__animation {
		position: relative;
		height: 20.6rem;
		width: 20.6rem;
		@include max($sm) {
			height: 150px;
			width: 150px;
		}
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			&:nth-child(1) {
				animation: 1.5s rotate infinite linear;
				@include max($sm) {
					max-height: 125px;
					max-width: 125px;
				}
			}
			&:nth-child(2) {
				animation: 2s rotateAlt infinite linear;
				@include max($sm) {
					max-height: 135px;
					max-width: 135px;
				}
			}
			&:nth-child(3) {
				animation: 3s rotate infinite linear;
				@include max($sm) {
					max-height: 150px;
					max-width: 150px;
				}
			}
		}
	}
	@keyframes rotate {
		0% {
			transform: translate(-50%, -50%) rotate(0deg);
		}
		100% {
			transform: translate(-50%, -50%) rotate(360deg);
		}
	}
	@keyframes rotateAlt {
		0% {
			transform: translate(-50%, -50%) rotate(0deg);
		}
		100% {
			transform: translate(-50%, -50%) rotate(-360deg);
		}
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		bottom: 4rem;
		@include min($sm) {
			bottom: 7rem;
		}
		left: 50%;
		transform: translateX(-50%);
	}
	.progress {
		width: 27rem;
		height: 0.3rem;
		position: relative;
		z-index: 1;
		background-color: rgba(0,211,213,0.56);
		margin-bottom: 3.5rem;
		span {
			display: block;
			width: 100%;
			transform: scaleX(0);
			transform-origin: left center;
			height: 0.5rem;
			position: absolute;
			top: -0.1rem;
			left: 0;
			background-color: #00D3D5;
		}
	}
	.rotate {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 1;
		&::after {
			content: '';
			display: block;
			width: 150%;
			height: 100%;
			position: absolute;
			top: 0;
			left: -25%;
			z-index: -1;
			background-color: #fff;
			@include min($lg) {
				transform: skewX(30deg);
			}
		}
	}
	.wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -65%);
		@include min($sm) {
			transform: translate(-50%, -50%);
		}
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

.hide-mobile {
	@include max($sm) {
		display: none;
	}
}

a {
	&:not([href]) {
		opacity: 0.5;
		cursor: no-drop;
	}
}
main,
body {
	overflow-x: hidden;
}
.preload:not(.loaded) {
	overflow: hidden;
}

.animUp {
	overflow: hidden;
}

.section {
	@include max($lg) {
		position: relative;
		// margin-bottom: 4rem;
	}
}

.logo {
	&__typo {
		span {
			@include min($lg) {
				transform: translateY(-200%);
			}
		}
	}
}
.person {
	&--strategy {
		.person {
			&__lines {
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 4;
				transform: translate(0, -650%);
			}
			&__plus {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 4;
				transform: translate(150%, 400%);
			}
		}
	}
}
.header__nav {
	transition: opacity 0.6s ease-in-out;
}
.fp-viewing-intro {
	.header__nav {
		opacity: 0;
	}
}
.fp-viewing-introAnim {
	.logo {
		&__typo {
			span {
				transform: translateY(0);
			}
		}
	}
}
.fp-viewing-contactus,
.fp-viewing-intro {
	.icon-logo {
		transform: translateY(-100%);
	}
	.header {
		&__menu {
			&.animUp {
				> div {
					transform: translateX(-100%);
					transition: transform 0.3s $expoOut 0s;
				}
			}
		}
	}
}
.header__toggle {
	span {
		&:nth-child(1),
		&:nth-child(2) {
			transition: background-color 0.3s $easing, top 0.3s $easing, transform 0.3s $easing;
		}
	}
}
.header__menu.animUp > div {
	transition: color 0.7s $expoOut;
}
.fp-viewing-contactus {
	@include max($sm) {
		min-height: 100vh;
	}
	@include min($sm) {
		height: 100vh;
	}
	.section {
		height: inherit;
	}
	.header__nav .header__menuLink {
		color: #fff;
	}
	.header__toggle {
		span {
			&:nth-child(1),
			&:nth-child(2) {
				background-color: #fff;
				transition: 0.3s $easing;
				transition-property: top, transform, background-color;
			}
		}
	}
	.header__menu.animUp > div {
		transform: none;
		color: #fff;
		transition: color 0.7s $expoOut;
	}
}
.header {
	&__menu {
		transition: transform 0.3s $easing;
		&.animUp {
			overflow: hidden;
			> div {
				transition: transform 0.3s $expoOut 0.7s;
			}
		}
	}
}
.icon-logo-typo,
.icon-logo {
	transition: transform 0.3s $easing 0.7s;
}
.fp-enabled {
	.section {
		overflow: hidden;
		background-color: #fff;
		z-index: 1;
		@include min($sm) {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.about--alt .about__heading::after {
			transform-origin: right bottom;
		}
		.intro {
			&__heading {
				.animUp {
					display: inline-block;
				}
			}
			&.anim {
				.intro {
					&__heading {
						.animUp {
							display: block;
						}
					}
				}
			}
		}
		.intro__content {
			text-shadow: 4px 2px 0px #000;
			transition: text-shadow 0.3s ease-in-out;
			&::after {
				transform: translateX(-50%) scaleX(0);
				transform-origin: left center;
			}
		}
		&.active {
			.intro__content {
				text-shadow: 4px 2px 12px #000;
				transition: text-shadow 0.3s ease-in-out 1.2s;
				&::after {
					transform: translateX(-50%) scaleX(1);
					transition: transform 0.9s ease-in-out 1.8s;
				}
			}
		}
		.intro {
			&__heading {
				&::before,
				&::after {
					transform: scaleX(0);
					transition: transform 0.6s ease-in-out;
				}
				&::before {
					transform-origin: left center;
				}
				&::after {
					transform-origin: right center;
				}
			}
		}
		&.active {
			.intro {
				.shadows {
					position: absolute;
					top: -70px;
					left: -250px;
					display: block;
					width: calc(100% + 500px);
					height: calc(100% + 200px);
					white-space: nowrap;
					background: radial-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, .5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
					z-index: -1;
					opacity: 1;
					transition: opacity 1s ease-in-out 1s;
					@include max($lap) {
						top: -30px;
						left: -50px;
						width: calc(100% + 100px);
						height: calc(100% + 100px);
					}
				}
				&__heading {
					&::before,
					&::after {
						transform: scaleX(1);
						transition: transform 0.7s ease-in-out 0.7s;
					}
					&::before {
						transform-origin: right center;
					}
					&::after {
						transform-origin: left center;
					}
				}
			}
		}
		.intro {
			&__pattern {
				$opacityDuration: 0.7s;
				$opacity: 0;
				$opacityDelay: 0s;
				$transformDuration: 1s;
				$transform: translate(-50%, 0%) rotate(-40deg);
				$transformDelay: 0s;
				@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
			}
		}
		&.active {
			.intro {
				&__pattern {
					$opacityDuration: 1s;
					$opacity: 0.17;
					$opacityDelay: 0.7s;
					$transformDuration: 1s;
					$transform: translate(-50%, -50%) rotate(-40deg);
					$transformDelay: 0.7s;
					@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
				}
			}
		}
		.clients {
			&__heading {
				&::after {
					transition: transform 0.7s $easing;
					transform: scaleX(0);
					transform-origin: left center;
				}
			}
		}
		&.active {
			.clients {
				&__heading {
					&::after {
						transform: none;
						transition: transform 0.9s $easing 1s;
					}
				}
			}
		}
		.animUp {
			display: block;
			overflow: hidden;
			&::after {
				transform: scaleX(0);
				transform-origin: left bottom;
				transition: transform 0.7s $expoOut 0s;
			}
			> span {
				transform: translateY(100%);
				transition: transform 0.3s $expoOut 0s;
				display: flex;
			}
			&--alt {
				transform: translateY(100px);
			}
		}
		.animUp.anim__mainHeadWrap {
			span {
				transform: translateY(105%);

			}
		}
		.intro {
			&__image {
				&--strategy {
					font-size: 0;
					transform: translateX(-50%);
					.borderedCircle {
						transform: translate(-5%,-96.5%);
						transition: transform 0.3s $easing;
					}
					.whiteCircle {
						transform: translate(125%, 20%);
						transition: transform 0.3s $easing;
					}
					.icon-crop {
						transform: translate(5%, 0%);
						transition: transform 0.3s $easing;
					}
					.patternCircle {
						transform: translate(240%, 180%) rotate(-35deg);
						transition: transform 0.3s $easing;
					}
					&::after {
						transform: translateY(300%);
						transition: transform 0.3s $easing;
					}
				}
			}
		}
		&.active.fp-completely {
			.animUp {
				&::after {
					transition: transform 0.9s $expoOut 0.9s;
					transform: none;
				}
				&.second {
					> span {
						transition-delay: 1.2s !important;
					}
				}
				&.third {
					> span {
						transition-delay: 1.7s !important;
					}
				}
				> span {
					transition: transform 0.8s $expoOut 0.7s;
					transform: none;
				}
				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						span {
							transition: transform 0.8s $expoOut 0.4s + 0.1s * $i;
						}
					}
				}
			}
			.intro {
				&__image {
					&--strategy {
						font-size: 0;
						.borderedCircle {
							transform: translateX(-200%);
						}
						.whiteCircle {
							transform: translateY(200%);
						}
						.icon-crop {
							transform: translateY(200%);
						}
						.patternCircle {
							transform: translateX(-400%) rotate(-35deg);
						}
						&::after {
							transform: translateY(300%);
						}
					}
				}
			}
		}
		.icon-logo {
			transform: translateY(-600%);
			transition: transform 1s $easing;
		}
		.intro {
			&::after,
			&::before {
				opacity: 0;
			}
			&__team {
				filter: blur(0);
				transition: filter 0.7s $easing;
				.copy, .art, .manage, .strategy  {
					transition: transform 0.7s $easing;
				}
				.strategy {
					transform-origin: center center;
					transform: scale(0.75) $strategyLap;
					@include min($lap) {
						transform-origin: center bottom;
						transform: scale(1) $strategy;
					}
				}
				.copy {
					transform-origin: right bottom;
					transform: scale(0.75) $copyLap;
					@include min($lap) {
						transform: scale(1) $copy;
					}
				}
				.manage {
					transform-origin: left bottom;
					transform: scale(0.75) $manageLap;
					@include min($lap) {
						transform: scale(1) $manage;
					}
				}
				.art {
					transform-origin: left bottom;
					transform: scale(0.75) scale(0.9) $artLap;
					@include min($lap) {
						transform: scale(0.9) $art;
					}
				}
			}
		}
		.anim {
			&.fromTop {
				&::after,
				&::before {
					opacity: 1;
				}
				.intro {
					&__team {
						filter: blur(50px);
						transition: filter 0.7s $easing;
						.copy, .art, .manage, .strategy  {
							transition: transform 0.7s $easing;
						}
						.strategy {
							transform: scale((1.5 * 0.75)) $strategyLap;
							@include min($lap) {
								transform-origin: center center;
								transform: scale(1.5) $strategy;
							}
						}
						.manage {
							transform: scale(1.5 * 0.75) $manageLap;
							@include min($lap) {
								transform: scale(1.5) $manage;
							}
						}
						.copy {
							transform: scale((1.5 * 0.75)) $copyLap;
							@include min($lap) {
								transform: scale(1.5) $copy;
							}
						}
						.art {
							transform: scale((1.5 * 0.75 * 0.9)) $artLap;
							@include min($lap) {
								transform: scale(1.5 * 0.9) $art;
							}
						}
					}
				}
			}
			&__bordered {
				z-index: 1;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 40vw;
				height: 40vw;
				border-width: 12vw;
				@include expoInOut(0.7s, 0, 0s, 0.7s, translate(-50%, -30%), 0s);
			}
		}
		&.active {
			.anim {
				$opacityDuration: 0.7s;
				$opacity: 1;
				$opacityDelay: 1.3s;
				$transformDuration: 1s;
				$transform: translate(-55%, -40%);
				$transformDelay: 1.2s;
				&__bordered {
					$transform: translate(-55%, -40%);
					@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					@include min($lap) {
						$transform: translate(-44%, -41%);
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
				}
				&.fromTop {
					.anim__bordered {
						$transform: translate(-55%, -40%);
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						@include min($lap) {
							$transform: translate(-44%, -41%);
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
	 				}
				}
			}
		}
		.clients {
			&__logo {
				@for $i from 1 through 12 {
					&:nth-child(#{$i}) {
						opacity: 0;
						transition: opacity 0.3s $easing 0s;
					}
				}
			}
		}
		&.active {
			.clients {
				&__logo {
					@for $i from 1 through 12 {
						&:nth-child(#{$i}) {
							opacity: 1;
							transition: opacity 0.3s $easing 0.7s + 0.1s * $i;
						}
					}
				}
			}
		}
		.offer {
			&__line {
				transform: scaleX(0);
				transform-origin: left center;
				transition: transform 0.7s $easing 0s;
			}
		}
		&.active {
			.offer {
				&__line {
					transform: scaleX(1);
					transition: transform 0.9s $easing 0.8s;
				}
			}
		}
		.comp {
			&__item {
		    border: 0.2rem solid rgba(255,255,255,0);
				transition: border 0.3s $easing 0s;
				@for $i from 1 through 12 {
					&:nth-child(#{$i}) {
						> * {
							opacity: 0;
							transition: opacity 0.3s $easing 0s;
						}
					}
				}
			}
		}
		&.active {
			.comp {
				&__item {
			    border: 0.2rem solid #ECECEC;
					transition: border 0.3s $easing 0.8s;
					@for $i from 1 through 12 {
						&:nth-child(#{$i}) {
							> * {
								opacity: 1;
								transition: opacity 0.3s $easing 0.7s + 0.1s * $i;
							}
						}
					}
				}
			}
		}
		&.active {
			.icon-logo {
				transform: none;
			}
		}
		&.fp-completely {
			z-index: 2;
			.intro {
				&::after,
				&::before {
					opacity: 1;
				}
				&__team {
					filter: blur(50px);
					transition: filter 0.7s $easing;
					.copy, .art, .manage, .strategy {
						transition: transform 0.7s $easing;
					}
					.strategy {
						transform: scale((1.5 * 0.75)) $strategyLap;
						@include min($lap) {
							transform: scale(1.5) $strategy;
						}
					}
					.art {
						transform: scale((1.5 * 0.75 * 0.9)) $artLap;
						@include min($lap) {
							transform: scale(1.5 * 0.9) $art;
						}
					}
					.manage {
						transform: scale(1.5 * 0.75) $manageLap;
						@include min($lap) {
							transform: scale(1.5) $manage;
						}
					}
					.copy {
						transform: scale((1.5 * 0.75)) $copyLap;
						@include min($lap) {
							transform: scale(1.5) $copy;
						}
					}
				}
			}
			.intro {
				&.anim {
					&::after,
					&::before {
						opacity: 0;
					}
					.intro {
						&__team {
							filter: blur(0);
							transition: filter 0.7s $easing;
							.copy, .art, .manage, .strategy {
								transition: transform 0.7s $easing;
							}
							.manage {
								transform: scale(0.75) $manageLap;
								transform-origin: left bottom;
								@include min($lap) {
									transform: scale(1) $manage;
								}
							}
							.strategy {
								transform: scale(0.75) $strategyLap;
								transform-origin: center center;
								@include min($lap) {
									transform-origin: center bottom;
									transform: scale(1) $strategy;
								}
							}
							.copy {
								transform-origin: right bottom;
								transform: scale(0.75) $copyLap;
								@include min($lap) {
									transform: scale(1) $copy;
								}
							}
							.art {
								transform-origin: left bottom;
								transform: scale(0.75 * 0.9) $artLap;
								@include min($lap) {
									transform: scale(1 * 0.9) $art;
								}
							}
						}
					}
				}
			}
		}
		.intro {
			&__bordered {
				$opacityDuration: 1s;
				$opacityDelay: 0s;
				$transformDuration: 1s;
				$transformDelay: 0s;
				$opacity: 0;
				@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, translate(-90%, -35%), $transformDelay);
			}
		}
		&.active {
			.intro {
				&__bordered {
					$opacityDuration: 1s;
					$opacityDelay: 1s;
					$transformDuration: 1s;
					$transformDelay: 1s;
					$opacity: 0.1;
					@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, translate(-40%, -35%) scale(0.75), $transformDelay);
					@include min($lap) {
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, translate(-40%, -35%), $transformDelay);
					}
				}
			}
		}
		.anim {
			.intro {
				$opacityDuration: 1s;
				$opacityDelay: 0s;
				$transformDuration: 1s;
				$transformDelay: 0s;
				$opacity: 0;
				&__circleFirst {
					@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, translate(230%, -35%) rotate(-35deg), $transformDelay);
				}
				&__circleSecond {
					@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, translate(-225%, 55%) rotate(-35deg), $transformDelay);
				}
			}
		}
		&.active {
			.anim {
				.intro {
					$opacityDuration: 1.5s;
					$opacityDelay: 1.6s;
					$transformDuration: 1.5s;
					$transformDelay: 1.6s;
					$opacity: 1;
					&__circleFirst {
						transform: translate(120%, -30%) rotate(-35deg);
						transition: transform 1s $easing 1.4s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, translate(120%, -30%) rotate(-35deg), $transformDelay);
					}
					&__circleSecond {
						transform: translate(-200%, -20%) rotate(-35deg);
						transition: transform 1s $easing 1.4s;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, translate(-200%, -20%) rotate(-35deg), $transformDelay);
					}
				}
			}
		}
	}
	.fromBottom {
		.copy {
			opacity: 0;
			transform: translateX(-50%) scale(0.75) $copyLap !important;
			@include min($lap) {
				transform: translateX(-50%) $copy !important;
			}
			transition: 0.5s $expoOut !important;
			transition-property: transform, opacity !important;
		}
		.strategy {
			opacity: 1;
			transform: translateX(-34%) scale(0.75) $strategyLap !important;
			@include min($lap) {
				transform: translateX(-66%) $strategy !important;
			}
			transition: 0.7s $expoOut !important;
			transition-property: transform, opacity !important;
		}
		.manage {
			opacity: 0;
			transform: translateX(50%) scale(0.75) $manageLap !important;
			@include min($lap) {
				transform: translateX(50%) $manage !important;
			}
			transition: 0.7s $expoOut !important;
			transition-property: transform, opacity !important;
		}
		.art {
			opacity: 0;
			transform: translateX(50%) scale(0.9) scale(0.75) $artLap !important;
			@include min($lap) {
				transform: translateX(50%) scale(0.9) $art !important;
			}
			transition: 0.5s $expoOut !important;
			transition-property: transform, opacity !important;
		}
	}
	.active {
		.fromBottom {
			.copy {
				opacity: 1;
				transform: scale(0.75) $copyLap !important;
				@include min($lap) {
					transform: $copy !important;
				}
				transition: 1s $expoOut 0.9s !important;
				transition-property: transform, opacity !important;
			}
			.strategy {
				opacity: 1;
				transform: scale(0.75) $strategyLap !important;
				@include min($lap) {
					transform: $strategy !important;
				}
				transition: 1s $expoOut 0.7s !important;
				transition-property: transform, opacity !important;
			}
			.manage {
				opacity: 1;
				transform: scale(0.75) $manageLap !important;
				@include min($lap) {
					transform: $manage !important;
				}
				transition: 1s $expoOut 0.7s !important;
				transition-property: transform, opacity !important;
			}
			.art {
				opacity: 1;
				transform: scale(0.9) scale(0.75) $artLap !important;
				@include min($lap) {
					transform: scale(0.9) $art !important;
				}
				transform-origin: left bottom;
				transition: 1s $expoOut 0.9s !important;
				transition-property: transform, opacity !important;
			}
		}
	}
}
.fp-viewing-intro .header__toggle {
	span:nth-child(1),
	span:nth-child(2) {
		background-color: #fff;
	}
}

.debug {
	.intro {
		&::after,
		&::before {
			display: none !important;
		}
		&__team {
			filter: blur(0) !important;
		}
	}
}

.typed-cursor{
    opacity: 1;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
}
@keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
