.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: (2702/2008);
	font-weight: 900;
	color: #025F77;
	padding-bottom: 2vw;

	@include max($sm) {
		flex-direction: column;
		padding-top: 6vw;
	}

	@include btw($sm, $md) {
		align-items: flex-end;
	}

	@include min($md) {
		font-size: 1.05vw;
		padding-bottom: 6vw;
	}

	&__patterns {

		@include min($sm) {
			margin: 0 3vw;
		}

		@include max($md) {
			margin-top: 2rem;
		}
	}
	&--alt {
		.footer {
			padding: 25rem 0 4rem;
			@include max($lg) {
				padding: 15rem 0 4rem;
			}
			@include max($md) {
				padding: 10rem 0 4rem;
			}
		}
	}
}
