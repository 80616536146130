.inputs {

  &__short {
    width: 33%;

    @include max($sm) {
      margin-bottom: 1rem;
    }
  }

  &__long {
    width: 65%;
  }

  &__short,
  &__long {

    @include max($sm) {
      width: 100%;
    }
    span {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }

  &__set {
    display: flex;
    width: 100%;

    @include max($sm) {
      display: flex;
      flex-direction: column;
    }

    @include min($sm) {
      justify-content: space-between;
    }
  }
}
