.intro {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	position: relative;
	z-index: 1;
	@include min($sm) {
		overflow: hidden;
	}
	&__logo {
		background: url('img/logointro.png');
		background-size: 175px;
		width: 175px;
		height: 79px;
	}

	&:not(.anim) {
		@include max($lg) {
			min-height: 100vh;
		}
	}

	&__pattern {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) rotate(-40deg);
		transition: 0.7s transform ease-in-out;
		height: 26rem;
		width: 26rem;
		opacity: 0.17;
		@include max($sm) {
			transform: translate(-50%, -50%) rotate(-40deg) scale(0.65);
		}
	}
	.intro {
		&__content {
			&.mobileOnly {
				position: absolute;
				bottom: 7rem;
				left: 0;
				width: 100%;
				text-align: center;
				&::after {
					content: none;
				}
				@include min($sm) {
					display: none;
				}
				.intro {
					&__lead {
						font-size: 1.1rem;
						line-height: 1.33;
					}
					&__mainLead,
					&__mainHead {
						font-size: 1.7rem;
					}
				}
			}
		}
	}
	&__content {
		text-shadow: 4px 2px 12px rgb(0,0,0);
		&::after {
			content: '';
			display: block;
			width: 8.2rem;
			height: 0.3rem;
			background-color: #00D3D5;
			position: absolute;
			bottom: 10.5vw;
			left: 50%;
			transform: translateX(-50%);
			@include max($lap) {
				bottom: 4vw;
			}
			@media (min-width: 1599px) and (max-width: 1601px) and (min-height: 800px) and (max-height: 901px) {
			 	bottom: 3.5vw;
			}
			@media (min-width: 1279px) and (max-width: 1281px) and (min-height: 750px) and (max-height: 801px) {
				bottom: 6.5vw;
			}
		}
	}
	&__line {
		position: absolute;
		bottom: 0;
		left: 50%;
	}
	&__lead {
		font-size: 1.76rem;
		line-height: (2111/1758);
		letter-spacing: 0.25em;
		font-weight: 900;
		color: #fff;
		text-transform: uppercase;
		margin-top: 3rem !important;
		@include max($lap) {
			font-size: 1.6rem;
		}
		@media (min-width: 1599px) and (max-width: 1601px) and (min-height: 800px) and (max-height: 901px) {
			font-size: 1.6rem;
		}
	}
	&__mainHead {
		font-size: 2.5rem;
		letter-spacing: 0.15em;
		line-height: (3248/3067);
		font-weight: 900;
		font-style: normal;
		@include min($lg) {
			font-size: 2.5rem;
		}
		@include min($lap) {
			font-size: 3rem;
		}
		@media (min-width: 1599px) and (max-width: 1601px) and (min-height: 800px) and (max-height: 901px) {
			font-size: 2.5rem;
		}
	}
	&__mainLead {
		font-size: 2.5rem;
		letter-spacing: 0.05em;
		line-height: (3248/3067);
		font-weight: 700;
		font-style: italic;
		@include min($lg) {
			font-size: 2.5rem;
		}
		@include min($lap) {
			font-size: 3rem;
		}
		@media (min-width: 1599px) and (max-width: 1601px) and (min-height: 800px) and (max-height: 901px) {
			font-size: 2.5rem;
		}
	}
	.anim {
		&__heading {
			text-align: center;
			letter-spacing: 0;
			&::after,
			&::before {
				content: none;
			}
		}
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
		background: radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(0,0,0,1) 85%);
		background: radial-gradient(ellipse at center, rgba(255,255,255,0) 0%, rgb(10, 36, 41) 85%);
		transition: opacity 0.7s ease-in-out;
		@include min($lg) {
			opacity: 0;
		}
	}
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
		background-color: rgba(0,0,0,0.36);
		transition: opacity 0.7s ease-in-out;
		@include min($lg) {
			opacity: 0;
		}
	}
	&__bordered {
		opacity: 0.1;
		width: 62vw;
		height: 62vw;
		border-width: 18vw;
		top: 8vw;
		left: 2vw;
		position: absolute;
		z-index: -1;
		transform: translate(-90%, -35%) scale(0.75);
		@include min($lap) {
			transform: translate(-90%, -35%);
		}
	}
	.icon-logo {
		position: relative;
		z-index: 2;
		margin-bottom: 4rem;
	}
	.anim {
		&__crop {
			position: absolute;
	    bottom: 0;
	    left: 50%;
	    z-index: 4;
	    transform: scale(0.8) translate(-50%, 80%);
	    transform-origin: 0 100%;
			@include min($lg) {
				display: none;
			}
		}
		&__bordered {
			@include max($lg) {
				position: absolute;
				top: 60%;
				left: 50%;
				width: 22rem;
				height: 22rem;
				border-width: 6rem;
				transform: translate(-50%, -50%);
			}
		}
		&__mainHeadWrap {
			.intro__mainHead {
				@include max($lg) {
					font-size: 3rem;
					margin: 0;
				}
			}
		}
		&__mainLeadWrap {
			transform: none;
			.intro__mainLead {
				@include max($lg) {
					font-size: 2rem;
				}
			}
		}
	}
	&.anim {
		@include max($lg) {
			display: none;
			min-height: 75vh;
			min-height: calc(300/160 * 50vw + 2.5rem);
			margin-bottom: 4rem;
		}
		&::before,
		&::after {
			@include max($lg) {
				content: none;
			}
		}
		.intro {
			&__team {
				display: flex;
				justify-content: center;
				align-items: flex-end;
				@include max($lap) {
					align-items: center;
					height: 100%;
				}
				@include max($lg) {
					align-items: flex-end;
					filter: blur(0);
				}
				.copy {
					@include max($lg) {
			  	  transform: translate(70%, 15%);
					}
				}
				.strategy {
					@include max($lg) {
			  	  transform: translateX(35%);
					}
				}
				.manage {
					@include max($lg) {
			  	  transform: translateX(-15%);
					}
				}
				.art {
					@include max($lg) {
			  	  transform: translateX(-85%) scale(0.9);
					}
				}
				img {
					@include max($lg) {
						width: 50%;
						position: static;
					}
				}
			}
		}
		img {
			position: relative;
			flex-shrink: 0;
		}
		.copy {
			z-index: 1;
		}
		.manage {
			z-index: 2;
		}
		.art {
			z-index: 1;
		}
		.intro__circleFirst {
			flex-shrink: 0;
			position: absolute;
			z-index: 1;
			left: 3vw;
			top: calc(50% - 6rem);
			width: 18rem;
			height: 18rem;
			transform: translateX(150%) rotate(-35deg);
		}
		.intro__circleSecond {
			flex-shrink: 0;
			position: absolute;
			right: -3.5vw;
			top: 50%;
			width: 18rem;
			height: 18rem;
			z-index: 1;
			transform: translate(-150%, 80%) rotate(-35deg);
		}
		.anim {
			&__circleFirst {
				top: 55%;
				transform: translateX(120%) rotate(-35deg) scale(0.3);
			}
			&__circleSecond {
				top: 55%;
				transform: translate(-120%, -25%) rotate(-35deg) scale(0.3);
			}
		}
	}
	&__mainHeadWrap {
		transform: translateX(-12%);
	}
	&__mainLeadWrap {
		transform: translateX(20%);
	}
	.patternCircle {
		position: absolute;
		z-index: 3;
		background-position: 0.2rem;
	}
	&__image {
		&--strategy {
			z-index: 3;
			left: 6vw;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				z-index: 4;
				bottom: 0;
				left: 0;
				display: block;
				background-color: #fff;
				width: 100%;
				height: 9rem;
				@include min($md) {
					height: 16rem;
				}
			}
			.icon-crop {
				position: absolute;
				z-index: 4;
				bottom: 0;
				left: 0;
				transform: translate(-5%, -40%);
				@include max($lg) {
			    transform: translate(-5%, -45%);
			    transform-origin: left bottom;
				}
			}
			.borderedCircle {
				z-index: 1;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 35rem;
				height: 35rem;
				border-width: 9.7rem;
				transform: translate(-5%, -96.5%);
				@include max($lg) {
					width: 16rem;
					height: 16rem;
					border-width: 4rem;
					transform: translate(0, -75%);
				}
			}
			.whiteCircle {
				transform: translate(85%, 10%);
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 4;
				@include max($lg) {
					width: 20rem;
					height: 20rem;
					transform: translate(50%, 20%);
				}
			}
			.patternCircle {
				transform: translate(150%, -105%) rotate(-35deg);
				position: absolute;
				bottom: 0;
				left: 0;
				width: 16rem;
				height: 16rem;
				z-index: 4;
				@include max($lg) {
					width: 16rem;
					height: 16rem;
					transform-origin: left bottom;
					transform: translate(90%, -50%) rotate(-35deg) scale(0.65);
				}
			}
		}
	}
	&__logoSpacing {
		position: absolute;
		top: 6rem;
		left: 6rem;
		transform: scale(0.75);
		transform-origin: top left;
		@include max($lg) {
			display: none;
		}
	}
	&__logo {
		position: relative;
		z-index: 2;
	}
	&__team {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: -2;
		@include min($lap) {
			transform: translateY(40px);
		}
		@include max($lap) {
			align-items: center;
			height: 100%;
		}
		@include max($lg) {
			filter: blur(25px);
		}
		&::after {
			@media (min-width: 1200px) and (max-width: 1500px) and (max-height: 1200px) and (min-height: 900px) {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 4;
				width: 100%;
				height: 14rem;
				background-color: #fff;
			}
		}
		img {
			position: relative;
			flex-shrink: 0;
		}
		.copy {
			z-index: 1;
			left: 28vw;
			@include min($lap) {
				left: 19vw;
			}
			@include max($xs) {
				width: 50%;
				left: 36vw;
			}
		}
		.strategy {
			z-index: 3;
			left: 12vw;
			@include min($lap) {
				top: 0;
				left: 10vw;
			}
			@include max($sm) {
				width: 80%;
			}
			@include max($xs) {
				width: 50%;
				left: 16vw;
			}
		}
		.manage {
			z-index: 2;
			right: 9vw;
			@include min($lap) {
				right: 4.7vw;
				top: 1vw;
			}
			@include max($sm) {
				width: 80%;
			}
			@include max($xs) {
				width: 50%;
				right: 10vw;
			}
		}
		.art {
			z-index: 1;
			right: 32vw;
			@include max($lap) {
				top: -1vw;
			}
			@include min($lap) {
				right: 17.5vw;
			}
			@include max($xs) {
				width: 50%;
				right: 44vw;
				top: 0;
			}
			transform: scale(0.9);
			transform-origin: left bottom;
		}
	}
	.anim__heading {
		flex-direction: column;
		z-index: 1;
	}
	&__lead {
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: center;
		margin-top: 2rem;
		z-index: 2;
	}
	&.anim {
		justify-content: flex-end;
		padding-bottom: 13vw;
		@include max($lap) {
			padding-bottom: 7vw;
		}
		@media (min-width: 1599px) and (max-width: 1601px) and (min-height: 800px) and (max-height: 901px) {
			padding-bottom: 7vw;
		}
		@media (min-width: 1279px) and (max-width: 1281px) and (min-height: 750px) and (max-height: 801px) {
			padding-bottom: 10vw;
		}

	}
	&__heading {
		font-size: 2.4rem;
		font-weight: 900;
		@include min($lg) {
			font-size: (5.412rem * 0.75);
		}
		@include min($lap) {
			font-size: 5.412rem;
		}
		@include max($lap) {
			font-size: 3.059rem;
		}
		line-height: (8725/5412);
		color: #fff;
		text-align: center;
		position: relative;
		z-index: 4;
		letter-spacing: 0.136em;
		display: flex;
		align-items: center;
		justify-content: center;
		@include max($sm) {
			line-height: 1;
		}
		@include max($xs) {
			flex-wrap: wrap;
			justify-content: center;
		}
		.animUp {
			&:first-child {
				@include max($sm) {
					width: 100%;
					margin-bottom: 0.5rem;
				}
			}
		}
		span {
			display: block;
		}
		&::after,
		&::before {
			@include min($xs) {
				content: '';
			}
			display: block;
			width: 16rem;
			height: 0.2rem;
			background-color: #00D3D5;
		}
		&::before {
			margin-right: 8rem;
		}
		&::after {
			margin-left: 8rem;
		}
	}
	.shadows {
		opacity: 0;
		transition: opacity .3s ease-in-out;
	}
}
