.goTop {
  position: absolute;
  background: #03D3D5;
  bottom: -20px;
  right: 40px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.2rem;
  transform: rotate(-90deg);
  color: #fff;
  @include max($sm) {
    height: 50px;
    width: 50px;
    bottom: -70px;
    right: 5px;
    // border: 1px solid #000;
  }
  .icon-jump {
    background: url(img/icons/icon-jump.png) no-repeat;
    background-size: cover;
    width: 21px;
    height: 17px;
    transform: rotate(90deg);
    @include max($sm) {
      background: url(img/icons/icon-jump.png) no-repeat;
      background-size: cover;
      transform: rotate(90deg);
      width: 30px;
      height: 24px;
    }
  }
  span:not(.icon) {
    margin-right: 5px;
    @include max($sm) {
      display: none;
    }
  }
}
