.references {
	&__heading {
			line-height: (9326/5900);
			font-weight: 900;
			color: #046078;
			position: relative;
			z-index: 1;
			font-size: 2.6rem;
		@include max($md) {
			padding: 1.5rem 0;
		}
		@include min($md) {
			height: 11vw;
			font-size: 3.07933vw;
		}
	}
	.swiper-slide {
		align-self: center;
	}
	.swiper-button {
		&-next,
		&-prev {
				height: auto;
				width: auto;
				background-image: none;
			@include max($md) {
				top: auto;
				bottom: 0.3rem;
			}
			.rotate {
				transform: rotateY(180deg);
			}
		}
		&-prev {
			left: calc(((100vw - 1240px) / 2) - 12rem);
			@include btw($lg, $lap) {
				margin-left: 10rem;
			}
		}
		&-next {
			right: calc(((100vw - 1240px) / 2) - 12rem);
			@include btw($lg, $lap) {
				margin-right: 10rem;
			}
		}
	}
}
