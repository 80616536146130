@for $i from 1 through 12 {
	.col-#{$i} {
		width: 100% / 12 * $i;
		width: calc(100% / 12 * #{$i});
		height: 100%;
	}
}
.row {
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	width: 100%;
}