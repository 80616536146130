.comp {
	padding: 6rem 1.5rem 1.5rem;

	&__wrap {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		margin: 0 auto;

		@include min($md) {
			padding: 3rem 1.5rem 0;
		}

		@include min($lg) {
			width: 124rem;
		}

		@include btw($lg, $lap) {
			width: 96rem;
		}

		@include min($lap) {
			justify-content: space-between;
		}
	}

	&__item {
		border: 0.2rem solid #ECECEC;
		width: 100%;
		padding: 1.5rem;
		justify-content: space-around;
		flex-shrink: 0;
		position: relative;
		z-index: 1;
		margin-bottom: 6vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		@include min($md) {
			padding: 1vw;
			justify-content: space-between;
			width: calc(25% - 3rem);
			justify-content: flex-end;
			height: 22vw;
		}

		@include min($lg) {
			height: 21vw;
		}

		@include min($lap) {
			height: 16vw;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: #fff;
			width: calc(100% + 8px);
			height: 60%;
			z-index: -1;
		}

		&::after {
			height: calc(100% + 8px);
			width: 60%;
		}
	}

	&__iconWrapper {

		@include min($md) {
			position: absolute;
			width: 120px;
			height: 90px;
			top: -5rem;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__heading {
		font-size: 2rem;
		line-height: (3261/2718);
		font-weight: 900;
		color: #046078;
		display: flex;
		align-items: center;

		@include max($md) {
			padding: 2vw 0 1vw 0;
		}

		@include min($md) {
			position: absolute;
			top: 4.5vw;
			left: 50%;
			transform: translateX(-50%);
			font-size: 1.4vw;
			margin: 0;
		}
	}
	&__icon {
		@include max($lap) {
			transform: scale(.7);
		}
		@include max($lg) {
			transform: scale(.9);
		}
	}
	&__text {
		font-size: 1.6rem;
		line-height: (18/15);
		font-weight: 300;
		color: #046078;

		@include min($md) {
			font-size: 1.3vw;
			height: 63%;
		}

		@include min($lg) {
			font-size: 1.1vw;
			height: 60%;
		}

		@include min($lap) {
			font-size: 0.8vw;
			height: 44%;
		}
	}
}
