// list reset

body {
	padding: 0;
	margin: 0;
}

ul, li {
	list-style: none;
	padding: 0;
	margin: 0;
}

// anchor reset
a {
	text-decoration: none;
	color: inherit;
}

button {
	border: 0;
	padding: 0;
	background: none;
	&:focus {
		outline: 0;
	}
}

p {
	margin: 0;
}
h1, h2, h3, h4, h5, h6 {
	margin: 0;
}
input {
	-webkit-appearance: none;
	border-radius: 0;
}

blockquote {
	margin: 0;
}