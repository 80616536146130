.introPost {
  background-size: cover;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50vw;
  background-position: center;

  @include max($lg) {
    margin-top: 70px;
  }

  @include min($md) {
    height: 27vw;
    background-position: 0 -25rem;
  }

  @include max($lg) {
    padding: 70px 0 4rem;
    height: 300px;
  }

  @include max($sm) {
    padding: 70px 0 3rem;
  }

  &--first {

    @include max($lg) {
      background-position-y: -35px;
    }

    @include max($sm) {
      background-position-y: 9px;
    }

    @include max($xs) {
      background-position-y: 45px;
    }
  }

  &--second {

    @include min($lg) {
      background-position-y: 550px;
    }

    @include min($xl) {
      background-position-y: 700px;
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::after {
    content: "";
    display: block;
    width: 0.2rem;
    height: 8rem;
    background-color: #00d3d5;
    position: absolute;
    left: 50%;
    bottom: -4rem;
    transform: translateX(-50%);

    @include max($sm) {
      height: 6rem;
      bottom: -3rem;
    }

    @include btw($md, $lg) {
      height: 4rem;
      bottom: -2rem;
    }
  }

  &__content {
    text-align: center;
    z-index: 1;

    @include max($lg) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    font-size: 2.4rem;
    margin-top: 2rem;

    @include min($sm) {
      font-size: 3.5rem;
    }

    @include min($md) {
      font-size: 4.8rem;
    }
  }

  &__title-text {
    font-size: 1.4rem;
    font-weight: 100;

    @include min($sm) {
      font-size: 1.9rem;
    }

    @include min($md) {
      font-size: 1.3vw;
    }
  }

  .btn {

    @include max($lg) {
      margin-top: 1rem;
      padding: 0.6rem 2.4rem;
    }

    @include min($lg) {
      position: absolute;
      top: 7.6rem;
      right: 12.5rem;
      font-size: 1.8rem;
      padding: 0.8rem 2.8rem;
    }

    @include min($lap) {
      padding: 1rem 3rem;
      top: 7.8rem;
      right: 14rem;
    }
  }
}
