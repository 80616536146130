.contactUs {

	@include btw($xs, $sm) {
		margin-bottom: 3rem;
	}
	&__bar {
		text-align: center;
		padding: 1.5rem 0;
		@include min($md) {
			padding: 4vw 0;
		}
	}
	&__heading {
		font-size: 2.6rem;
		font-weight: 900;
		color: #046078;
		line-height: (7202/5020);
		@include min($md) {
			font-size: 3.2vw;
			line-height: 1;
		}
		@include max($sm) {
			line-height: 1
		}
	}
	&__lead {
		font-size: 2rem;
		font-weight: 900;
		line-height: (4300/2011);
		color: #5D6669;
		@include min($md) {
			font-size: 2.6vw;
			line-height: 1;
		}
	}
}

.writeToUs {
	// .grid {
	// 	padding-top: 150px;
	// 	@include max($md) {
	// 		padding: 10rem 0 5rem;
	// 	}
	// 	@include max($sm) {
	// 		padding: 6rem 0 1rem;
	// 	}
	// }
	// p {
	// 	color: #C2C2C2;
	// 	font-size: 59px;
	// 	@include max($md) {
	// 		font-size: 2.5rem;
	// 	}
	// 	@include max($sm) {
	// 		font-size: 2.2rem;
	// 	}
	// }
	// &--extended {
	// 	.grid {
	// 		padding-top: 390px;
	// 		@include max($md) {
	// 			padding: 20rem 0 1rem;
	// 		}
	// 		@include max($sm) {
	// 			padding: 6rem 0 1rem;
	// 		}
	// 	}
	// }
}
