.fp-enabled {
	.section {
		.contact {
			&::after {
				transform: translateY(100%);
			}
		}
		&.active {
			.contact {
				&::after {
					transition: transform 0.7s cubic-bezier(1,0,0,1) 0.7s;
					transform: none;
				}
			}
		}
	}
}
.contact {
	&::after {
		transition: transform 0.7s cubic-bezier(1,0,0,1);
		content: '';
		display: block;
		background-color: #023947;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}
.contactFull{
	.contact {
		@include max($sm) {
			padding-bottom: 28vh;
			padding-top: 6rem;
			height: auto;
		}
	}
}
.contact {
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	position: relative; z-index: 1;
	padding: 3rem 0 7rem;
	@include max($lg) {
		flex-direction: column;
	}
	@include max($sm) {
		padding-top: 1rem;
		height: auto;
		padding-bottom: 28vh;
	}

	&__info {
		@include max($sm) {
			text-align: center;
		}
	}
	&__copy {
		@include max($sm) {
			margin: 2rem 0;
		}
		font-weight: 900;
	}
	&__social {
		display: flex;
		li:not(:last-child) {
			margin-right: 1.6rem;
		}
		a {
			display: block;
		}
		li {
			flex-shrink: 0;
		}
	}

	&__footer {
		color: #fff;
		font-weight: 400;
		font-size: 1.4rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		bottom: 3rem;

		@include max($sm) {
			flex-direction: column;
		}

		@include max($lg) {
			width: calc(100% - 3rem);
			left: 1.5rem;
		}

		@include btw($md, $lg) {
			width: 60vw;
			left: 20vw;
		}

		@include min($lg) {
			bottom: 3.6rem;
			right: 8rem;
			flex-direction: row;
			width: 70%;
		}

		@include min($lap) {
			font-size: 1.6rem;
		}

		strong {
			font-weight: 900;
		}
	}

	.icon-logo-light {
		@include max($lap) {
			transform: scale(0.7);
			transform-origin: left top;
		}
		@include max($lg) {
			transform: scale(0.6);
			display: none;
		}
		@include max($sm) {
			display: none;
		}
	}
	.icon-logo {
		margin-top: 1rem;
	}
	&__arrow {
		margin-top: 0.6rem;
		margin-left: 2rem;
		transform: rotate(-90deg);
	}
	&__btn {
		font-variant: small-caps;
		height: 4.8rem;
		width: 16rem;
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-top: 2rem;
		background-color: #00D3D5;
		color: #fff;
		font-size: 2.137rem;
		line-height: (2565 / 2137);
		display: flex;
		border: 0;
		padding: 0;
		justify-content: center;
		position: relative;
		@include max($sm) {
			width: 100%;
		}
		@include max($lg) {
			margin-bottom: 2rem;
		}
		@include min($lap) {
			height: 5.5rem;
			width: 20rem;
		}
		&::after {
			background-color: #00a9d5;
		}
	}
	form {
		position: relative;
		z-index: 1;
		.thank {
			pointer-events: none;
			opacity: 0;
			transition: opacity 1s ease-in-out 1s;
			display: flex;
			flex-direction: column;
			text-align: center;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 20;
			background-color: #023947;
			h2 {
				font-size: 4rem;
		    margin-bottom: 1rem;
		    line-height: 1.03797;
		    font-weight: 900;
				color: #fff;
				opacity: 0;
				transition: opacity 1s ease-in-out;
			}
			p {
				line-height: 1.33333;
		    color: #00D3D5;
		    font-size: 2.4rem;
				opacity: 0;
				transition: opacity 1s ease-in-out;
			}
		}
		&.active {
			.thank {
				pointer-events: all;
				opacity: 1;
				transition: opacity 1s ease-in-out;
				h2,
				p {
					opacity: 1;
					transition: opacity 1s ease-in-out 1s;
				}
			}
		}
	}
	&.active {
		display: flex;
		z-index: 21;
	}
	.copy {
		@include max($lg) {
			height: 4rem;
			position: static;
			margin: 3rem 0;
		}
	}
	&__cols {
		display: flex;
		justify-content: space-between;
		@include max($lg) {
			flex-direction: column;
		}
	}
	&__image {
		display: flex;
		align-items: center;
		@include max($lg) {
			display: none;
		}
		@include btw($sm, $lg) {
			display: flex;
			justify-content: center;
		}
		@include btw($lg, $lap) {
			transform: scale(0.7);
			transform-origin: left top;
		}
	}
	&__close {
		position: absolute;
		top: 1rem;
		right: 1.5rem;
		cursor: pointer;
		@include min($lg) {
			top: 2rem;
			right: 2.5rem;
		}
		@include min($lap) {
			top: 5rem;
			right: 6.5rem;
		}
		width: 6.2rem;
		height: 6.2rem;
		@include btw($lg, $lap) {
			transform: scale(0.6);
			transform-origin: left center;
		}
		border-radius: 50%;
		&::before,
		&::after {
			content: '';
			display: block;
			width: 5.5rem;
			height: 1rem;
			background-color: #000;
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&::before {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
		&::after {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
	&__lines {
		width: 100%;
		position: relative;
		z-index: 1;
		&::after,
		&::before {
			position: absolute;
			left: 0;
			content: '';
			display: block;
			width: 100%;
			height: 0.1rem;
			background-color: #d3d3d3;
		}
		&::after {
			top: 4rem;
			@include min($lg) {
				top: (6.2rem * 0.6);
			}
			@include min($lap) {
				top: 6.2rem;
			}
		}
		&::before {
			top: 8rem;
			@include min($lg) {
				top: (12.4rem * 0.6);
			}
			@include min($lap) {
				top: 12.4rem;
			}
		}
		&.error {
			&::before,
			&::after {
				background-color: #e77;
				height: 0.2rem;
			}
		}
	}
	&__img {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
    @include min($xl) {
			// margin-top: -25rem;
   		width: 20vw;
    }
		@include btw($lg, $lap) {
			margin-bottom: 8rem;
		}
		@include max($lg) {
			display: none;
		}
    img {
			@include btw($lg, $lap) {
				transform: scale(0.7) rotate(-21deg);
				transform-origin: center 80%;
			}
    }
	}
	&__form {
		flex-shrink: 0;
		width: calc(100% - 3rem);
		@include min($md) {
			width: 60vw;
		}
		@include min($lg) {
			margin: 0 5rem;
			width: 50vw;
		}
		@include btw($lg, $lap) {
			width: 41vw;
		}
		@include min($xl){
			margin: 0 12rem;
			width: 38vw;
		}
	}
	&__inputs {
		display: flex;
		flex-direction: column;
		button:disabled {
			// .contact__btn {
			// 	opacity: .7;
			// }
			.simpleLoader-wrapper {
				opacity: 1;
			}
		}
		textarea,
		input {
			border-radius: 0;
			background-color: #fff;
			border: 0;
			height: 4rem;
			font-size: 1.6rem;
			font-family: inherit;
			padding-left: 1.6rem;
			@include min($lg) {
				font-size: (2.4rem * 0.6);
				height: 4.8rem;
			}
			@include min($lap) {
				padding-left: 2.5rem;
				font-size: 2.288rem;
				height: 6.2rem;
				line-height: (2655 / 2288);
			}
			&:focus {
				outline: 0;
			}
			&.error {
				background-color: #ffdfdf;
			}
		}
		textarea {
			resize: none;
			border-bottom: 0;
			width: 100%;
			max-width: 100%;
			min-width: 100%;
			height: 16rem;
			line-height: 4.8rem;
			margin-top: 1rem;
			@include min($lap) {
				height: 21rem;
				max-height: 21rem;
				min-height: 21rem;
				line-height: 6.2rem;
			}
			&:focus {
				border-bottom: 0;
			}
		}
	}
	&__heading {
		font-size: 3rem;
		line-height: (6234/6906);
		font-weight: 900;
		color: #fff;
		@include max($lg) {
			margin-bottom: 1rem;
		}
		@include min($lg) {
			font-size: (6.906rem * 0.6);
		}
		@include min($lap) {
			font-size: 6.906rem;
		}
		&::after {
			content: '';
			display: block;
			width: 9.2rem;
			height: 0.5rem;
			margin-top: 1rem;
			background-color: #00D3D5;
			@include min($lg) {
				margin-top: 3rem;
			}
		}
		br {
			@include max($lg) {
				display: none;
			}
		}
	}

	.button {
		margin-top: 2.5rem;
		.rotate {
			transform: rotate(-90deg);
		}
	}
}
.fp-enabled {
	.section {
		.contact {
			&__heading {
				&::after {
					transform: scaleX(0);
					transform-origin: left center;
					transition: transform 0.7s ease-in-out;
				}
			}
			&__img {
				img {
					$opacityDuration: 0.7s;
					$opacity: 0;
					$opacityDelay: 0s;
					$transformDuration: 0.7s;
					$transform: translateX(-50%) scale(0.7) rotate(-21deg);
					$transformDelay: 0s;
					@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					@include min($lap) {
						$transform: translateX(-50%) rotate(-21deg);
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
					}
				}
			}
			&__form {
				transition: 1s transform ease-in-out;
			}
		}
		&.active {
			.contact {
				&__heading {
					&::after {
						transform: none;
						transition: transform 0.7s ease-in-out 1s;
					}
				}
				&__img {
					img {
						$delay: 1.3s;
						$opacityDuration: 0.7s;
						$opacity: 1;
						$opacityDelay: $delay;
						$transformDuration: 0.7s;
						$transform: scale(0.7) rotate(-21deg);
						$transformDelay: $delay;
						@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						@include min($lap) {
							$transform: rotate(-21deg);
							@include expoInOut($opacityDuration, $opacity, $opacityDelay, $transformDuration, $transform, $transformDelay);
						}
					}
				}
				&__form {
					transition: 1s transform ease-in-out 1s;
				}
			}
		}
	}
}
