.project {
	position: relative;
	z-index: 1;
	&:hover {
		// cursor: pointer;
		.project {
			&__image {
				// transform: scale(1.1);
			}
		}
		.slideUp {
			> * {
				transform: none;
			}
		}
	}
	.slideUp {
		overflow: hidden;
		> * {
			@include min($sm) {
				transform: translateY(100%);
			}
			transition: transform 0.3s ease-in-out;
			display: flex;
		}
	}
	&__animate {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding-top: 12%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url('img/patternBg.png');
			transition: background-color 0.5s ease-in-out;
			opacity: 0.7;
		}
	}
	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		object-fit: cover;
		object-position: 50% 50%;
		transition: 0.5s ease-in-out;
	}
	&__heading {
		display: flex;
		font-size: (3.379rem * 0.75);
		@include min($lg) {
			font-size: 3.379rem;
		}
		@include min($lap) {
			font-size: 4.7rem;
			line-height: (4554/4752);
		}
		line-height: (3686 / 3379);
		font-weight: 900;
		color: #fff;
		position: relative;
		z-index: 1;
		white-space: pre;
	}
	&__name {
		font-size: 2.475rem;
		line-height: (3960/2475);
		color: #fff;
		font-weight: 900;
		font-style: italic;
		position: relative;
		z-index: 1;
		margin-top: 0.5rem;
	}
	&__btn {
		background-color: #00D3D5;
		color: #fff;
		font-size: 1.783rem;
		line-height: (2139/1783);
		height: 4.8rem;
		width: 17rem;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 700;
		margin-top: 3rem;
	}
	&__line {
		position: relative;
		display: block;
		z-index: 1;
		height: 3.379rem;
		width: 5rem;
		flex-shrink: 0;
		margin-right: 1rem;
		// &::before {
		// 	content: '';
		// 	position: absolute;
		// 	top: 50%;
		// 	left: 0;
		// 	display: inline-block;
		// 	width: 4.8rem;
		// 	height: 0.5rem;
		// 	background-color: #00D3D5;
		// }
	}
}
