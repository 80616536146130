$expoOut: cubic-bezier(0.13, 0.4, 0.29, 0.97);
.portfolio {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@include min($sm) {
		align-items: center;
	}
	@include max($lg) {
		padding-top: 4rem;
	}
	.swiper-slide {
		height: 21.5vw;
		@include max($lap) {
			height: 21.5vw;
		}
		@include max($lg) {
			height: 315px;
		}
		@include max($md) {
			height: 340px;
			width: 100%;
		}
		@include max($sm) {
			height: 280px;
		}
		@include max($xs) {
			height: 180px;
		}
	}
	&__lead {
		font-size: 2.7rem;
		font-weight: 900;

		@include max($sm) {
			padding: 0 2.5rem;
		}

		@include btw($lg, $lap) {
			font-size: 2.4rem;
		}
	}
	&__heading {
		line-height: (6926/8311);
		font-weight: 900;
		position: relative;
		z-index: 1;
		display: flex;
		font-size: 5rem;
		// margin-bottom: 2rem;
		@include max($sm) {
			margin-top: 4rem;
			padding: 0 2.5rem;
			width: 100%;
		}
		@include max($lg) {
			flex-direction: column;
		}
		@include min($sm) {
			text-align: center;
			font-size: 7rem;
			// margin-bottom: 7rem;
		}
		@include min($lg) {
			align-items: center;
		}
		@include min($lap) {
			font-size: 6.926rem;
		}
		&::after {
			position: relative;
			content: '';
			display: block;
			height: 0.6rem;
			width: 16rem;
			background-color: #00D3D5;
			@include max($sm) {
				width: 9.2rem;
			}
			@include max($lg) {
				margin: 1rem 0;
			}
			@include min($lg) {
				transform: translate(40px, 200%) scaleX(0);
				top: -0.5rem;
				transform-origin: left bottom;
				transition: transform 0.7s ease-in-out 0s;
			}
		}
	}
	&__btnWrap {
		margin: 2rem 0 4rem;
		flex-shrink: 0;
		@include max($sm) {
			padding: 0 1.5rem;
			margin: 1rem 0;
			display: flex;
			justify-content: center;
		}

		@include btw($lg, $lap) {
			margin: 2.5rem 0 2rem;
		}
	}
	&__btn {
    padding: 1.3rem 4rem 1.6rem;
		font-size: 2rem;
		font-weight: 700;
		display: flex;
		align-items: center;
		align-content: center;
		@include min($lap) {
			height: 6rem;
	    padding: 1.5rem 4.2rem 1.8rem;
			font-size: 2.4rem;
		}
	}
	&__arrows {
		background: none;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		pointer-events: none;
		&.swiper-button {
			&-prev,
			&-next {
				background: none;
				width: 35px;
				height: 39px;
				top: calc(50% + 12rem);
				@include max($xl)
					top: calc(50% + 5rem);

				@include max($lap) {
					top: calc(50% + 10rem);

				}
				@include max($lg) {
					top: calc(50% + 15rem);

				}
			}
			&-next {
				@include btw($lg, $lap) {
					right: 100px;
				}
				@include min($lap) {
					right: 120px;
				}
			}
			&-prev {
				@include btw($lg, $lap) {
					left: 100px;
				}
				@include min($lap) {
					left: 120px;
				}
				.rotate {
					transform: rotateY(180deg);
				}
			}
		}
	}
	&__gallery {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		flex-wrap: wrap;
		position: relative;
		z-index: 1;
		width: 100%;
		width: calc(100%/2 - 14px);
		height: 420px;
		@include max($lap) {
			height: 21.5vw;
		}
		@include max($lg) {
			height: 315px;
		}
		@include max($md) {
			height: 340px;
			width: 100%;
		}
		@include max($sm) {
			height: 280px;
		}
		@include max($xs) {
			height: 180px;
		}
		@include min($sm) {
			width: 99vw;
			max-width: 100%;
			margin: 0 auto;
		}
		@include min($md) {
			width: 99vw;
		}
		@include min($lg) {
			width: 75vw;
		}
		@include min($lap) {
			width: 1480px;
			max-width: 75vw;
		}
		@include max($sm) {
			padding: 0 1.5rem;
		}
		.project {
			// width: calc(100vw - 3rem);
			// height: calc(100vw - 3rem);
			overflow: hidden;
			@include max($sm) {
				margin-bottom: 1.5rem;
			}
			// @include min($sm) {
			// 	width: calc(((90vw - 30rem) / 4) - 1.5rem);
			// 	height: calc(((90vw - 30rem) / 4) - 1.5rem);
			// }
			@include min($sm) {
				// width: calc(((90vw - 30rem) / 2) - 1.5rem);
				// height: calc(((90vw - 30rem) / 3) - 1.5rem);
			}
			// &:last-child {
			// 	@include max($lap) {
			// 		display: none;
			// 	}
			// }
		}
	}
}
.fp-viewing-portfolio {
	.portfolio__arrows {
		transition: opacity 0.3s ease-in-out 0.7s;
		opacity: 1;
		pointer-events: auto;
	}
}
.fp-enabled {
	.section {
		.portfolio {
			&__heading {
				&.animUp {
					&::after {
						transform: translate(40px, 200%) scaleX(0);
						transform-origin: right bottom;
						transition: transform 0.7s ease-in-out 0s;
					}
					> span {
						transition: transform 0.2s ease-in-out 0s;
					}
				}
			}
			&__btn {
				transform: translateY(500%);
				transition: 1s transform ease-in-out;
			}
			.project {
				overflow: hidden;
				transform: translateY(110%);
				transition: 0.3s ease-in-out;
				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						transition: transform 0.7s $expoOut 0s + 0.05s * $i;
					}
				}
			}
		}
		&.active {
			.portfolio {
				&__heading {
					&::after {
						transform: translate(40px, 200%) scaleX(1);
						transition: transform 1.3s ease-in-out 0.7s;
					}
				}
				&__btn {
					transform: none;
					transition: transform 0.8s $expoOut 0.7s;
				}
				.project {
					transform: none;
					@for $i from 1 through 10 {
						&:nth-child(#{$i}) {
							transition: transform 0.7s $expoOut 0.7s + 0.05s * $i;
						}
					}
				}
			}
		}
	}
}
.portfolio--mobile {
	@include min($md) {
		display: none;
	}
}
.portfolio__gallery {
	&:not(.portfolio--mobile) {
		@include max($md) {
			display: none;
		}
	}
}
