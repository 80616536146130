.twoColumns {
  .container--big {
    display: flex;
    @include max($lg) {
      width: 100%;
    }
    @include max($md) {
      flex-direction: column-reverse;
    }
  }
  &__column {
    width: 50%;
    @include max($md) {
      width: 100%;
    }
  }
  .grid {
    padding: 7rem 7rem 5rem;
    @include max($lap) {
      padding: 5rem 5rem 0;
    }
    @include max($lg) {
      padding: 5rem 0 5rem;
    }
    @include max($md) {
      padding: 2rem 0;
    }
    &::after {
      content: '';
      display: none;
    }
  }
  &__content.contentBox {
    background-color: #AEAEAE;
    color: #fff;
    padding: 7.5rem;
    margin-left: -95px;
    z-index: 1;
    position: relative;
    @include max($md) {
      margin-left: 0;
      padding: 3rem 2rem;
    }
    @include max($sm) {
      margin-left: 0;
      padding: 3rem 2rem;
    }
  }
  .patternCircle {
    position: absolute;
    top: -100px;
    right: -90px;
    width: 130px;
    height: 130px;
    transform: rotate(-40deg);
    opacity: .5;
    @include max($sm) {
      background-image: url('../assets/img/patternCircleMore.png')
    }
  }
  h2 {
    font-size: 61px;
    font-weight: 900;
    text-align: center;
    @include max($lap) {
      font-size: 45.5px;
    }
    @include max($lg) {
      font-size: 45px;
    }
    @include max($sm) {
      font-size: 3rem;
    }
  }
  p {
    font-size: 2rem;
    line-height: (340/200);
    text-align: center;
    max-width: 520px;
    margin: 3rem auto 0;
    font-weight: 400;
    @include max($lap) {
      font-size: 1.5rem;
    }
    @include max($sm) {
      margin: 1rem auto 0;
      font-size: 1.6rem;
      line-height: (340/200);
    }
  }
  .robicos {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    z-index: 2;
    @include max($lap) {
      transform: scale(.75);
    }
    @include max($lg) {
      justify-content: flex-start;
      height: 100%;
    }
    img {
      margin-top: -100px;
      @include max($lap) {
        margin-top: -150px;
      }
      @include max($lg) {
        max-width: 500px;
      }
      @include max($md) {
        max-width: 70%;
        margin-top: -20px;
      }
      @include max($sm) {
        max-width: 100%;
        margin-top: -10px;
      }
      // &:nth-child(1) {
      //   transform: translateX(100px);
      // }
      // &:nth-child(2) {
      //   transform: translate(-120px, 30px);
      // }
      // &:nth-child(3) {
      //   transform: translate(-340px, 0);
      //   z-index: -1;
      //
      // }
    }
  }
}
