.info {
	&__heading {
		@include min($md) {
			font-size: 1.4vw;
		}
		line-height: (32/27);
		font-weight: 900;
		margin-bottom: (32/27) * 1.4vw;
		color: #6F6F6E;
	}
	&__list {
		color: #6F6F6E;
		@include min($md) {
			font-size: 1.3vw;
		}
		line-height: (29/25);
	}
	&__link {
		&:hover {
			text-decoration: underline;
		}
	}
}