.eventPreview {
  position: relative;
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .3;
    @include max($lap) {
      background-position: center center;
    }
    @include max($sm) {
      background-size: cover;
    }
  }
  .container--case {
    position: relative;
    display: flex;
    justify-content: space-between;
    @include max($sm) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .tasks {
    left: -10px;
    bottom: 35px;
    padding: 10rem 3rem;
    // @include max($lap){
    //   width: calc(50% + 150px);
    // }
    @include max($lg) {
      padding: 6rem 3rem;
      bottom: 100px;
    }
    @include max($md) {
      // top: 180px;
      position: relative;
      width: calc(100% + 50px);
      // bottom: auto;
      padding: 3rem 2rem;
    }
    @include max($sm) {
      width: 100%;
      left: 0;
      margin-top: 2rem;
      bottom: auto;
    }
    p {
      font-size: 3rem;
      text-align: left;
      @include max($lap) {
        font-size: 2.25rem;
      }
      @include max($md) {
        font-size: 2rem;
      }
      @include max($sm) {
        font-size: 1.6rem;
      }
    }
  }
  .smartphone {
    margin-top: 1rem;
    display: flex;
    align-items: flex-end;
    transform: translateX(70px);
    overflow: hidden;
    @include max($sm) {
      transform: none;
      margin-top: 2rem;
    }
    .anim--image {
      transition: opacity .7s ease-in-out.5s, transform .6s ease-in-out .5s;
    }
    img {
      @include max($lap) {
        max-height: 500px;
      }
      @include max($md) {
        max-height: 400px;
      }
    }
  }
  h5 {
    margin-top: 150px;
    font-size: 7.4rem;
    font-weight: 900;
    @include max($lap) {
      margin-top: 50px;
      font-size: 5.55rem;
    }
    @include max($lg) {
      font-size: 5.3rem;
    }
    @include max($md) {
      font-size: 4rem;
    }
    @include max($sm) {
      font-size: 2.8rem;
      margin-top: 30px;
    }
    &::after {
      content: '';
      display: block;
      margin-top: 1rem;
      width: 0;
      height: 5px;
      background-color: #00D3D5;
    }
    &.anim--active {
      &::after {
        content: '';
        width: 125px;
        transition: width .3s ease-in-out .9s;
        @include max($sm) {
          width: 100px;
        }
      }
    }
  }
}
.eventPreview--examples {
  padding: 130px 0 ;
  @include max($md) {
    padding: 50px 0;
  }
  .container--case {
    overflow: hidden;
    @include min($lap) {
      width: 1325px;
    }
  }
  p {
    font-size: 2.3rem;
    font-weight: 300;
    @include max($lap) {
      font-size: 1.7rem;
    }
    @include max($sm) {
      font-size: 1.6rem;
      font-weight: 400;
      padding-bottom: 3rem;
    }
  }
  .examples {
    width: calc(100% - 290px);
    margin: 80px auto 0;
    display: flex;
    justify-content: space-around;
    @include max($lap) {
      width: calc(100% - 90px);
    }
    @include max($sm) {
      width: calc(100% - 30px);
      margin: 30px auto 0;
      flex-wrap: wrap;
      align-items: center;
    }
  }
  .example {
    position: relative;
    @include max($lap) {
      width: calc(100%/5);
      height: auto;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    @include min($sm) {
      &:nth-child(5n - 3) {
        .anim--image-horizontal {
          transition: opacity .7s ease-in-out .2s, transform .6s ease-in-out .2s;
        }
      }
      &:nth-child(5n - 2) {
        .anim--image-horizontal {
          transition: opacity .7s ease-in-out .3s, transform .6s ease-in-out .3s;
        }
      }
      &:nth-child(5n - 1) {
        .anim--image-horizontal {
          transition: opacity .7s ease-in-out .4s, transform .6s ease-in-out .4s;
        }
      }
      &:nth-child(5n) {
        .anim--image-horizontal {
          transition: opacity .7s ease-in-out .5s, transform .6s ease-in-out .5s;
        }
      }
    }
    @include max($sm) {
      max-width: 100%;
      width: auto;
      height: auto;
      transition: opacity .7s ease-in-out, transform .6s ease-in-out;
    }
    &:first-child {
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -30px;
        top: -30px;
        background-color: #00D3D5;
        width: 4px;
        height: 60px;
        @include max($sm) {
          left: -10px;
          top: -10px;
        }
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: -30px;
        top: -30px;
        background-color: #00D3D5;
        height: 4px;
        width: 60px;
        @include max($sm) {
          left: -10px;
          top: -10px;
        }
      }
    }
    &:last-child {
      &::before {
        content: '';
        display: block;
        position: absolute;
        right: -30px;
        bottom: -30px;
        background-color: #00D3D5;
        width: 4px;
        height: 60px;
        @include max($sm) {
          right: -10px;
          bottom: -10px;
        }
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: -30px;
        bottom: -30px;
        background-color: #00D3D5;
        height: 4px;
        width: 60px;
        @include max($sm) {
          right: -10px;
          bottom: -10px;
        }
      }
    }
  }
}
